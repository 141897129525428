<div class="row">
    <div class="col-lg-9">

        <h2 class="mb-4 text-second font-ag-light" style="font-size: 3rem;">
            <span class="text-main font-ag-bold">Delegate</span> DETAILS
        </h2>

    </div>
    <div class="col-lg-3 pt-1">

        <button class="book-btn pull-right filter-apply" (click)="goBack()">
            Back
        </button>


    </div>

</div>


<div class="row no-bg pb-0 p-3 ml-0 mb-0 m-2 pt-0 mt-0">
    <div class="col-md-4">
        <div class="row">
            <div class="col-md-12">

                <label class="text-second-- top-dd">{{currentDelegate.name}}</label>

            </div>
        </div>
    </div>
    <div class="col-md-8">
        <div class="row no-bg">
            <div class="col-md-4">
            </div>
            <div class="col-md-4">


            </div>
            <div class="col-md-4">
                <label class="text-second fw-normal pull-right">
                    Lifetime Value:
                    <span class="income-box">
                        &#163;{{printNo(currentDelegate.lifetimeValue)}}
                    </span>
                </label>
            </div>
        </div>
    </div>

</div>


<div class="row outer-div bg-lg ml-0 mb-0 mt-0 m-2 pt-0 p-3">
    <div class="col-md-4">
        <div class="row">
            <div class="col-md-12">

                <div class="row bg-white h-crs-detail m-0 mb-3px">
                    <div class="col-md-4">
                        <label class="text-second fw-normal">Organisation:</label>
                    </div>
                    <div class="col-md-8">
                        <label class="text-second">{{currentDelegate.organization}}</label>
                    </div>
                </div>

                <div class="row bg-white h-crs-detail m-0 mb-3px">
                    <div class="col-md-4">
                        <label class="text-second fw-normal">Sector:</label>
                    </div>
                    <div class="col-md-8">
                        <label class="text-second">{{emptyFormatter(currentDelegate.sector)}}</label>
                    </div>
                </div>

                <div class="row bg-white h-crs-detail m-0 mb-3px">
                    <div class="col-md-4">
                        <label class="text-second fw-normal">Department:</label>
                    </div>
                    <div class="col-md-8">
                        <label class="text-second">{{emptyFormatter(currentDelegate.department)}}</label>
                    </div>
                </div>

                <div class="row bg-white h-crs-detail m-0 mb-3px">
                    <div class="col-md-4">
                        <label class="text-second fw-normal">Address 1:</label>
                    </div>
                    <div class="col-md-8">
                        <label class="text-second">{{emptyFormatter(currentDelegate.address1)}}</label>
                    </div>
                </div>

                <div class="row bg-white h-crs-detail m-0 mb-3px">
                    <div class="col-md-4">
                        <label class="text-second fw-normal">Address 2:</label>
                    </div>
                    <div class="col-md-8">
                        <label class="text-second">{{emptyFormatter(currentDelegate.address2)}}</label>
                    </div>
                </div>

                <div class="row bg-white h-crs-detail m-0 mb-3px">
                    <div class="col-md-4">
                        <label class="text-second fw-normal">Town/City:</label>
                    </div>
                    <div class="col-md-8">
                        <label class="text-second">{{currentDelegate.cityName}}</label>
                    </div>
                </div>

                <div class="row bg-white h-crs-detail m-0 mb-3px">
                    <div class="col-md-4">
                        <label class="text-second fw-normal">Postcode:</label>
                    </div>
                    <div class="col-md-8">
                        <label class="text-second">{{emptyFormatter(currentDelegate.postcode)}}</label>
                    </div>
                </div>

                <div class="row bg-white h-crs-detail m-0 mb-3px">
                    <div class="col-md-4">
                        <label class="text-second fw-normal">Telephone:</label>
                    </div>
                    <div class="col-md-8">
                        <label class="text-second">{{emptyFormatter(currentDelegate.telephone)}}</label>
                    </div>
                </div>

                <div class="row bg-white h-crs-detail m-0 mb-3px">
                    <div class="col-md-4">
                        <label class="text-second fw-normal">Email:</label>
                    </div>
                    <div class="col-md-8">
                        <label class="text-second">{{emptyFormatter(currentDelegate.email)}}</label>
                    </div>
                </div>

                <div class="row bg-white h-crs-detail m-0 mb-3px h-auto">
                    <div class="col-md-4 bg-white">
                        <label class="text-second fw-normal">Notes:</label>
                    </div>
                    <div class="col-md-8 lbl-wraper bg-white pb-3">
                        <label class="text-second lbl-notes" *ngIf="currentDelegate.specialNotes!=null">
                            {{currentDelegate.specialNotes}}
                        </label>
                        <label class="text-second lbl-notes" *ngIf="currentDelegate.specialNotes==null">
                            N/A
                        </label>
                    </div>
                </div>

                <div class="btn-holder mb-3">
                    <button class="book-btn pull-right filter-apply" (click)="updateDelegate()">
                        Update
                    </button>
                </div>

                <div class="row bg-white h-crs-detail m-0 mb-3px h-auto pb-3-">
                    <div class="col-md-4 bg-white">
                        <label class="text-second fw-normal">Postal Address:</label>
                    </div>
                    <div class="col-md-8 lbl-wraper bg-white pb-3 lbl-address">
                        <label class="text-second w-inherit" *ngIf="currentDelegate.postalName!=null">
                            {{currentDelegate.postalName}}<br />
                        </label>
                        <label class="text-second w-inherit" *ngIf="currentDelegate.postalAddress!=null">
                            {{currentDelegate.postalAddress}}<br />
                        </label>
                        <label class="text-second w-inherit" *ngIf="currentDelegate.postalTelephone!=null">
                            {{currentDelegate.postalTelephone}}<br />
                        </label>
                        <label class="text-second w-inherit" *ngIf="currentDelegate.postalCity!=null">
                            {{currentDelegate.postalCity}}<br />
                        </label>
                        <label class="text-second w-inherit" *ngIf="currentDelegate.postalPostcode!=null">
                            {{currentDelegate.postalPostcode}}
                        </label>

                        <label class="text-second lbl-notes-" *ngIf="(currentDelegate.postalPostcode == null) &&
                               (currentDelegate.postalName==null) &&
                               (currentDelegate.postalAddress==null) &&
                               (currentDelegate.postalTelephone==null) &&
                               (currentDelegate.postalCity==null)">
                            N/A
                        </label>
                        <div class="btn-holder">
                            <button class="book-btn pull-right filter-apply" (click)="updatePostalAddress()">
                                Update
                            </button>
                        </div>

                    </div>
                </div>

                <div class="row bg-white h-crs-detail m-0 mb-3px h-auto pb-3-">
                    <div class="col-md-4 bg-white">
                        <label class="text-second fw-normal">Invoice Address:</label>
                    </div>
                    <div class="col-md-8 lbl-wraper bg-white pb-3 lbl-address">
                        <label class="text-second w-inherit" *ngIf="currentDelegate.invoiceName!=null">
                            {{currentDelegate.invoiceName}}
                        </label>
                        <label class="text-second w-inherit-" *ngIf="currentDelegate.invoiceAddress!=null">
                            {{currentDelegate.invoiceAddress}}
                        </label>
                        <label class="text-second w-inherit" *ngIf="currentDelegate.invoiceEmail!=null">
                            {{currentDelegate.invoiceEmail}}
                        </label>
                        <label class="text-second w-inherit" *ngIf="currentDelegate.invoiceDepartment!=null">
                            {{currentDelegate.invoiceDepartment}}
                        </label>
                        <label class="text-second w-inherit" *ngIf="currentDelegate.invoiceOrganization!=null">
                            {{currentDelegate.invoiceOrganization}}
                        </label>
                        <label class="text-second w-inherit" *ngIf="currentDelegate.invoiceTelephone!=null">
                            {{currentDelegate.invoiceTelephone}}
                        </label>
                        <label class="text-second w-inherit" *ngIf="currentDelegate.invoiceCity!=null">
                            {{currentDelegate.invoiceCity}}
                        </label>
                        <label class="text-second w-inherit" *ngIf="currentDelegate.invoicePostcode!=null">
                            {{currentDelegate.invoicePostcode}}
                        </label>


                        <label class="text-second lbl-notes-" *ngIf="(currentDelegate.invoiceName == null) &&
                               (currentDelegate.invoiceAddress==null) &&
                               (currentDelegate.invoiceEmail==null) &&
                               (currentDelegate.invoiceDepartment==null) &&
                               (currentDelegate.invoiceOrganization==null) &&
                               (currentDelegate.invoiceCity==null) &&
                               (currentDelegate.invoiceTelephone==null) &&
                               (currentDelegate.invoicePostcode==null)">
                            N/A
                        </label>

                        <div class="btn-holder">
                            <button class="book-btn pull-right filter-apply" (click)="updateInvoiceAddress()">
                                Update
                            </button>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="col-md-8">

        <div class="row">
            <div class="col-12 flip-border-bottom-white">

                <div id="show-course-container" class="flip-btn pull-left p-2 cursor-pointer" (click)="showCourses()">
                    <!--<button class="book-btn pull-right filter-apply" (click)="showCourses()">
                        show Courses
                    </button>-->
                    <span class="strong m-2">Courses</span>

                </div>
                <div id="show-prod-container" class="flip-btn pull-left bg-white p-2 cursor-pointer" (click)="showProducts()">
                    <!--<button class="book-btn pull-right filter-apply" (click)="showProducts()">
                        Show products
                    </button>-->
                    <span class="strong m-2">Products</span>

                </div>
            </div>
        </div>

        <div class="col-12">
            <div class="col-12" id="course-section">

                <div class="row">
                    <div class="col-md-12">

                        <label class="text-second p-1">Courses</label>


                        <label class="text-second p-1 pull-right p-rl-10px bg-yellow-gradient">{{currentDelegate.bookedCourses.length}}</label>

                        <hr class="u-line-" />

                        <div *ngIf="currentDelegate.bookedCourses.length == 0" class="text-center pt-5">
                            <img class="information-icon" src="assets/images/icone-information.png" />
                            <br />
                            <span class="not-found">No data found</span>
                        </div>

                        <div *ngFor="let crs of currentDelegate.bookedCourses">
                            <div class="row" [ngClass]="(crs.isCancelled == true) ? 'isCancelled' : ''">
                                <div class="col-md-2 pr-0">
                                    <label class="text-second fw-normal">Course Date:</label><br>
                                    <div class="row bg-white h-crs-detail m-0 mb-2">
                                        <div class="col-md-12 p-1 pl-1">
                                            <label class="text-second dt-box-f mt-1">{{crs.courseStartDate |  date:'dd-MM-yy'}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="pr-0" [ngClass]="(crs.cnNumber != null) ? 'col-md-4' : 'col-md-6'">
                                    <label class="text-second fw-normal">Course:</label><br>
                                    <div class="row bg-white h-crs-detail m-0 mb-2">
                                        <div class="col-md-12 crs-margins">
                                            <label class="text-second fw-normal"
                                                   [ngClass]="crs.courseTitle.length > 100 ? 'crs-size-max' : 'crs-size'">
                                                {{crs.courseTitle}}
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-2 pr-0">
                                    <label class="text-second fw-normal">Invoice Date:</label><br />
                                    <div class="row bg-white h-crs-detail m-0 mb-3">
                                        <div class="col-md-12 p-1 pl-1">
                                            <label class="text-second dt-box-f mt-1">{{crs.invoiceDate | date:'dd-MM-yy'}}</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="pr-0" [ngClass]="(crs.cnNumber != null) ? 'col-md-2 d-block-important' : 'd-none-important'">
                                    <label class="text-second fw-normal">Credit Note Number:</label><br />
                                    <div class="row bg-white h-crs-detail m-0 mb-3">
                                        <div class="col-md-12 p-1 pl-1">
                                            <label class="text-second dt-box-f mt-1">{{crs.cnNumber}}</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-2">
                                    <label class="text-second fw-normal">Invoice</label><br>
                                    <div class="row bg-green- h-crs-detail m-0 mb-2" [ngClass]="getStatus(crs)">
                                        <div class="col-md-12 p-1 pl-1 text-center">
                                            <label class="dt-box-f mt-1" [ngClass]="getStatus(crs) == 'redborder-simple' ? 'text-second' : 'text-white'">
                                                {{crs.invoiceNumber}}
                                            </label>
                                        </div>
                                    </div>
                                </div>


                            </div>
                            <div class="row mt-loc-row-" [ngClass]="(crs.isCancelled == true) ? 'isCancelled mb-2' : ''">
                                <!--<div class="col-md-4 pr-0">
                                    <div class="row bg-white h-crs-detail m-0 mb-2-">
                                        <div class="col-md-12">
                                            <label class="text-second fw-normal">Location: &nbsp;</label>
                                            <label class="text-second dt-box-f mt-1">{{crs.location}}</label>
                                        </div>
                                    </div>
                                </div>-->
                                <div class="col-md-2 pr-0">
                                    <label class="text-second fw-normal mt-0">Location:</label><br />
                                    <div class="row bg-white h-crs-detail m-0 mb-3">
                                        <div class="col-md-12 p-1 pl-1">
                                            <label class="text-second dt-box-f mt-1">{{crs.location}}</label>
                                        </div>
                                    </div>
                                </div>



                                <div class="col-md-2 ml-1">
                                    <label class="text-second fw-normal mt-0">Fees:&nbsp; </label><br />
                                    <div class="row bg-white h-crs-detail m-0 mb-2-">
                                        <div class="col-md-12 p-1 pl-1">
                                            <!--<label class="text-second fw-normal">&nbsp;</label>-->
                                            <label class="text-second dt-box-f mt-1">{{crs.courseCost | currency: 'GBP'}}</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-2 ml-1">
                                    <label class="text-second fw-normal mt-0">Date Paid: </label><br>
                                    <div class="row bg-white h-crs-detail m-0 mb-3">
                                        <div class="col-md-12 p-1 pl-1">
                                            <label [ngClass]="{'not-paid': crs.reconcileDate == null}" class="text-second dt-box-f mt-1">&nbsp;{{crs.reconcileDate | date:'dd-MM-yy'}}</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-2">
                                    <label class="text-second fw-normal mt-0">Payment Type:</label><br />
                                    <div class="row bg-white h-crs-detail m-0 mb-2-">
                                        <div class="col-md-12 p-1 pl-1">
                                            <label class="text-second dt-box-f mt-1"> {{crs.transactionType}}</label>
                                            <!--<label class="text-second fw-normal">Type: </label>
                                            <label class="text-second dt-box-f mt-1">&nbsp; {{crs.transactionType}}</label>-->
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-2">
                                    <label class="text-second fw-normal mt-0">PO Number</label><br>
                                    <div class="row bg-white h-crs-detail m-0 mb-2-">
                                        <div class="col-md-12 p-1 pl-1">
                                            <!--<label class="text-second fw-normal">Type: </label>-->
                                            <label class="text-second dt-box-f mt-1"> {{crs.poNumber}}</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-1">
                                    <label class="text-second fw-normal mt-0">Paid: </label><br />
                                    <div class="row bg-white h-crs-detail m-0 mb-2-">
                                        <div class="col-md-12">
                                            <label class="text-second fw-normal pr-2-">&nbsp; </label>
                                            <input type="checkbox"
                                                   id="{{crs.courseId}}_chk"
                                                   (click)="onPaidClick($event, crs)"
                                                   [checked]="crs.isInvoicePaid" />
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-1">
                                    <label class="text-second fw-normal">&nbsp; </label><br />
                                    <div class="row bg-white h-crs-detail m-0 mb-2-">
                                        <div class="col-md-12 pt-1">
                                            <label class="text-second fw-normal pr-2-">&nbsp; </label>
                                            <a href=javascript:void() style="height: 21px" (click)="changeDelegateCourse(crs.courseId)" data-title="Swap course" class=" filter-apply">
                                                <i class="material-icons">swap_horiz</i>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="row mt-loc-row-">



                                <!--<div class="col-md-4">
                                    <label class="text-second fw-normal">Paid: </label><br>
                                    <div class="row bg-white h-crs-detail m-0 mb-3">
                                        <div class="col-md-12 p-1 pl-1">
                                            <div class="pull-center">
                                                <input type="checkbox"
                                                       id="{{crs.courseId}}_chk"
                                                       (click)="onPaidClick($event, crs)"
                                                       [checked]="crs.isInvoicePaid" />
                                            </div>
                                        </div>
                                    </div>
                                </div>-->
                            </div>

                            <div class="col-md-12">

                                <!--<button class="book-btn pull-right filter-apply" (click)="changeDelegateCourse(crs.courseId)">
                                    Change course
                                </button>-->
                            </div>
                            <hr class="u-line" />
                        </div>
                    </div>
                </div>

            </div>


            <div class="col-12" id="prod-section">
                <div class="row">
                    <div class="col-md-12">
                        <button class="book-btn pull-right filter-apply" (click)="addDelegateProduct()">
                            Add product
                        </button>
                    </div>


                    <div *ngIf="currentDelegate.myProducts.length == 0" class="text-center pt-5">
                        <img class="information-icon" src="assets/images/icone-information.png" />
                        <br />
                        <span class="not-found">No data found</span>
                    </div>


                    <div class="col-md-12" *ngIf="currentDelegate.myProducts.length > 0">

                        <label class="text-second  p-1">Products</label>
                        <label class="text-second p-1 pull-right p-rl-10px bg-yellow-gradient">{{currentDelegate.myProducts.length}}</label>
                        <hr />


                        <div *ngFor="let prod of currentDelegate.myProducts">
                            <div class="row"  [ngClass]="(prod.cancelled == true) ? 'isCancelled' : 'ting'">

                                

                                <div *ngIf="prod.invoiceDetail == null" class="prod-h" [ngClass]="(prod.bookedCourses[0]?.cnNumber != null && prod.bookedCourses[0]?.cnNumber != '') ? 'col-8' : 'col-10'">
                                    <div class="col-md-12 bg-white h-crs-detail p-rl-10px">
                                        <label class="text-second">{{prod.productTitle}}</label>
                                    </div>
                                </div>



                                <div *ngIf="prod.invoiceDetail != null" class="prod-h" [ngClass]="(prod.invoiceDetail.creditNoteNumber != null && prod.invoiceDetail.creditNoteNumber != '') ? 'col-8' : 'col-10'">
                                    <div class="col-md-12 bg-white h-crs-detail p-rl-10px">
                                        <label class="text-second">{{prod.productTitle}}</label>
                                    </div>
                                </div>



                                <div *ngIf="prod.invoiceDetail == null" class="prod-h" [ngClass]="(prod.bookedCourses[0]?.cnNumber != null && prod.bookedCourses[0]?.cnNumber != '') ? 'col-md-2 d-block-important' : 'd-none-important'">
                                    <div class="row bg-white h-crs-detail m-0 mb-2">
                                        <!--foundation certificate-->
                                        <div class="col-md-12 p-1 pl-1 text-center">

                                            <label *ngIf="prod.invoiceDetail == null" class="text-second dt-box-f mt-1"> {{prod.bookedCourses[0]?.cnNumber}}</label>
                                            <label *ngIf="prod.invoiceDetail != null" class="text-second dt-box-f mt-1"> {{prod.invoiceDetail.creditNoteNumber}}</label>
                                        </div>
                                    </div>
                                </div>



                                <div *ngIf="prod.invoiceDetail != null" class=" prod-h" [ngClass]="(prod.invoiceDetail.creditNoteNumber != null && prod.invoiceDetail.creditNoteNumber != '') ? 'col-md-2 d-block-important' : 'd-none-important'">
                                    <div class="row bg-white h-crs-detail m-0 mb-2">
                                        <!--foundation certificate-->
                                        <div class="col-md-12 p-1 pl-1 text-center">

                                            <label *ngIf="prod.invoiceDetail == null" class="text-second dt-box-f mt-1"> {{prod.bookedCourses[0]?.cnNumber}}</label>
                                            <label *ngIf="prod.invoiceDetail != null" class="text-second dt-box-f mt-1"> {{prod.invoiceDetail.creditNoteNumber}}</label>
                                        </div>
                                    </div>
                                </div>



                                <div class="col-md-2 pr-0 prod-h">
                                    <!--<label class="text-second fw-normal">&nbsp;</label><br>-->

                                    <div *ngIf="prod.invoiceDetail == null" class="row bg-green- h-crs-detail m-0 mb-2" [ngClass]="getStatus(prod.bookedCourses[0])">
                                        <!--foundation certificate-->
                                        <div class="col-md-12 p-1 pl-1 text-center">
                                            <label class="dt-box-f mt-1" [ngClass]="getStatus(prod.bookedCourses[0]) == 'redborder-simple' ? 'text-second' : 'text-white'">
                                                {{prod.bookedCourses[0].invoiceNumber}}
                                            </label>
                                        </div>
                                    </div>


                                    <div *ngIf="prod.invoiceDetail != null" class="row bg-green- h-crs-detail m-0 mb-2" [ngClass]="getProductStatus(prod.invoiceDetail)">
                                        <div class="col-md-12 p-1 pl-1 text-center">
                                            <label class="dt-box-f mt-1" [ngClass]="getProductStatus(prod.invoiceDetail) == 'redborder-simple' ? 'text-second' : 'text-white'">
                                                {{prod.invoiceDetail.invoiceNumber}}
                                            </label>
                                        </div>
                                    </div>

                                </div>


                                <br />

                                <div *ngFor="let crs of prod.bookedCourses">


                                    <div class="row">
                                        <div class="col-md-3 pr-0">
                                            <label class="text-second fw-normal">Date:</label><br>
                                            <div class="row bg-white h-crs-detail m-0 mb-2">
                                                <div class="col-md-12 p-1 pl-1">
                                                    <label class="text-second dt-box-f mt-1">{{crs.courseStartDate |  date:'dd-MM-yy'}}</label>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="col-md-5 pr-0">
                                            <label class="text-second fw-normal">Course:</label><br>
                                            <div class="row bg-white h-crs-detail m-0 mb-2">
                                                <div class="col-md-12 crs-margins">
                                                    <label class="text-second fw-normal"
                                                           [ngClass]="crs.courseTitle.length > 100 ? 'crs-size-max' : 'crs-size'">
                                                        {{crs.courseTitle}}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-3 pr-0">
                                            <label class="text-second fw-normal">Location:</label><br />
                                            <div class="row bg-white h-crs-detail m-0 mb-3">
                                                <div class="col-md-12 p-1 pl-1">
                                                    <label class="text-second dt-box-f mt-1">{{crs.location}}</label>
                                                </div>
                                            </div>
                                        </div>



                                        <div *ngIf="prod.invoiceDetail == null" class="col-md-1">
                                            <!--showing only for foundation certificate-->
                                            <label class="text-second fw-normal">&nbsp;</label><br>

                                            <div class="row bg-white h-crs-detail m-0 mb-2-">
                                                <a href=javascript:void() style="height: 21px" (click)="editDelegateFC(crs)" class="text-center filter-apply">
                                                    <i class="material-icons">edit</i>
                                                </a>
                                            </div>
                                        </div>

                                    </div>
                                    <!--<div class="row mt-loc-row-">

                                        <div class="col-md-4 ml-1">
                                            <div class="row bg-white h-crs-detail m-0 mb-2-">
                                                <div class="col-md-12">
                                                    <label class="text-second fw-normal">Fees: </label>
                                                    <label class="text-second dt-box-f mt-1">&nbsp; &#163;{{crs.courseCost}}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="row bg-white h-crs-detail m-0 mb-2-">
                                                <div class="col-md-12">
                                                    <label class="text-second fw-normal">Type: </label>
                                                    <label class="text-second dt-box-f mt-1">&nbsp; {{crs.transactionType}}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="row bg-white h-crs-detail m-0 mb-2-">
                                                <div class="col-md-12">
                                                    <label class="text-second fw-normal pr-2">Paid: </label>
                                                    <input type="checkbox"
                                                           id="{{crs.courseId}}_chk"
                                                           (click)="onPaidClick($event, crs)"
                                                           [checked]="crs.isInvoicePaid" />

                                                </div>
                                            </div>
                                        </div>
                                    </div>-->
                                    <div class="row mt-loc-row-">

                                    </div>


                                    <hr class="u-line" />
                                </div>

                                <div class="col-md-2 pr-0">
                                    <label class="text-second fw-normal">Invoice Date:</label><br />
                                    <div class="row bg-white h-crs-detail m-0 mb-3">
                                        <div class="col-md-12 p-1 pl-1">
                                            <label *ngIf="prod.invoiceDetail == null" class="text-second dt-box-f mt-1">{{prod.bookedCourses[0].invoiceDate | date:'dd-MM-yy'}}</label>
                                            <label *ngIf="prod.invoiceDetail != null" class="text-second dt-box-f mt-1">{{prod.invoiceDetail.dateAdded | date:'dd-MM-yy'}}</label>
                                        </div>
                                    </div>
                                </div>




                                <div class="col-md-2 ml-1-">
                                    <label class="text-second fw-normal">Fees: </label><br>
                                    <div class="row bg-white h-crs-detail m-0 mb-2-">
                                        <div class="col-md-12 p-1 pl-1">
                                            <label *ngIf="prod.invoiceDetail == null" class="text-second dt-box-f mt-1"> {{prod.bookedCourses[0].courseCost  | currency: 'GBP'}}</label>
                                            <label *ngIf="prod.invoiceDetail != null" class="text-second dt-box-f mt-1"> {{prod.invoiceDetail.totalAmount  | currency: 'GBP'}}</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-2 ml-1">
                                    <label class="text-second fw-normal">Date Paid: </label><br>
                                    <div class="row bg-white h-crs-detail m-0 mb-3">
                                        <div class="col-md-12 p-1 pl-1">
                                            <label *ngIf="prod.invoiceDetail == null" [ngClass]="{'not-paid': prod.bookedCourses[0].reconcileDate == null}" class="text-second dt-box-f mt-1">&nbsp;{{prod.bookedCourses[0].reconcileDate | date:'dd-MM-yy'}}</label>
                                            <label *ngIf="prod.invoiceDetail != null" [ngClass]="{'not-paid': prod.invoiceDetail.reconcileOn == null}" class="text-second dt-box-f mt-1">&nbsp;{{prod.invoiceDetail.reconcileOn | date:'dd-MM-yy'}}</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-2">
                                    <label class="text-second fw-normal">Payment Type</label><br>
                                    <div class="row bg-white h-crs-detail m-0 mb-2-">
                                        <div class="col-md-12 p-1 pl-1">
                                            <!--<label class="text-second fw-normal">Type: </label>-->
                                            <label *ngIf="prod.invoiceDetail == null" class="text-second dt-box-f mt-1"> {{prod.bookedCourses[0].transactionType}}</label>
                                            <label *ngIf="prod.invoiceDetail != null" class="text-second dt-box-f mt-1"> {{prod.invoiceDetail.transactionType}}</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-2">
                                    <label class="text-second fw-normal">PO Number</label><br>
                                    <div class="row bg-white h-crs-detail m-0 mb-2-">
                                        <div class="col-md-12 p-1 pl-1">
                                            <!--<label class="text-second fw-normal">Type: </label>-->
                                            <label *ngIf="prod.invoiceDetail == null" class="text-second dt-box-f mt-1"> {{prod.bookedCourses[0].poNumber}}</label>
                                            <label *ngIf="prod.invoiceDetail != null" class="text-second dt-box-f mt-1"> {{prod.poNumber}}</label>
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="prod.invoiceDetail == null" class="col-md-2">
                                    <!--will appear only for foundation certificate-->

                                    <label class="text-second fw-normal">Paid:</label><br>

                                    <div class="row bg-white h-crs-detail m-0 mb-2-">
                                        <div class="col-md-12 text-center">
                                            <label class="text-second fw-normal pr-2-">&nbsp; </label>

                                            <!--foundation certificate-->
                                            <input *ngIf="prod.invoiceDetail == null" type="checkbox"
                                                   id="{{prod.bookedCourses[0].bookingId}}_chk"
                                                   (click)="on_FC_PaidClick($event, prod, prod.bookedCourses[0])"
                                                   [checked]="prod.bookedCourses[0].isInvoicePaid" />

                                            <input *ngIf="prod.invoiceDetail != null" type="checkbox"
                                                   id="{{prod.invoiceDetail.purchaseId}}_chk"
                                                   (click)="on_Product_PaidClick($event, prod, prod.invoiceDetail)"
                                                   [checked]="prod.invoiceDetail.isInvoicePaid" />

                                        </div>
                                    </div>
                                </div>



                                <div *ngIf="prod.invoiceDetail != null" class="col-md-2">
                                    <!--showing only for products not for foundation certificate-->
                                    <label class="text-second fw-normal">&nbsp;</label><br>

                                    <div class="row bg-white h-crs-detail m-0 mb-2-">
                                        <a href=javascript:void() style="height: 21px" (click)="editDelegateProduct(prod)" class="text-center filter-apply">
                                            <i class="material-icons">edit</i>
                                        </a>
                                    </div>
                                </div>








                                <hr />

                            </div>
                        </div>

                        <div class="col-md-12" *ngIf="currentDelegate.myProducts.length == 0">

                            <label class="text-second  p-1">Products</label>
                            <label class="text-second p-1 pull-right">{{currentDelegate.myProducts.length}}</label>
                            <hr />

                            <div class="row">
                                <div class="col-md-12 pr-0">
                                    <label class="text-second fw-normal">No Product Purchased</label><br>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>









    <!--<div class="col-8">

        <div class="container">
            <div class="front">Front</div>
            <div class="back">Back</div>
        </div>


    </div>-->



</div>

<ng-template #modalEditInvoice let-close="close">

    <div class="modal-header primary">

        <h5 class="modal-title primary">Invoice Address</h5>

    </div>

    <div class="p-4">

        <form class="form-horizontal" [formGroup]="invoiceEditForm" (ngSubmit)="onInvoiceEditSubmit()" name="invoiceEditForm" autocomplete="off">

            <div class="row">

                <div class="col-md-6">

                    <label class="text-second">Name:</label>
                    <input type="text" class="form-control no-radius" placeholder="Delegate name"
                           [ngClass]="{  'is-invalid': f.invoiceName.errors}"
                           required formControlName="invoiceName" maxlength="250" />
                </div>
                <div class="col-6">
                    <label class="text-second">Organisation:</label>
                    <input type="text" class="form-control no-radius" placeholder="Organisation"
                           formControlName="invoiceOrganization" maxlength="250" />
                </div>
                <div class="col-6">
                    <label class="text-second">Department:</label>
                    <input type="text" class="form-control no-radius" placeholder="Department"
                           formControlName="invoiceDepartment" maxlength="250" />

                </div>
                <div class="col-6">
                    <label class="text-second">Telephone:</label>
                    <input type="text" class="form-control no-radius" placeholder="Telephone"
                           formControlName="invoiceTelephone" maxlength="50" />

                </div>
                <div class="col-12">
                    <label class="text-second">Address:</label>
                    <input type="text" class="form-control no-radius" placeholder="Address"
                           [ngClass]="{  'is-invalid': f.invoiceAddress.errors}"
                           required formControlName="invoiceAddress" maxlength="250" />
                </div>
                <div class="col-4">
                    <label class="text-second">Email:</label>
                    <input type="text" class="form-control no-radius" placeholder="Email"
                           [ngClass]="{  'is-invalid': f.invoiceEmail.errors && f.invoiceEmail.errors.pattern}"
                           required formControlName="invoiceEmail" maxlength="250" />
                    <div *ngIf="f.invoiceEmail.errors" class="invalid-feedback">
                        <div *ngIf="f.invoiceEmail.errors.pattern">{{errors.invoiceEmail}}</div>
                    </div>
                </div>

                <div class="col-4">
                    <label class="text-second">City:</label>
                    <!--<mat-select formControlName="invoiceCity" #select
                                class="form-control no-radius text-second">
                        <mat-option *ngFor="let loc of locationsList" [value]="loc.cityId">{{loc.cityName}}</mat-option>
                    </mat-select>-->
                    <input type="text" class="form-control no-radius" placeholder="Town/City"
                           [ngClass]="{  'is-invalid': f.invoiceCity.errors}"
                           required formControlName="invoiceCity" maxlength="250" />
                    <div *ngIf="f.invoiceCity.errors" class="invalid-feedback mt-0">
                        <div *ngIf="f.invoiceCity.errors.pattern">{{errors.required}}</div>
                    </div>
                </div>
                <div class="col-4">
                    <label class="text-second">Postcode:</label>
                    <input type="text" class="form-control no-radius" placeholder="Postcode eg: AA123AA" pattern="^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$"
                           [ngClass]="{  'is-invalid': f.invoicePostcode.errors}"
                           required formControlName="invoicePostcode" maxlength="250" />
                    <div *ngIf="f.invoicePostcode.errors" class="invalid-feedback mt-0">
                        <div *ngIf="f.invoicePostcode.errors.pattern">{{errors.required}}</div>
                    </div>

                </div>
            </div>

            <div class="modal-footer">
                <button type="button" class="book-btn" (click)="close()">
                    Cancel
                </button>
                <div class="">
                    <button [disabled]="!invoiceEditForm.valid"
                            class="book-btn">
                        Save
                    </button>
                </div>

            </div>
        </form>
    </div>

</ng-template>



<ng-template #modalEditPostal let-close="close">

    <div class="modal-header primary">

        <h5 class="modal-title primary">Postal Address</h5>

    </div>

    <div class="p-4">

        <form class="form-horizontal" [formGroup]="invoicePostalForm" (ngSubmit)="onInvoicePostalSubmit()" name="invoicePostalForm" autocomplete="off">

            <div class="row">

                <div class="col-12">

                    <label class="text-second">Name:</label>
                    <input type="text" class="form-control no-radius" placeholder="Delegate name"
                           [ngClass]="{  'is-invalid': p.postalName.errors}"
                           required formControlName="postalName" maxlength="250" />
                </div>

                <div class="col-12">
                    <label class="text-second">Address:</label>
                    <input type="text" class="form-control no-radius" placeholder="Address"
                           [ngClass]="{  'is-invalid': p.postalAddress.errors}"
                           required formControlName="postalAddress" maxlength="250" />
                </div>

                <div class="col-4">
                    <label class="text-second">Telephone:</label>
                    <input type="text" class="form-control no-radius" placeholder="Telephone"
                           formControlName="postalTelephone" maxlength="50" />
                </div>

                <div class="col-4">

                    <label class="text-second">City:</label>
                    <!--<mat-select formControlName="postalCity" #select
                                class="form-control no-radius text-second">
                        <mat-option *ngFor="let loc of locationsList" [value]="loc.cityId">{{loc.cityName}}</mat-option>
                    </mat-select>-->

                    <input type="text" class="form-control no-radius" placeholder="Town/City"
                           [ngClass]="{  'is-invalid': p.postalCity.errors}"
                           required formControlName="postalCity" maxlength="250" />
                    <div *ngIf="p.postalCity.errors" class="invalid-feedback mt-0">
                        <div *ngIf="p.postalCity.errors.pattern">{{errors.required}}</div>
                    </div>
                </div>

                <div class="col-4">
                    <label class="text-second">Postcode:</label>
                    <input type="text" class="form-control no-radius" placeholder="Postcode eg: AA123AA" pattern="^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$"
                           [ngClass]="{  'is-invalid': p.postalPostcode.errors}"
                           required formControlName="postalPostcode" maxlength="250" />
                    <div *ngIf="p.postalPostcode.errors" class="invalid-feedback mt-0">
                        <div *ngIf="p.postalPostcode.errors.pattern">{{errors.required}}</div>
                    </div>

                </div>
            </div>

            <div class="modal-footer">
                <button type="button" class="book-btn" (click)="close()">
                    Cancel
                </button>
                <div class="">
                    <button [disabled]="!invoicePostalForm.valid"
                            class="book-btn">
                        Save
                    </button>
                </div>

            </div>
        </form>
    </div>

</ng-template>


<ng-template #modalPaymentDetails let-close="close">
    <div class="modal-header primary">
        <h5 class="modal-title primary">Payment Details</h5>
    </div>
    <div class="modal-body- p-4">


        <form class="form-horizontal" [formGroup]="paymentForm" (ngSubmit)="onPaymentSubmit()" name="cuForm" autocomplete="off">


            <div class="row">



                <div [ngClass]="(isCreditNote == true) ? 'col-4' : 'col-6'">

                    <label class="text-second">Invoice Number:</label>
                    <input type="text" class="form-control no-radius" placeholder="Invoice Number"
                           [ngClass]="{  'is-invalid': i.invoiceNumber.errors}"
                           required formControlName="invoiceNumber" maxlength="250" />
                    <div *ngIf="i.invoiceNumber.errors" class="invalid-feedback">
                        <div *ngIf="i.invoiceNumber.errors.pattern">{{errors.courseTitle}}</div>
                    </div>
                </div>

                <div [ngClass]="(isCreditNote == true) ? 'col-4' : 'col-6'">
                    <label class="text-second">Paid By:</label>

                    <mat-select *ngIf="true" formControlName="source" #select [(ngModel)]="selectedPaymentMethod"
                                id="paymentMethod"
                                (selectionChange)="onPaymentMethodChanged($event)"
                                class="form-control no-radius text-second mt-3-">

                        <mat-option selected value="none" class=" text-second">Select source</mat-option>

                        <mat-option value="BACS">
                            BACS
                        </mat-option>

                        <mat-option value="Cash">
                            Cash
                        </mat-option>

                        <mat-option value="Cheque">
                            Cheque
                        </mat-option>

                        <mat-option value="Credit Note">
                            Credit Note
                        </mat-option>

                        <mat-option value="Direct Debit">
                            Direct Debit
                        </mat-option>

                        <mat-option value="AMEX">
                            AMEX
                        </mat-option>

                        <mat-option value="Paypal">
                            Paypal
                        </mat-option>

                        <mat-option value="Phone">
                            Phone
                        </mat-option>

                        <mat-option value="Shopify">
                            Shopify
                        </mat-option>

                        <mat-option value="Written Off">
                            Written Off
                        </mat-option>

                        <mat-option value="Other">
                            Other
                        </mat-option>

                        <mat-option value="Cancelled">
                            Cancelled
                        </mat-option>

                    </mat-select>

                </div>

                <div [ngClass]="(isCreditNote == true) ? 'col-4 d-block-important' : 'd-none-important'">
                    <label class="text-second">Credit Note Number:</label>
                    <input type="text" class="form-control no-radius" placeholder="Credit note number"
                           formControlName="cnNumber" maxlength="20" />
                </div>

                <div class="col-4">
                    <label class="text-second">Subtotal:</label>
                    <input type="text" class="form-control no-radius" placeholder="Subtotal"
                           [ngClass]="{  'is-invalid': i.subtotal.errors}"
                           required formControlName="subtotal" maxlength="250" (input)="applyTax($event.target.value)" />
                    <div *ngIf="i.subtotal.errors" class="invalid-feedback">
                        <div *ngIf="i.subtotal.errors.pattern">{{errors.courseTitle}}</div>
                    </div>
                </div>
                <div class="col-4">
                    <label class="text-second">Total Tax:</label>
                    <input type="text" class="form-control no-radius" placeholder="Total Tax"
                           [ngClass]="{  'is-invalid': i.totalTax.errors}"
                           required formControlName="totalTax" maxlength="250" />
                    <div *ngIf="i.totalTax.errors" class="invalid-feedback">
                        <div *ngIf="i.totalTax.errors.pattern">{{errors.courseTitle}}</div>
                    </div>
                </div>
                <div class="col-4">
                    <label class="text-second">Total:</label>
                    <input type="text" class="form-control no-radius" placeholder="Total"
                           [ngClass]="{  'is-invalid': i.total.errors}"
                           required formControlName="total" maxlength="250" />
                    <div *ngIf="i.total.errors" class="invalid-feedback">
                        <div *ngIf="i.total.errors.pattern">{{errors.courseTitle}}</div>
                    </div>
                </div>

                <div class="col-4">
                    <label class="text-second">PO Number:</label>
                    <input type="text" class="form-control no-radius" placeholder="Purchase order number"
                           formControlName="ponumber" maxlength="100" />
                </div>

                <div class="col-4">
                    <div class="col-md-12  mt-3">
                        <mat-form-field appearance="fill" ngDefaultControl class="w-100">
                            <mat-label>Payment date</mat-label>
                            <input matInput [matDatepicker]="picker_paymentDate" formControlName="p_paymentDate" placeholder=""
                                   [ngModel]="paymentDate" (dateChange)="addEvent($event)">
                            <mat-datepicker-toggle matSuffix [for]="picker_paymentDate"></mat-datepicker-toggle>
                            <mat-datepicker [startAt]="_date" #picker_paymentDate></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <!--[ngClass]="{'is-invalid':  submitted && i.p_paymentDate.errors}"-->
                </div>
                <div class="col-2 pt-2">
                    <mat-checkbox formControlName="reconcile" [ngModel]="_reconcile">Reconcile</mat-checkbox>
                </div>

                <div class="col-2 pt-2">
                    <mat-checkbox formControlName="isPaired" [ngModel]="_isPaired">Paired</mat-checkbox>
                </div>

            </div>

            <div class="modal-footer">
                <button type="button" class="book-btn" (click)="close(); close2($event);">
                    Cancel
                </button>

                <div class="">
                    <button [disabled]="!paymentForm.valid"
                            class="book-btn">
                        Save
                    </button>

                </div>
            </div>

        </form>



    </div>

</ng-template>


<ng-template #modalAddDelegateProduct let-close="close">
    <div class="modal-header primary">
        <h5 class="modal-title primary">Add Product</h5>
    </div>
    <div class="modal-body- p-4">


        <form class="form-horizontal" [formGroup]="delegateProductForm" (ngSubmit)="onDelegateProductSubmit()" name="delegateProductForm" autocomplete="off">


            <div class="row">

                <div [ngClass]="(isCreditNote == true) ? 'col-3' : 'col-4'">
                    <label class="text-second">Product:</label>
                    <mat-select formControlName="productName" #select (selectionChange)="populateTitleData($event)"
                                class="form-control no-radius text-second">
                        <mat-option selected value="none" class=" text-second">Select product</mat-option>
                        <mat-option *ngFor="let loc of productList" [value]="loc.id">{{loc.title}}</mat-option>
                    </mat-select>
                </div>

                <div [ngClass]="(isCreditNote == true) ? 'col-3' : 'col-4'">

                    <label class="text-second">Invoice Number:</label>
                    <input type="text" class="form-control no-radius" placeholder="Invoice Number"
                           [ngClass]="{  'is-invalid': i.invoiceNumber.errors}"
                           required formControlName="invoiceNumber" maxlength="250" />
                    <div *ngIf="i.invoiceNumber.errors" class="invalid-feedback">
                        <div *ngIf="i.invoiceNumber.errors.pattern">{{errors.courseTitle}}</div>
                    </div>
                </div>


                <div [ngClass]="(isCreditNote == true) ? 'col-3' : 'col-4'">
                    <label class="text-second">Paid By:</label>

                    <mat-select *ngIf="true" formControlName="source" #select [(ngModel)]="selectedPaymentMethod"
                                id="paymentMethod"
                                (selectionChange)="onPaymentMethodChanged($event)"
                                class="form-control no-radius text-second mt-3-">

                        <mat-option selected value="none" class=" text-second">Select source</mat-option>

                        <mat-option value="BACS">
                            BACS
                        </mat-option>

                        <mat-option value="Cash">
                            Cash
                        </mat-option>

                        <mat-option value="Cheque">
                            Cheque
                        </mat-option>

                        <mat-option value="Credit Note">
                            Credit Note
                        </mat-option>

                        <mat-option value="Direct Debit">
                            Direct Debit
                        </mat-option>

                        <mat-option value="AMEX">
                            AMEX
                        </mat-option>

                        <mat-option value="Paypal">
                            Paypal
                        </mat-option>

                        <mat-option value="Phone">
                            Phone
                        </mat-option>

                        <mat-option value="Shopify">
                            Shopify
                        </mat-option>

                        <mat-option value="Written Off">
                            Written Off
                        </mat-option>

                        <mat-option value="Other">
                            Other
                        </mat-option>

                        <mat-option value="Cancelled">
                            Cancelled
                        </mat-option>

                    </mat-select>

                </div>

                <div [ngClass]="(isCreditNote == true) ? 'col-3 d-block-important' : 'd-none-important'">
                    <label class="text-second">Credit Note Number:</label>
                    <input type="text" class="form-control no-radius" placeholder="Credit note number"
                           formControlName="cnNumber" maxlength="20" />
                </div>

                <div class="col-4">
                    <label class="text-second">Quantity:</label>
                    <input type="text" class="form-control no-radius" placeholder="Quantity"
                           formControlName="quantity" maxlength="3" (input)="applyProductTax(true);" />
                    <!--<div *ngIf="d.totalTax.errors" class="invalid-feedback">
            <div *ngIf="d.totalTax.errors.pattern">{{errors.courseTitle}}</div>
        </div>-->
                </div>

                <div class="col-4">
                    <label class="text-second">Unit price:</label>
                    <input type="text" class="form-control no-radius" placeholder="Subtotal"
                           [ngClass]="{  'is-invalid': d.subtotal.errors}"
                           (keypress)="validateAmount($event, $event.target.value)"
                           required formControlName="subtotal" maxlength="8" (input)="applyProductTax(true);" />
                    <div *ngIf="d.subtotal.errors" class="invalid-feedback">
                        <div *ngIf="d.subtotal.errors.pattern">{{errors.courseTitle}}</div>
                    </div>
                </div>

                <div class="col-4">
                    <label class="text-second">Shipping:</label>
                    <input type="text" class="form-control no-radius" placeholder="Shipping cost"
                           [ngClass]="{  'is-invalid': d.shippingCost.errors}"
                           (keypress)="validateAmount($event, $event.target.value)"
                           formControlName="shippingCost" maxlength="5" (input)="applyProductTax(true);" />
                    <div *ngIf="d.shippingCost.errors" class="invalid-feedback">
                        <div *ngIf="d.shippingCost.errors.pattern">{{errors.courseTitle}}</div>
                    </div>
                </div>

                <div class="col-4">
                    <label class="text-second">Total Tax:</label>
                    <input type="text" class="form-control no-radius" placeholder="Total Tax"
                           formControlName="totalTax" maxlength="8" (input)="applyProductTax(false);" />
                    <div *ngIf="d.totalTax.errors" class="invalid-feedback">
                        <div *ngIf="d.totalTax.errors.pattern">{{errors.courseTitle}}</div>
                    </div>
                </div>
                <div class="col-4">
                    <label class="text-second">Total:</label>
                    <input type="text" class="form-control no-radius" placeholder="Total"
                           [ngClass]="{  'is-invalid': d.total.errors}"
                           required formControlName="total" maxlength="8" />
                    <div *ngIf="d.total.errors" class="invalid-feedback">
                        <div *ngIf="d.total.errors.pattern">{{errors.courseTitle}}</div>
                    </div>
                </div>

                <div class="col-4">
                    <label class="text-second">PO Number:</label>
                    <input type="text" class="form-control no-radius" placeholder="Purchase order number"
                           formControlName="ponumber" maxlength="100" />
                </div>

                <div class="col-4">
                    <div class="col-md-12  mt-3">
                        <mat-form-field appearance="fill" ngDefaultControl class="w-100">
                            <mat-label>Payment date</mat-label>
                            <input matInput [matDatepicker]="picker_productPaymentDate" formControlName="prod_paymentDate" placeholder=""
                                   [ngModel]="prodPaymentDate" (dateChange)="addEvent($event)">
                            <mat-datepicker-toggle matSuffix [for]="picker_productPaymentDate"></mat-datepicker-toggle>
                            <mat-datepicker #picker_productPaymentDate></mat-datepicker>
                        </mat-form-field>

                    </div>
                    <!--[ngClass]="{'is-invalid':  submitted && i.p_paymentDate.errors}"-->
                </div>
                <div class="col-2 pt-2">
                    <mat-checkbox formControlName="reconcile" [ngModel]="_reconcile">Reconcile</mat-checkbox>
                </div>

                <div class="col-2 pt-2">
                    <mat-checkbox formControlName="isPaired" [ngModel]="_isPaired">Paired</mat-checkbox>
                </div>

            </div>

            <div class="modal-footer">
                <button type="button" class="book-btn" (click)="close();">
                    Cancel
                </button>

                <div class="">
                    <button [disabled]="!delegateProductForm.valid"
                            class="book-btn">
                        Save
                    </button>

                </div>
            </div>

        </form>



    </div>

</ng-template>


<ng-template #modalChangeDelegateCourse let-close="close">
    <div class="modal-header primary">
        <h5 class="modal-title primary">Change Course</h5>
    </div>
    <div class="modal-body- p-4">


        <form class="form-horizontal" [formGroup]="delegateCourseChangeForm" (ngSubmit)="onDelegateCourseChangeSubmit()" name="delegateCourseChangeForm" autocomplete="off">


            <div class="row">

                <div class="col-12">
                    <label class="text-second">Select course to change:</label>
                    <mat-select formControlName="courseTitle" #select
                                class="form-control no-radius text-second">
                        <mat-option selected value="none" class=" text-second">Select course</mat-option>
                        <mat-option *ngFor="let course of coursesList" [value]="course.courseId">{{course.startDate | date:'dd-MM-yy'}} - {{course.title}}</mat-option>
                    </mat-select>
                </div>

            </div>

            <div class="modal-footer">
                <button type="button" class="book-btn" (click)="close();">
                    Cancel
                </button>

                <div class="">
                    <button [disabled]="!delegateCourseChangeForm.valid"
                            class="book-btn">
                        Save
                    </button>

                </div>
            </div>

        </form>



    </div>

</ng-template>

<ng-template #modalEditFoundationCourse let-close="close">
    <div class="modal-header primary">
        <h5 class="modal-title primary">Edit Foundation Certificate</h5>
    </div>
    <div class="modal-body- p-4">


        <form class="form-horizontal" [formGroup]="editFoundationCourseForm" (ngSubmit)="onEditFoundationCourseSubmit()" name="editFoundationCourseForm" autocomplete="off">


            <div class="row panel- mt-2 subscribe-style- bg-white">
                <div class="fw-bold text-center">LIVE WEBINARS</div>

                <div class="green_text">
                    Please choose a date to participate in the live webinars below.
                </div>

                <div *ngIf="showDD == 'Introduction to GDPR Webinar'" class="col-12">

                    <label class="text-second">Introduction to GDPR Webinar</label>
                    <mat-select formControlName="dd_GDPR" #select
                                (selectionChange)='onGDPRSelected($event)'
                                class="text-left form-control no-radius">
                        <mat-option selected value="none" class=" text-second">Select date</mat-option>
                        <mat-option *ngFor="let gdpr of gdprList" [value]="gdpr.courseId + ',' + gdpr.bookingId">{{gdpr.courseDate | date:'dd-MM-yy'}}</mat-option>
                    </mat-select>

                </div>

                <div *ngIf="showDD == 'Introduction to FOI Webinar'" class="col-12">
                    <label class="text-second">Introduction to FOI Webinar</label>
                    <mat-select formControlName="dd_FOI" #select
                                (selectionChange)='onFOISelected($event)'
                                class="text-left form-control no-radius">
                        <mat-option selected value="" class=" text-second">Select date</mat-option>
                        <mat-option *ngFor="let foi of foiList" [value]="foi.courseId + ',' + foi.bookingId">{{foi.courseDate | date:'dd-MM-yy'}}</mat-option>
                    </mat-select>
                </div>

                <div *ngIf="showDD == 'Introduction to Records Management Webinar'" class="col-12">
                    <label class="text-second">Introduction to RM Webinar</label>
                    <mat-select formControlName="dd_RM" #select
                                (selectionChange)='onRMSelected($event)'
                                class="text-left form-control no-radius">
                        <mat-option selected value="" class=" text-second">Select date</mat-option>
                        <mat-option *ngFor="let rm of rmList" [value]="rm.courseId + ',' + rm.bookingId">{{rm.courseDate | date:'dd-MM-yy'}}</mat-option>
                    </mat-select>
                </div>
            </div>

            <div class="modal-footer">
                <button type="button" class="book-btn" (click)="close();">
                    Cancel
                </button>

                <div class="">
                    <button [disabled]="!editFoundationCourseForm.valid"
                            class="book-btn">
                        Save
                    </button>

                </div>
            </div>

        </form>



    </div>

</ng-template>










