import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdminRoutingModule } from './admin-routing.module';
import { UploadCoursesComponent } from '../upload-courses/upload-courses.component';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DebtCollectionComponent } from '../reports/debt-collection/debt-collection.component';
import { SpeakerCourseNumbersComponent } from '../reports/speaker-course-numbers/speaker-course-numbers.component';
import { AgGridModule } from 'ag-grid-angular';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { SpeakersListComponent } from '../speakers/speakers-list/speakers-list.component';
import { AddEditSpeakersComponent } from '../speakers/add-edit-speakers/add-edit-speakers.component';
import { SpeakerDetailsComponent } from '../speakers/speaker-details/speaker-details.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SpeakersClickableParentComponent } from '../speakers/speakers-clickable-parent/speakers-clickable-parent.component';
import { SpeakersActionsComponent } from '../speakers/speakers-actions/speakers-actions.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

@NgModule({
    declarations: [
        UploadCoursesComponent,
        DebtCollectionComponent,
        SpeakerCourseNumbersComponent,
        SpeakersListComponent,
        AddEditSpeakersComponent,
        SpeakerDetailsComponent,
        SpeakersClickableParentComponent,
        SpeakersActionsComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatCardModule,
        MatMenuModule,
        MatIconModule,
        AdminRoutingModule,
        AgGridModule,        
        MatSelectModule,
        MatInputModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatCheckboxModule,
        MatButtonToggleModule
    ],
    exports: [
        UploadCoursesComponent,
        DebtCollectionComponent,
        SpeakerCourseNumbersComponent,
        SpeakersListComponent,
        AddEditSpeakersComponent,
        SpeakerDetailsComponent
    ]
})
export class AdminModule { }
