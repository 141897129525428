import { HttpClient } from '@angular/common/http';
import { Component, OnInit, EventEmitter, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AgGridAngular } from 'ag-grid-angular';
import { CellClickedEvent, ColDef, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { CommonServiceService } from '../../../common/_services/common-service.service';
import { DelegateService } from '../../../common/_services/delegate-services';
import { AddEditDelegateComponent } from '../add-edit-delegate/add-edit-delegate.component';
import { ClickableParentComponent } from './clickable-parent/clickable-parent.component';

@Component({
    selector: 'app-view-delegate',
    templateUrl: './view-delegate.component.html',
    styleUrls: ['./view-delegate.component.css']
})
export class ViewDelegateComponent implements OnInit {

    delegatesList: any;
    domLayout;
    private gridApi;
    gridOptions: any;

    _methodToload: any = "";
    listTitle: any = "";
    paginationPageSize: any = 50;

    

    @Output() gotoMainMenu = new EventEmitter<void>();

    // Each Column Definition results in one Column.
    public columnDefs: ColDef[] = [
        { field: 'name', width: 325 },
        { field: 'organization', headerName: 'Organisation', width: 225 },
        { field: 'sector', headerName: 'Sector', width: 225, valueFormatter: this.emptyFormatter },
        { field: 'department', width: 225, valueFormatter: this.emptyFormatter },
        { field: 'email', width: 250, valueFormatter: this.emptyFormatter },
        { field: 'telephone', width: 225, valueFormatter: this.emptyFormatter },
        {
            field: 'Action', cellRendererFramework: ClickableParentComponent,
            sortable: false, filter: false, width: 150, cellStyle: { color: '#1BAAD5', 'margin-top': '7px', 'border': '0px' }
        }
    ];

    emptyFormatter(params) {

        if (params.value == null || params.value == "")
            return '-';
        else return params.value;
    }

    public rowData = [
        { make: 'Toyota', model: 'Celica', price: 35000 },
        { make: 'Ford', model: 'Mondeo', price: 32000 },
        { make: 'Porsche', model: 'Boxster', price: 72000 }
    ];

    // DefaultColDef sets props common to all Columns
    public defaultColDef: ColDef = {
        sortable: true,
        filter: true,
    };

    // Data that gets displayed in the grid
    //public rowData$!: Observable<any[]>;

    // For accessing the Grid's API
    @ViewChild(AgGridAngular) agGrid!: AgGridAngular;

    constructor(
        private modal: NgbModal,
        private http: HttpClient,
        private _delegateService: DelegateService,
        private _route: Router,
        private _commonService: CommonServiceService) {

        _commonService.setBackNavigation();

        this.gridOptions = <GridOptions>{
            context: {
                componentParent: this
            },
            rowClass: 'grid-row',
            suppressCellSelection: true
        };

        this.domLayout = "autoHeight";
    }

    setAutoHeight() {
        this.gridApi.setDomLayout("autoHeight");
        //document.querySelector("#dGrid").style.height = "";
        var elm = document.querySelector("#dGrid") as HTMLElement;


        var elm_outer = document.querySelector(".grid-wrapper") as HTMLElement;

        // elm_outer.offsetHeight 
        elm.style.height = screen.height - 300 + "px";
        //Array.from(document.getElementsByClassName('grid-wrapper') as HTMLCollectionOf<HTMLElement>)
    }

    setFixedHeight() {

        this.gridApi.setDomLayout("normal");
        //document.querySelector("#dGrid").style.height = "400px";
        var elm = document.querySelector("#dGrid") as HTMLElement;
        elm.style.height = '535px';
    }

    // Example load data from sever
    onGridReady(params: GridReadyEvent) {
        //    //this.rowData$ = this.http
        //    //    .get<any[]>('http://www.ag-grid.com/example-assets/row-data.json');

        this.gridApi = params.api;

        this.rowData = [
            { make: 'Toyota', model: 'Celica', price: 35000 },
            { make: 'Ford', model: 'Mondeo', price: 32000 },
            { make: 'Porsche', model: 'Boxster', price: 72000 }
        ];

        this.setFixedHeight();
        this.gridOptions.api!.sizeColumnsToFit();
    }

    // Example of consuming Grid Event
    onCellClicked(e: CellClickedEvent): void {
        //console.log('cellClicked', e);
        if (e.colDef.field != "Action")
            this.gotoMainMenu.emit(e.data);
    }

    onRowClicked() { }

    // Example using Grid's API
    clearSelection(): void {
        this.agGrid.api.deselectAll();
    }

    
    openModal(param) {
        const modalRef = this.modal.open(AddEditDelegateComponent, {
            size: 'lg'
        });
        modalRef.componentInstance.data = param;

        if (this._methodToload == this.methodName.archive)
            modalRef.componentInstance.refreshGrid.subscribe(event => this.getDelegatesList(true));
        else if (this._methodToload == this.methodName.nonArchive)
            modalRef.componentInstance.refreshGrid.subscribe(event => this.getDelegatesList(false));
    }

    

    ngOnInit(): void {
        //
        localStorage.setItem('projectPageSize', '50');
        this.getDelegatesList(false);
    }

    onPageSizeChanged() {
        var elm = document.getElementById('page-size') as HTMLInputElement;
        localStorage.setItem('projectPageSize', elm.value);
        this.gridApi.paginationSetPageSize(Number(elm.value));
    }


    methodName = {
        
        nonArchive: "nonArchive",
        archive: "archive"
    }

    getDelegatesList(isArch) {

        if (isArch == true) {
            this.listTitle = "Archived Delegates";
            this._methodToload = this.methodName.archive;
        } else {
            this.listTitle = "Delegates";
            this._methodToload = this.methodName.nonArchive;
        }

        var req = {            
            isArchived: isArch
        };
        this._delegateService.getDelegatesList(req).subscribe((result: any) => {

            if (result.success == true) {
                
                this.delegatesList = result.data;

                if (localStorage.getItem('projectPageSize') != null)
                    this.paginationPageSize = Number(localStorage.getItem('projectPageSize'))

                $("#page-size").val(this.paginationPageSize);

            } else {

                //this._commonService.error(result.data.desc);
            }

        }, (_error: any) => {
            //this.loading = false;
        })

    }

    addNew() {
        const modalRef = this.modal.open(AddEditDelegateComponent, {
            size: 'lg'
        });

        modalRef.result.then((result) => {
            this.getDelegatesList(false);
        }, (reason) => {
            
            //console.log('Dismissed action: ' + reason);
        });
    }

    //gotoMainMenu(tab) {
    //    if (tab.index == 0) {
    //        this._route.navigateByUrl("/courses/all-courses");
    //    }
    //    else if (tab.index == 2) {
    //        this._route.navigateByUrl("/schedule");
    //    }
    //}

}
