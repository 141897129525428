import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AgGridAngular } from 'ag-grid-angular';
import { CellClickedEvent, ColDef, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { CoursesService } from '../../../common/_services/courses-services';
import { AddEditSpeakersComponent } from '../add-edit-speakers/add-edit-speakers.component';
import { SpeakersClickableParentComponent } from '../speakers-clickable-parent/speakers-clickable-parent.component';

@Component({
    selector: 'app-speakers-list',
    templateUrl: './speakers-list.component.html',
    styleUrls: ['./speakers-list.component.css']
})
export class SpeakersListComponent implements OnInit {

    //@Output() gotoMainMenu = new EventEmitter<void>();
    //@Output() gotoAllCourses = new EventEmitter<void>();

    _paramOut = {
        gotoAdmin: ""
    }

    @Output() gotoAdminTab = new EventEmitter<object>();
    @Output() gotoSpeakerDetails = new EventEmitter<object>();



    speakersList: any;
    domLayout;
    private gridApi;
    gridOptions: GridOptions;

    _methodToload: any = "";
    listTitle: any = "";
    paginationPageSize: any = 50;


    constructor(
        private modal: NgbModal,
        //private http: HttpClient,
        private _courseService: CoursesService,
        //private _route: Router
    ) {

        this.gridOptions = {
            suppressCellSelection: true,
            context: {
                componentParent: this
            }
        };

        //this.gridOptions = <GridOptions>{
        //    context: {
        //        componentParent: this
        //    },
        //    rowClass: 'grid-row',
        //    suppressCellSelection: true
        //};

        this.domLayout = "autoHeight";
    }



    gotoAdmin(param) {
        this._paramOut.gotoAdmin = param;
        this.gotoAdminTab.emit(this._paramOut);
    }

    // Each Column Definition results in one Column.
    public columnDefs: ColDef[] = [
        { field: 'speakerName', headerName: 'Title' },
        { field: 'initials', headerName: 'Initials' },
        { field: 'email1', headerName: 'Email' },
        { field: 'mobileNumber', headerName: 'Mobile Number' },
        { field: 'statusId', headerName: 'Status', valueFormatter: this.getStatus },
        {
            field: 'Action', cellRendererFramework: SpeakersClickableParentComponent,
            sortable: false, filter: false, width: 85, cellStyle: { color: '#1BAAD5', 'margin-top': '7px', 'border': '0px' }
        }
    ];

    getStatus(params) {
        if (params.value == 1)
            return "Active";
        else return "Inactive";
    }
    //convertStringToDate(params) {

    //    var strDate = params.value;
    //    if (strDate != null && strDate != "" && strDate != undefined) {
    //        var date = new Date(strDate);
    //        var dd = String(date.getDate()).padStart(2, '0');
    //        var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    //        var yyyy = date.getFullYear();

    //        var _date = dd + '/' + mm + '/' + yyyy;
    //        return _date;

    //        //return new Date(strDate).toString();
    //    }
    //    else return "";
    //    //else return new Date().toString();
    //}

    reconcileCellRendererFunc(params) {
        var strDate = params.value;
        if (params.value == true)
            return "<div class='paid status-circles mt-10px'></div>";
        else return "<div class='not-paid status-circles mt-10px'></div>";
    }

    public rowData = [
        { make: 'Toyota', model: 'Celica', price: 35000 },
        { make: 'Ford', model: 'Mondeo', price: 32000 },
        { make: 'Porsche', model: 'Boxster', price: 72000 }
    ];

    // DefaultColDef sets props common to all Columns
    public defaultColDef: ColDef = {
        sortable: true,
        filter: true,
    };

    // Data that gets displayed in the grid
    //public rowData$!: Observable<any[]>;

    // For accessing the Grid's API
    @ViewChild(AgGridAngular) agGrid!: AgGridAngular;



    setAutoHeight() {
        this.gridApi.setDomLayout("autoHeight");
        //document.querySelector("#dGrid").style.height = "";
        var elm = document.querySelector("#speakersGrid") as HTMLElement;


        var elm_outer = document.querySelector(".grid-wrapper") as HTMLElement;

        // elm_outer.offsetHeight 
        elm.style.height = screen.height - 300 + "px";
        //Array.from(document.getElementsByClassName('grid-wrapper') as HTMLCollectionOf<HTMLElement>)
    }

    setFixedHeight() {

        this.gridApi.setDomLayout("normal");
        //document.querySelector("#dGrid").style.height = "400px";
        var elm = document.querySelector("#speakersGrid") as HTMLElement;
        elm.style.height = '535px';
    }

    // Example load data from sever
    onGridReady(params: GridReadyEvent) {

        this.gridApi = params.api;

        this.rowData = [
            { make: 'Toyota', model: 'Celica', price: 35000 },
            { make: 'Ford', model: 'Mondeo', price: 32000 },
            { make: 'Porsche', model: 'Boxster', price: 72000 }
        ];

        this.setFixedHeight();
        this.gridOptions.api!.sizeColumnsToFit();
    }

    // Example of consuming Grid Event
    onCellClicked(e: CellClickedEvent): void {
        //console.log('cellClicked', e);
        //debugger;

        //if (e.colDef.field != "Action") {
        //    this._paramOut.gotoAdmin = "speakerDetails";
        //    this._paramOut.id = e.data.speakerId;
        //    this.gotoAdminTab.emit(this._paramOut);
        //}
        this.gotoSpeakerDetails.emit({ id: e.data.speakerId });

    }

    onRowClicked() { }

    // Example using Grid's API
    clearSelection(): void {
        this.agGrid.api.deselectAll();
    }




    ngOnInit(): void {

        localStorage.setItem('projectPageSize', '10');
        this.getSpeakersList();
    }

    onPageSizeChanged() {
        var elm = document.getElementById('page-size') as HTMLInputElement;
        localStorage.setItem('projectPageSize', elm.value);
        this.gridApi.paginationSetPageSize(Number(elm.value));
    }


    methodName = {

        nonArchive: "nonArchive",
        archive: "archive"
    }

    getSpeakersList() {


        this._courseService.getSpeakersList().subscribe((result: any) => {

            if (result.success == true) {

                this.speakersList = result.data;

                if (localStorage.getItem('projectPageSize') != null)
                    this.paginationPageSize = Number(localStorage.getItem('projectPageSize'))

                $("#page-size").val(this.paginationPageSize);

            } else {

                //this._commonService.error(result.data.desc);
            }

        }, (_error: any) => {
            //this.loading = false;
        })

    }

    addNew() {
        const modalRef = this.modal.open(AddEditSpeakersComponent, {
            size: 'lg'
        });

        modalRef.result.then((result) => {

            this.getSpeakersList();
        }, (reason) => {

            //console.log('Dismissed action: ' + reason);
        });
    }

    openModal(param) {

        const modalRef = this.modal.open(AddEditSpeakersComponent, {
            size: 'lg'
        });
        modalRef.componentInstance.data = param;

        if (localStorage.getItem('projectPageSize') != null)
            this.paginationPageSize = Number(localStorage.getItem('projectPageSize'))

        modalRef.componentInstance.refreshGrid.subscribe(event => this.getSpeakersList());
    }



}
