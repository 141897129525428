import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
//import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AgGridAngular } from 'ag-grid-angular';
//import { ColDef, GridReadyEvent, GridOptions } from 'ag-grid-community';
import { CellClickedEvent, ColDef, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { CoursesService } from '../../../common/_services/courses-services';
import * as $ from "jquery";
import { Location } from '@angular/common';
import { DeleteComponent } from '../../../shared/_components/delete/delete.component';
import { MatDialog } from '@angular/material/dialog';
import { CheckboxRendererComponent } from '../course-actions/checkbox-renderer/checkbox-renderer.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { AddCourseComponent } from '../add-course/add-course.component';
import { regExpressions } from '../../../common/validation/messages';
import { DelegateService } from '../../../common/_services/delegate-services';
import { CommonServiceService } from '../../../common/_services/common-service.service';
import { ButtonRendererComponent } from '../course-actions/button-renderer/button-renderer.component';
import jsPDF from 'jspdf';
import { parse } from 'date-fns';

@Component({
    selector: 'app-course-details',
    templateUrl: './course-details.component.html',
    styleUrls: ['./course-details.component.css']
})
export class CourseDetailsComponent implements OnInit {

    @ViewChild('modalPaymentDetails', { static: true }) modalPaymentDetails: TemplateRef<any>;
    @ViewChild('modalAddDelegateToCourse', { static: true }) modalAddDelegateToCourse: TemplateRef<any>;
    @ViewChild('modalDelegateEmails', { static: true }) modalDelegateEmails: TemplateRef<any>;

    loading = false;
    delegateEmails: any;
    paymentDate: any = new FormControl(new Date());
    deadline: Date | null = null;
    submitted = false;
    isOfficeUse: boolean = false;
    _courseToUpdate: any;
    public _refDialog: any;
    gridOptions: GridOptions;
    @Input() detailData: any;
    coursesList: any;
    courseTitle: any;
    currentCoursesList: any;
    speakersList: any;
    locationsList: any;
    delegatesList: any = [];
    hotelRoomsList: any;
    hotelsList: any;
    cost: any;
    courseIncome: any;
    start_date: any;
    _xero: any;

    duration: any;
    courseTypeId: any;
    registration: any;
    start_time: any;
    end_time: any;

    coursesForm: FormGroup;
    del_date_Form: FormGroup;
    paymentForm: FormGroup;
    addDelegateToCourseForm: FormGroup;

    showAddDelegateToCourseForm: any = true;

    day1: any;
    day2: any;
    day3: any;
    day4: any;
    day1_capacity: any;
    day2_capacity: any;
    day3_capacity: any;
    day4_capacity: any;
    d1_checked: any = true;
    d2_checked: any = true;
    d3_checked: any = true;
    d4_checked: any = true;
    d1_teams: any = true;
    d2_teams: any = true;
    d3_teams: any = true;
    d4_teams: any = true;
    d1_cancelled: any = false;
    d2_cancelled: any = false;
    d3_cancelled: any = false;
    d4_cancelled: any = false;

    courseDatesId: any = 0;
    courseId: any = 0;
    hotelId: any = 0;
    cityId: any = 0;
    _params: any;

    _currentHotelId: any = 0;
    currentCourseTitle: any;
    currentCourseId: any;
    delegateId: any = 0;
    currentDelegate: any;


    selectedPaymentMethod: any = "none";

    @Output() gotoAllCourses = new EventEmitter<void>();

    frameworkComponents: any;

    constructor(private _coursesService: CoursesService,
        private _delegateService: DelegateService,
        private _route: Router,
        private formBuilder: FormBuilder,
        private location: Location,
        private modal: NgbModal,
        private route: ActivatedRoute,
        private _dialog: MatDialog,
        private spinner: NgxSpinnerService,
        private _commonService: CommonServiceService,
        private changeDetector: ChangeDetectorRef) {

        _commonService.setBackNavigation();


        //debugger;
        this.gridOptions = {
            context: {
                componentParent: this
            },
            rowClass: 'grid-row',
            suppressCellSelection: true
        };


        //this.gridOptions = <GridOptions>{
        //    context: {
        //        componentParent: this
        //    },
        //    rowClass: 'grid-row',
        //    suppressCellSelection: true
        //};

        setTimeout(() => {
            $("#main_dd div:nth-child(1) div:first-child").css("color", "white");
            $("#main_dd div:nth-child(2) div:first-child").css("color", "white");
            $("#mat-select-value-1").css("color", "black");
        }, 200);

        this._route.routeReuseStrategy.shouldReuseRoute = () => false;

        this.frameworkComponents = {
            checkboxRenderer: CheckboxRendererComponent,
            buttonRenderer: ButtonRendererComponent,
        }
    }


    ngOnInit(): void {


        this.coursesForm = this.formBuilder.group({
            d_courses: ['', Validators.required],
            current_courses: ['', Validators.required],
            speaker: ['', Validators.required],
            location: ['', Validators.required],
            hotel: ['', Validators.required]
        });

        this.del_date_Form = this.formBuilder.group({
            speaker1: [''],
            speaker2: [''],
            speaker3: [''],
            speaker4: [''],
            room1: [''],
            room2: [''],
            room3: [''],
            room4: [''],
            box1: [''],
            box2: [''],
            box3: [''],
            box4: [''],
            teams1: [''],
            teams2: [''],
            teams3: [''],
            teams4: [''],
            cancel1: [''],
            cancel2: [''],
            cancel3: [''],
            cancel4: ['']
        });

        this.paymentForm = this.formBuilder.group({
            invoiceNumber: ['', Validators.required],
            p_paymentDate: [this.paymentDate.value, Validators.required],
            source: ['', Validators.required],
            subtotal: ['', Validators.required],
            totalTax: ['', Validators.required],
        });

        this.addDelegateToCourseForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.pattern(regExpressions.email)]]
        });


        if (this.detailData.id > 0) {
            //localStorage.setItem("currentCourseId", this.detailData.id);
            //this.getCourseTitleById(this.detailData.id)
        }

        this.refreshData();

        //this.createMeetingLink();



    }

    get i() { return this.paymentForm.controls; }
    get dc() { return this.addDelegateToCourseForm.controls; }

    refreshData() {
        //this.getCoursesList();
        this.getCurrentCourseViewList(this.detailData.title, false);
        this.getLocationsList();
        this.getSpeakersList();
        //this.getHotelsList();
    }

    searchAgain() {
        this.showAddDelegateToCourseForm = true;
    }

    emptyFormatter(param) {
        if (param == null || param == "")
            return '-';
        else return param;
    }

    addToCourse() {

        var message = "Do you want to add this delegate into the course?";

        this._refDialog = this._dialog.open(DeleteComponent, {
            autoFocus: false,
            disableClose: true,
            data: { message: message, title: "Add to course" }
        });

        this._refDialog.componentInstance.redirect.subscribe((data) => {

            var req = {
                delegateId: this.currentDelegate.id,
                courseId: this.courseId
            }

            this._coursesService.addDelegateToCourse(req).subscribe((result: any) => {


                if (result.success == true) {

                    this.paymentForm.reset();

                    this.paymentForm.controls.source.patchValue("none");
                    this.modal.dismissAll();
                    this.refreshData();

                    this._commonService.success(result.message);

                } else {
                    //show errror popup

                }

            }, (_error: any) => {
                //this.loading = false;
            })

        });

        this._refDialog.componentInstance.closePopup.subscribe((data) => {
            this._refDialog.close();
        });







    }

    searchDelegateByEmail() {

        var email = this.addDelegateToCourseForm.controls.email.value;

        this._delegateService.searchDelegateByEmail(email).subscribe((result: any) => {

            if (result.success == true) {

                if (result.data.length > 0) {
                    this.currentDelegate = result.data[0];
                }
                this.showAddDelegateToCourseForm = false;
                //this.modal.dismissAll();
            } else {
                this._commonService.error(result.message);
            }

        }, (_error: any) => {
            //this.loading = false;
        })

    }

    createMeetingLink() {
        var req = {
            courseDateId: this.courseDatesId

        };

        this._coursesService.createMeetingLink(req).subscribe((result: any) => {

            if (result.success == true) {

            }

        }, (_error: any) => {
            //this.loading = false;
        })
    }

    public columnDefs: ColDef[] = [

        { field: 'name', headerName: 'Name', width: 160 },
        { field: 'organization', headerName: 'Organisation', width: 160 },
        { field: 'telephone', headerName: 'Telephone', width: 125 },
        { field: 'email', headerName: 'Email', width: 175 },

        {
            field: 'isSent.materialSent',
            headerName: "Material",
            cellRenderer: 'checkboxRenderer',
            cellRendererParams: {
                onClick: this.updateDelegateMaterialSent.bind(this)
            },
            headerClass: "pull-center",
            width: 75

        },
        {
            field: 'isSent.teamsLink',
            headerName: "Teams",
            cellRenderer: 'checkboxRenderer',
            cellRendererParams: {
                onClick: this.updateDelegateMaterialSent.bind(this)
            },
            width: 75

        },
        {
            field: 'isSent.isInvoicePaid',
            headerName: "Paid",
            cellRenderer: 'checkboxRenderer',
            cellRendererParams: {
                onClick: this.updateInvoicePaid.bind(this)
            },
            width: 75

        },
        {
            headerName: 'Action',
            cellRenderer: 'buttonRenderer',
            cellRendererParams: {
                onClick: this.onDeleteButtonClick.bind(this),
                label: 'delete'
            }
        }

    ];

    onDeleteButtonClick(params) {

        this._refDialog = this._dialog.open(DeleteComponent, {
            autoFocus: false,
            disableClose: true,
            data: { message: "Are you sure to cancel this booking?", title: "Cancel Booking" }
        });

        var req = { id: params.data.isSent.bookingId }

        this._refDialog.componentInstance.redirect.subscribe((data) => {

            this._delegateService.rejectBooking(req)
                .subscribe((result: any) => {


                    if (result.success == true) {

                        this.refreshData();

                    } else {
                        //show errror popup

                    }

                }, (_error: any) => {
                    console.log(_error);
                    //this.loading = false;
                })
            //alert("delete");

        });

        this._refDialog.componentInstance.closePopup.subscribe((data) => {
            this._refDialog.close();
        });
    }

    addDelegateToCourse() {
        this.modal.open(this.modalAddDelegateToCourse, {
            size: 'lg',
            backdrop: 'static',
            keyboard: false
        });
    }

    updateInvoicePaid(d) {
        this._xero = d;

        if (this._xero.action == true)
            this.modal.open(this.modalPaymentDetails, { size: 'lg' });
        //else
        //this.onPaymentSubmit();

        setTimeout(() => {
            $("#paymentMethod").css("color", "black");
        }, 200);


    }

    onPaymentSubmit() {

        var req = {};
        if (this._xero.action == true)
            req = {
                courseId: this.courseId,
                delegateId: this.delegateId,
                InvoiceNumber: this.paymentForm.controls.invoiceNumber.value,
                InvoicePaidDate: this.paymentForm.controls.p_paymentDate.value,
                InvoicePaidBy: this.paymentForm.controls.source.value,
                SubTotal: this.paymentForm.controls.subtotal.value,
                TotalTax: this.paymentForm.controls.totalTax.value,
                IsInvoicePaid: this._xero.action,
                CurrencyCode: "GBP"

            };
        else
            req = {
                courseId: this.courseId,
                delegateId: this.delegateId,
                InvoiceNumber: "",
                InvoicePaidDate: new Date(), //'2000-01-01T00:00:00'
                InvoicePaidBy: "",
                SubTotal: "0",
                TotalTax: "0",
                IsInvoicePaid: this._xero.action,
                CurrencyCode: ""

            };
        this._coursesService.updateDelegatePayment(req).subscribe((result: any) => {


            if (result.success == true) {

                this.paymentForm.reset();

                this.paymentForm.controls.source.patchValue("none");
                this.modal.dismissAll();

            } else {
                //show errror popup

            }

        }, (_error: any) => {
            //this.loading = false;
        })
    }

    close() {

        this.modal.dismissAll();
    }

    updateDelegateMaterialSent(p) {


        var req = {
            courseId: this.courseId,
            delegateId: p.delegateId,
            action: p.action,
            toBeUpdate: p.toBeUpdated
        };

        this._coursesService.updateDelegateMaterialSent(req).subscribe((result: any) => {

            if (result.success == true) {
                this.getCurrentCourseViewList(this.detailData.title, false); // refresh
            }

        }, (_error: any) => {
            //this.loading = false;
        })
    }



    // For accessing the Grid's API
    @ViewChild(AgGridAngular) agGrid!: AgGridAngular;
    private gridApi;

    setFixedHeight() {
        this.gridApi.setDomLayout("normal");
        var elm = document.querySelector("#myGrid") as HTMLElement;
        elm.style.width = '100%';
        //elm.style.height = '100%';
    }


    // Example load data from sever
    onGridReady(params: GridReadyEvent) {
        this.gridApi = params.api;

        this.setFixedHeight();
        //        this.gridApi!.sizeColumnsToFit();
        this.gridOptions.api!.sizeColumnsToFit();
    }

    // DefaultColDef sets props common to all Columns
    public defaultColDef: ColDef = {
        sortable: true,
        filter: true,
    };


    onCellClicked(e: CellClickedEvent): void {
        //console.log('cellClicked', e);
        // using random value to update url
        this.delegateId = e.data.id;

        if (e.colDef.headerName != "Material" &&
            e.colDef.headerName != "Teams" &&
            e.colDef.headerName != "Paid" &&
            e.colDef.headerName != "Action")
            this._route.navigateByUrl('/delegates/delegate-details?delegateId=' + e.data.id + '&rndm=' + Math.floor(Math.random() * (100 - 0 + 1)) + 0);


        if (e.colDef.headerName == "Paid") {
            if (this._xero.action == false)
                this.onPaymentSubmit();
        }
    }

    getCourseTitleById(id) {

        this._coursesService.getCourseTitleById(id).subscribe((result: any) => {


            if (result.success == true) {
                debugger;
                this.courseTitle = result.data

                //localStorage.setItem("currentCourseTitle", this.courseTitle);
                //this.currentCourseTitle = this.courseTitle.title;

                if (this.detailData.title == undefined ||
                    this.detailData.title == "") {
                    if (this.courseTitle.title != null) {
                        this.detailData.title = this.courseTitle.title;
                        //this.currentCourseTitle = this.courseTitle.title;
                        //localStorage.setItem("currentCourseTitle", this.detailData.title);
                    }
                }
            } else {

                //this._commonService.error(result.data.desc);
            }

        }, (_error: any) => {
            //this.loading = false;
        })

    }

    getCoursesList() {

        //this._coursesService.getCoursesList().subscribe((result: any) => {
        this._coursesService.getCourseTitlesList().subscribe((result: any) => {

            if (result.success == true) {

                this.coursesList = result.data;


                this.coursesForm.controls.d_courses.patchValue(this.detailData.title);
                //this.coursesForm.controls.d_courses.patchValue(this.coursesList[0].title);
                //this.currentCourseTitle = this.detailData.title;
                this.getCurrentCourseViewList(this.detailData.title, false);

            } else {

                //this._commonService.error(result.data.desc);
            }

        }, (_error: any) => {
            //this.loading = false;
        })

    }


    async getCurrentCourseViewList(title: any, isIdChanged) {
        this.spinner.show();

        if (title.value == null ||
            title.value == undefined)
            title = { value: title }

        // store value for refresh the page
        //this.currentCourseTitle = title.value;
        this.detailData.title = title.value;
        var _currCrsId = this.detailData.id;
        //
        await this._coursesService.getCurrentCourseViewList(title, _currCrsId).subscribe((result: any) => {


            if (result.success == true) {


                this.spinner.show();

                this.currentCoursesList = result.data

                if (isIdChanged == true) {
                    //
                    _currCrsId = this.currentCoursesList[0].courseId;

                }

                // store value for refresh the page
                //this.currentCourseId = _currCrsId;

                this.detailData.id = _currCrsId;
                this.location.go('/courses/course-detail?cid=' + this.detailData.id);

                //console.log("id: " + this.currentCourseId);

                this.coursesForm.controls.current_courses.patchValue(_currCrsId);


                this.populateData(_currCrsId);
                //this.populateData(this.currentCoursesList[0].courseId)

                this.spinner.hide();

            } else {

                //this._commonService.error(result.data.desc);
            }

        }, (_error: any) => {
            //this.loading = false;
        })

    }




    printNo(num) {
        return Number(num).toLocaleString('en-GB');
    }

    populateData(courseId) {
        debugger;
        this.spinner.show();

        this.courseId = courseId;

        let obj = this.currentCoursesList.find(o => o.courseId === courseId);

        this._courseToUpdate = obj;

        this.courseTitle = obj.courseTitle;
        //this._currentHotelId = obj.hotelId
        this.getHotelsListByLocation({ value: obj.cityId }, false);

        this.coursesForm.controls.location.patchValue(obj.cityId);
        this.coursesForm.controls.hotel.patchValue(obj.hotelId);
        this.coursesForm.controls.speaker.patchValue(obj.speakerId);


        this.courseTypeId = obj.courseTypeId;
        this.courseIncome = obj.courseIncome;
        this.cost = obj.courseCost;
        this.start_date = obj.dates.courseStartDate;
        this.duration = obj.courseDuration;

        // registration time is hardcoded
        this.registration = '2023-03-13T09:45:00';

        this.start_time = obj.dates.startTime;
        this.end_time = obj.dates.finishTime;

        this.delegatesList = obj.bookedDelegates;
        //debugger;
        this.del_date_Form.controls.speaker1.patchValue(obj.speakerId);

        if (this.duration > 1) {

            this.del_date_Form.controls.speaker1.patchValue(obj.dates.d1SpeakerId);
            this.del_date_Form.controls.speaker2.patchValue(obj.dates.d2SpeakerId);
            this.del_date_Form.controls.speaker3.patchValue(obj.dates.d3SpeakerId);
            this.del_date_Form.controls.speaker4.patchValue(obj.dates.d4SpeakerId);
        }
        //this.del_date_Form.controls.location1.patchValue(obj.cityId);


        // will be use to update rooms id
        this.cityId = obj.cityId;
        this.courseDatesId = obj.dates.courseDateId;

        // day1 data
        this.day1 = obj.dates.day1Date;
        this.getHotelRoomsList(obj.hotelId, false);

        this.d1_teams = obj.dates.d1Teams;
        this.d1_checked = obj.dates.d1Box;
        this.d1_cancelled = obj.dates.d1Cancelled;

        // day2 data
        this.day2 = obj.dates.day2Date;
        //this.getHotelRoomsList(obj.hotelId, 2);
        //this.day2_capacity = 10;
        this.d2_teams = obj.dates.d2Teams;
        this.d2_checked = obj.dates.d2Box;
        this.d2_cancelled = obj.dates.d2Cancelled;

        // day3 data
        this.day3 = obj.dates.day3Date;
        //this.getHotelRoomsList(obj.hotelId, 3);
        //this.day3_capacity = 10;
        this.d3_teams = obj.dates.d3Teams;
        this.d3_checked = obj.dates.d3Box;
        this.d3_cancelled = obj.dates.d3Cancelled;

        // day4 data
        this.day4 = obj.dates.day4Date;
        //this.getHotelRoomsList(obj.hotelId, 4);
        //this.day4_capacity = 10;
        this.d4_teams = obj.dates.d4Teams;
        this.d4_checked = obj.dates.d4Box;
        this.d4_cancelled = obj.dates.d4Cancelled;


        setTimeout(() => {

            this.del_date_Form.controls.room1.patchValue(obj.dates.d1RoomId);
            this.del_date_Form.controls.room2.patchValue(obj.dates.d2RoomId);
            this.del_date_Form.controls.room3.patchValue(obj.dates.d3RoomId);
            this.del_date_Form.controls.room4.patchValue(obj.dates.d4RoomId);
        }, 200);

    }

    getHotelsListByLocation(loc, isUpdate) {

        if (loc.value != null) {

            this.cityId = loc.value;

            var req = {
                cityId: loc.value,
                courseId: this.courseId,
                isUpdate: isUpdate
            };
            //
            this._coursesService.getHotelsListByLocation(req).subscribe((_result: any) => {

                if (_result.success == true) {
                    //
                    this.hotelsList = _result.data;




                    if (isUpdate == true) {
                        this.coursesForm.controls.hotel.patchValue(this.hotelsList[0].id);

                        this.getHotelRoomsList(this.hotelsList[0].id, true);    // update hotel rooms

                    }
                    else {

                        //this.getHotelRoomsList(this.hotelsList[0].id, false);   // not updating rooms, only set the value
                    }

                } else {

                    //this._commonService.error(result.data.desc);
                }

            }, (_error: any) => {
                //this.loading = false;
            })
        }
    }

    onAddDelegateToCourseFormSubmit() {

        this.searchDelegateByEmail();
    }

    onSubmit(f) {

    }

    changes(t) {

    }

    updateSpeaker(_speakerId, _dayToUpdate, isUpdate, isSpecific) {

        if (_speakerId != null) {

            var req = {
                courseDateId: this.courseDatesId,
                speakerId: _speakerId,
                dayToUpdate: _dayToUpdate,
                courseId: this.courseId
            };

            this._coursesService.updateSpeaker(req).subscribe((result: any) => {

                if (result.success == true) {
                    this.getCurrentCourseViewList(this.detailData.title, false); // refresh
                }

            }, (_error: any) => {
                //this.loading = false;
            })
        }
    }

    updateMaterialSent(isSent, _dayToUpdate, _toBeUpdate) {

        var req = {
            courseDateId: this.courseDatesId,
            isSent: isSent,
            dayToUpdate: _dayToUpdate,
            courseDuration: this.duration,
            courseId: this.courseId,
            toBeUpdate: _toBeUpdate
        };

        this._coursesService.updateMaterialSent(req).subscribe((result: any) => {

            if (result.success == true) {
                this.getCurrentCourseViewList(this.detailData.title, false); // refresh
            }

        }, (_error: any) => {
            //this.loading = false;
        })

    }



    updateCancellation(isCancelled, _dayToUpdate) {
        var message = "";

        if (isCancelled == true)
            message = "Do you want to cancel this course?";
        else if (isCancelled == false)
            message = "Do you want to arrange this course?";

        this._refDialog = this._dialog.open(DeleteComponent, {
            autoFocus: false,
            disableClose: true,
            data: { message: message, title: "Cancel" }
        });

        this._refDialog.componentInstance.redirect.subscribe((data) => {

            var req = {
                courseId: this.courseId,
                isCancelled: isCancelled,
                dayToUpdate: _dayToUpdate
            };

            this._coursesService.cancelCourse(req).subscribe((result: any) => {

                if (result.success == true) {
                    this.getCurrentCourseViewList(this.detailData.title, false); // refresh
                }

            }, (_error: any) => {
                //this.loading = false;
            })

        });

        this._refDialog.componentInstance.closePopup.subscribe((data) => {

            if (_dayToUpdate == 1)
                this.d1_cancelled = !isCancelled;

            this._refDialog.close();
        });



    }

    getCourseData(data) {

        //this.currentCourseId = data.value;
        this.detailData.id = data.value;

        this.location.go('/courses/course-detail?cid=' + this.detailData.id);

        //console.log("change course id: " + this.currentCourseId);
        //localStorage.setItem("currentCourseTitle", obj.courseTitle);
        //localStorage.setItem("currentCourseId", courseId);
        this.populateData(data.value);
    }

    getSpeakersList() {

        this._coursesService.getSpeakersList().subscribe((result: any) => {

            if (result.success == true) {

                this.speakersList = result.data

                //

            } else {

                //this._commonService.error(result.data.desc);
            }

        }, (_error: any) => {
            //this.loading = false;
        })

    }

    getHotelsList() {

        this._coursesService.getHotelsList().subscribe((result: any) => {

            if (result.success == true) {

                this.hotelsList = result.data

                //

            } else {

                //this._commonService.error(result.data.desc);
            }

        }, (_error: any) => {
            //this.loading = false;
        })

    }

    getHotelRoomsList(hotelId, isUpdate) {


        if (hotelId != null) {
            this._coursesService.getHotelRoomsList(hotelId).subscribe((result: any) => {

                if (result.success == true) {

                    this.hotelRoomsList = result.data


                    this.day1_capacity = this.hotelRoomsList[0].capacity;
                    this.day2_capacity = this.hotelRoomsList[0].capacity;
                    this.day3_capacity = this.hotelRoomsList[0].capacity;
                    this.day4_capacity = this.hotelRoomsList[0].capacity;

                    if (isUpdate == true) {
                        this.hotelId = hotelId;
                        this.updateRooms(this.hotelRoomsList[0].id, this.courseDatesId, 0);

                        this.del_date_Form.controls.room1.patchValue(this.hotelRoomsList[0].id);
                        this.del_date_Form.controls.room2.patchValue(this.hotelRoomsList[0].id);
                        this.del_date_Form.controls.room3.patchValue(this.hotelRoomsList[0].id);
                        this.del_date_Form.controls.room4.patchValue(this.hotelRoomsList[0].id);
                    }
                    else {

                    }
                }

            }, (_error: any) => {
                //this.loading = false;
            })
        }
    }

    updateSpecificRoom(_roomId, _dayToUpdate) {

        this.updateRooms(_roomId, this.courseDatesId, _dayToUpdate);
    }


    updateRooms(_roomId, _courseDateId, _dayToUpdate) {
        var req = {
            courseDateId: _courseDateId,
            roomId: _roomId,
            dayToUpdate: _dayToUpdate,
            hotelId: this.hotelId,
            courseId: this.courseId,
            cityId: this.cityId
        };


        this._coursesService.updateRooms(req).subscribe((result: any) => {


            if (result.success == true) {

                //console.log("var: " + this.currentCourseTitle);
                //console.log("out: " + this.detailData.title);

                this.getCurrentCourseViewList(this.detailData.title, false);    // refresh the data


            } else {
                //show errror popup

            }

        }, (_error: any) => {
            //this.loading = false;
        })
    }

    //gotoMainMenu(tab) {

    //    if (tab.index == 0) {
    //        this._route.navigateByUrl("/courses");
    //    }
    //    else if (tab.index == 1) {
    //        this._route.navigateByUrl("/delegates");
    //    }
    //    else if (tab.index == 2) {
    //        this._route.navigateByUrl("/schedule");
    //    }
    //}

    getLocationsList() {

        this._coursesService.getLocationsList().subscribe((result: any) => {

            if (result.success == true) {

                this.locationsList = result.data

                //this.coursesForm.controls.location.patchValue(this.locationsList[0].cityName);

            } else {

                //this._commonService.error(result.data.desc);
            }

        }, (_error: any) => {
            //this.loading = false;
        })

    }

    updateCourse() {
        const modalRef = this.modal.open(AddCourseComponent, {
            size: 'lg'
        });
        modalRef.componentInstance.data = this._courseToUpdate;

        modalRef.componentInstance.refreshGrid.subscribe(event => this.refreshData());
    }

    public testFunction() {

    }

    allCourses() {
        this.gotoAllCourses.emit();

    }

    //printPDF() {
    //    $(".hide-for-print").css("content-visibility", "hidden");
    //    $("#section-to-print").css("display", "block");
    //    window.print();
    //    $(".hide-for-print").css("content-visibility", "visible");
    //    $("#section-to-print").css("display", "none");
    //}

    @ViewChild('printSection', { static: false }) el!: ElementRef

    printPDF(_isOfficeUse: boolean) {
        this.isOfficeUse = _isOfficeUse;

        setTimeout(() => {


            $("#section-to-print").css("display", "block");

            let pdf = new jsPDF('p', 'px', 'a4', true);


            pdf.setFontSize(8);


            pdf.html(this.el.nativeElement, {

                callback: (pdf) => {
                    pdf.save('DelegatesList_' + this.courseTitle + '.pdf');
                },
                margin: [15, 15, 15, 15],
                autoPaging: 'text',
                x: 25,
                y: 25,
                width: 350, //target width in the PDF document
                windowWidth: 675 //window width in CSS pixels
            })

            setTimeout(() => {
                $("#section-to-print").css("display", "none");
            }, 4000);
        }, 1000);
    }

    printPDF_(_isOfficeUse: boolean) {

        this.isOfficeUse = _isOfficeUse;
        this.changeDetector.detectChanges();
        $(".hide-for-print").css("content-visibility", "hidden");
        $("#section-to-print").css("display", "block");
        window.print();
        $(".hide-for-print").css("content-visibility", "visible");
        $("#section-to-print").css("display", "none");



        //$("#section-to-print").html(htmlText).promise().done(function () {
        //    window.print();
        //});
    }

    getDelegateEmails() {

        this.delegateEmails = [];
        this.delegatesList.forEach((item, index) => {
            this.delegateEmails = item.email + ';' + this.delegateEmails;
        });

        //const email = 'shireen.ijaaz@veritau.co.uk;samantha.dawson2@malvernhills.gov.uk;lucie.jones@ipo.gov.uk;isla.kaye@snee.nhs.uk;Bianca.Bedin@veritau.co.uk;arthur.scott@greatermanchester-ca.gov.uk;'; // Replace with the recipient's email address
        const subject = this.courseTitle;// 'Your Subject Here';
        const body = 'Act Now Team';

        const mailtoLink = `mailto:${this.delegateEmails}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

        window.location.href = mailtoLink;

        //this.modal.open(this.modalDelegateEmails, {
        //    size: 'lg',
        //    backdrop: 'static',
        //    keyboard: false
        //});
    }

    async sendReminderEmailDelegates() {
        this.delegateEmails = [];
        //this.delegatesList.forEach(async (item, index) => {
        for (let i = 0; i < this.delegatesList.length; i++) {
            //this.delegateEmails = this.delegatesList[i].email + ';' + this.delegateEmails;
            
            // Convert to Date object

            var date = new Date(this._courseToUpdate.dates.courseEndDate);
            if (this.deadline) {
                date = new Date(this.deadline);
            } 

            const subject = "Assessment deadline: " + this.courseTitle;
            const body = `Dear ` + this.delegatesList[i].name + `,` +
                `
                Your candidate number for your written assessment as part of the "` + this.courseTitle + `" is "` + this.delegatesList[i].isSent.invoiceNumber + `". The deadline for your assessment is ` + new Intl.DateTimeFormat('en-GB').format(date).replace(/\//g, '-') + `.
                Please save your assessment document as your candidate number when submitting and email the document to project @actnow.org.uk.
                If you have any questions, please do not hesitate to contact us.

                Kind Regards,
                Act Now Training
                01924 470252`;




            //this.delegateEmails = item.email + ';' + this.delegateEmails;
            //debugger;
            //// Convert to Date object
            //const date = new Date(this._courseToUpdate.dates.courseEndDate);

            //const subject = "Assessment submission reminder: " + this.courseTitle;
            //const body = `Dear ` + item.name + `,` +
            //    `
            //    Your candidate number for your written assessment as part of the "` + this.courseTitle + `" is "` + item.isSent.invoiceNumber + `". The deadline for your assessment is ` + new Intl.DateTimeFormat('en-GB').format(date).replace(/\//g, '-') + `.
            //    Please save your assessment document as your candidate number when submitting and email the document to project @actnow.org.uk.
            //    If you have any questions, please do not hesitate to contact us.

            //    Kind Regards,
            //    Act Now Training
            //    01924 470252`;

            const mailtoLink = `mailto:${this.delegatesList[i].email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
            window.open(mailtoLink, '_blank');

            await this.sleep(1000);
            //});
        }
    }

    sleep(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
}
