import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { regExpressions } from '../../../common/validation/messages';
import { CommonServiceService } from '../../../common/_services/common-service.service';
import { CoursesService } from '../../../common/_services/courses-services';
import { DelegateService } from '../../../common/_services/delegate-services';
import { AddEditDelegateComponent } from '../add-edit-delegate/add-edit-delegate.component';

@Component({
    selector: 'app-delegate-details',
    templateUrl: './delegate-details.component.html',
    styleUrls: ['./delegate-details.component.css']
})
export class DelegateDetailsComponent implements OnInit {

    selectedPaymentMethod: any = "none";
    paymentDate: any = new FormControl(new Date());
    prodPaymentDate: any = null;// = new FormControl(new Date());
    _reconcile: boolean = false;
    _isPaired: boolean = false;
    _isFoundationCert: boolean = false;
    _xero: any;
    _courseId: any = 0;
    _bookingId: any = 0;
    _FC_bookingId: any = 0;
    coursesList: any = [];
    loading = false;
    @Input() delegateDetail: any;
    invoiceEditForm: FormGroup;
    invoicePostalForm: FormGroup;
    paymentForm: FormGroup;
    editFoundationCourseForm: FormGroup;
    delegateProductForm: FormGroup;
    delegateCourseChangeForm: FormGroup;
    locationsList: any;
    _params: any;
    _delegateId: any = 0;
    currentDelegate: any = {
        name: "",
        bookedCourses: [],
        myProducts: []
    };
    _purchaseId: any = 0;
    _invoiceId: any = 0;

    selectedGDPR: any;
    gdprId: any;
    gdprDate: any;

    selectedFOI: any;
    foiId: any;
    foiDate: any;

    selectedRM: any;
    rmId: any;
    rmDate: any;

    fc_currentCourseId: any = 0;
    fc_bookingIdToUpdate: any = 0;
    fc_courseIdToUpdate: any = 0;

    gdprList: any = [];
    foiList: any = [];
    rmList: any = [];

    showDD: any = "";
    isCreditNote: boolean = false;
    productList: any;

    @Output() backNavigation = new EventEmitter<void>();
    @Output() gotoAllDelegates = new EventEmitter<void>();
    @ViewChild('modalEditInvoice', { static: true }) modalContentEdit: TemplateRef<any>;
    @ViewChild('modalEditPostal', { static: true }) modalContentPostal: TemplateRef<any>;
    @ViewChild('modalPaymentDetails', { static: true }) modalPaymentDetails: TemplateRef<any>;
    @ViewChild('modalAddDelegateProduct', { static: true }) modalAddDelegateProduct: TemplateRef<any>;
    @ViewChild('modalChangeDelegateCourse', { static: true }) modalChangeDelegateCourse: TemplateRef<any>;
    @ViewChild('modalEditFoundationCourse', { static: true }) modalEditFoundationCourse: TemplateRef<any>;

    constructor(private _coursesService: CoursesService,
        private _delegateService: DelegateService,
        private route: ActivatedRoute,
        private modal: NgbModal,
        private _commonService: CommonServiceService,
        private formBuilder: FormBuilder,
        private _route: Router) {

        _commonService.setBackNavigation();

        //this.route.queryParamMap
        //    .subscribe((params) => {
        //        this._params = { ...params.keys, ...params };
        //        this._delegateId = this._params.params.delegateId
        //    });


        //if (this._delegateId == null || this._delegateId == undefined
        //    || this._delegateId == "null" || Number.isNaN(this._delegateId)) {
        //    this._delegateId = 0; // user will add new delegate
        //}
    }

    ngOnInit(): void {

        this.getLocationsList();
        this.showCourses();
        this.getProductTitle();
        this.getCoursesList();
        this.getFoundationCertDDLists();


        if (this.delegateDetail) {
            this._delegateId = this.delegateDetail;

            //this._delegateId = localStorage.getItem('delegateId');
            if (Number(this._delegateId) > 0) {
                this.getDelegateData();
            }
        }

        this.invoiceEditForm = this.formBuilder.group({
            invoiceName: ['', Validators.required],
            invoiceAddress: ['', Validators.required],
            invoiceEmail: ['', [Validators.required, Validators.pattern(regExpressions.email)]],
            invoiceDepartment: [''],
            invoiceOrganization: [''],
            invoiceCity: [''],
            invoiceTelephone: [''],
            invoicePostcode: ['', Validators.required]

        });

        this.invoicePostalForm = this.formBuilder.group({
            postalAddress: ['', Validators.required],
            postalCity: [''],
            postalName: ['', Validators.required],
            postalPostcode: ['', Validators.required],
            postalTelephone: ['']

        });

        this.paymentForm = this.formBuilder.group({
            invoiceNumber: ['', Validators.required],
            p_paymentDate: [this.paymentDate.value],
            source: [''],
            subtotal: ['', Validators.required],
            totalTax: ['', Validators.required],
            total: ['', Validators.required],
            reconcile: [false],
            isPaired: [false],
            ponumber: [''],
            cnNumber: ['']
        });

        this.delegateProductForm = this.formBuilder.group({
            invoiceNumber: ['', Validators.required],
            prod_paymentDate: [''],// [this.prodPaymentDate.value],
            source: [''],
            subtotal: ['', Validators.required],
            totalTax: [''],
            total: ['', Validators.required],
            reconcile: [false],
            isPaired: [false],
            productName: ['none'],
            ponumber: [''],
            shippingCost: [''],
            quantity: [''],
            cnNumber: ['']
        });

        this.editFoundationCourseForm = this.formBuilder.group({
            dd_GDPR: [''],
            dd_FOI: [''],
            dd_RM: [''],
        });

        this.delegateCourseChangeForm = this.formBuilder.group({
            courseTitle: ['', Validators.required]
        });


    }

    getLocationsList() {
        this.loading = true;
        this._coursesService.getLocationsList().subscribe((result: any) => {

            if (result.success == true) {

                this.locationsList = result.data;
                this.loading = false;


            } else {

                //this._commonService.error(result.data.desc);
            }

        }, (_error: any) => {
            //this.loading = false;
        })

    }

    getFoundationCertDDLists() {


        this._delegateService.getFoundationCertDDLists().subscribe((result: any) => {

            if (result.success == true) {

                this.gdprList = result.data[0];
                this.foiList = result.data[1];
                this.rmList = result.data[2];

            } else {

                //this._commonService.error(result.data.desc);
            }

        }, (_error: any) => {
            //this.loading = false;
        })

    }


    populateInvoiceAddressForm() {
        if (this.currentDelegate != null) {
            //debugger;
            this.invoiceEditForm.controls.invoiceName.setValue(this.currentDelegate.invoiceName);
            this.invoiceEditForm.controls.invoiceAddress.setValue(this.currentDelegate.invoiceAddress);
            this.invoiceEditForm.controls.invoiceEmail.setValue(this.currentDelegate.invoiceEmail);
            this.invoiceEditForm.controls.invoiceDepartment.setValue(this.currentDelegate.invoiceDepartment);
            this.invoiceEditForm.controls.invoiceOrganization.setValue(this.currentDelegate.invoiceOrganization);
            this.invoiceEditForm.controls.invoiceTelephone.setValue(this.currentDelegate.invoiceTelephone);
            this.invoiceEditForm.controls.invoicePostcode.setValue(this.currentDelegate.invoicePostcode);
            this.invoiceEditForm.controls.invoiceCity.setValue(this.currentDelegate.invoiceCity);

        }
    }


    populatePostalAddressForm() {
        if (this.currentDelegate != null) {

            this.invoicePostalForm.controls.postalAddress.setValue(this.currentDelegate.postalAddress);
            this.invoicePostalForm.controls.postalCity.setValue(this.currentDelegate.postalCity);
            this.invoicePostalForm.controls.postalName.setValue(this.currentDelegate.postalName);
            this.invoicePostalForm.controls.postalPostcode.setValue(this.currentDelegate.postalPostcode);
            this.invoicePostalForm.controls.postalTelephone.setValue(this.currentDelegate.postalTelephone);

        }
    }


    emptyFormatter(param) {

        if (param == null || param == "")
            return '-';
        else return param;
    }



    getProductTitle() {


        this._delegateService.getProductTitle().subscribe((result: any) => {

            if (result.success == true) {

                this.productList = result.data;

            } else {

                //this._commonService.error(result.data.desc);
            }

        }, (_error: any) => {
            //this.loading = false;
        })

    }

    populateTitleData(data: any) {

        let obj = this.productList.find(o => o.id === data.value);

        if (obj.cost != null && obj.cost != "" && obj.cost != undefined)
            obj.cost = obj.cost.toFixed(2)
        else
            obj.cost = 0;

        this.delegateProductForm.controls.subtotal.setValue(obj.cost);
        this.applyTax(obj.cost);

    }

    getCoursesList() {

        this._coursesService.getCoursesListWithId().subscribe((result: any) => {


            if (result.success == true) {

                this.coursesList = result.data;

            } else {

                //this._commonService.error(result.data.desc);
            }

        }, (_error: any) => {
            //this.loading = false;
        })

    }


    //DAYS COUNT
    getDaysCount(dateString): string {
        const givenDate = new Date(dateString);
        const today = new Date();
        const timeDiff = today.getTime() - givenDate.getTime();
        const daysDiff = (Math.floor(timeDiff / (1000 * 3600 * 24)));
        return daysDiff.toString();
    }

    getColor(value) {

        if (value <= 28) {
            return 'outstanding-green';
        } else if (value > 28 && value <= 49) {
            return 'amber';
        } else if (value > 63) {
            return 'red';
        } else if (value > 49) {
            return 'redborder-simple';
        }
    }

    getStatus(course) {
        // invoice is paid
        if (course != null) {
            if (course.isInvoicePaid == true) {
                return 'green';
            }
            else {
                return this.getColor(this.getDaysCount(course.invoiceDate));
            }
        }
        else return '';
    }

    getProductStatus(prod) {
        // product is paid

        if (prod.isInvoicePaid == true) {
            return 'green';
        }
        else {
            return this.getColor(this.getDaysCount(prod.dateAdded));
        }
    }



    get f() { return this.invoiceEditForm.controls; }

    get p() { return this.invoicePostalForm.controls; }

    get i() { return this.paymentForm.controls; }

    get d() { return this.delegateProductForm.controls; }

    on_Product_PaidClick(data: any, prod, product) {
        debugger;
        this._purchaseId = product.purchaseId;

        this.paymentForm.controls.invoiceNumber.setValue(product.invoiceNumber);

        if (product.reconcileOn != null)
            this.paymentDate = new Date(product.reconcileOn);
        else this.paymentDate = null;
        //debugger;
        this._reconcile = product.isInvoicePaid;
        this._isPaired = product.isPaired;
        this.selectedPaymentMethod = product.transactionType;
        this.paymentForm.controls.subtotal.setValue(product.amount);
        this.applyTax(product.amount);

        this.modal.open(this.modalPaymentDetails, { size: 'lg' });

    }

    on_FC_PaidClick(data: any, prod, course) {

        this._FC_bookingId = prod.bookingIds;

        this._isFoundationCert = true;

        this._purchaseId = 0;


        this._courseId = course.courseId;
        //this._xero = data;
        this._bookingId = course.bookingId;

        this.paymentForm.controls.invoiceNumber.setValue(course.invoiceNumber);

        if (course.reconcileDate != null)
            this.paymentDate = new Date(course.reconcileDate);
        else this.paymentDate = null;
        //debugger;
        this._reconcile = course.isInvoicePaid;
        this._isPaired = course.isPaired;
        this.selectedPaymentMethod = course.transactionType;


        this.paymentForm.controls.ponumber.setValue(course.poNumber);
        //this.paymentForm.controls.source.patchValue(course.transactionType);
        this.paymentForm.controls.subtotal.setValue(course.courseCost);
        this.applyTax(course.courseCost);

        this.modal.open(this.modalPaymentDetails, { size: 'lg' });

    }


    addEvent(event: MatDatepickerInputEvent<Date>) {

        if (event.value != null) {

            this.paymentDate = new Date(event.value);
            this.paymentDate.setDate(event.value.getDate());
            this.paymentDate.setMonth(event.value.getMonth());
            this.paymentDate.setFullYear(event.value.getFullYear());


            // product payment date
            this.prodPaymentDate = new Date(event.value);
            this.prodPaymentDate.setDate(event.value.getDate());
            this.prodPaymentDate.setMonth(event.value.getMonth());
            this.prodPaymentDate.setFullYear(event.value.getFullYear());


            // console.log(event.value)
        }

    }


    onPaidClick(data: any, course) {
        debugger;
        this._FC_bookingId = "";

        this._isFoundationCert = false;

        this.isCreditNote = false;

        this._purchaseId = 0;

        this._courseId = course.courseId;
        //this._xero = data;
        this._bookingId = course.bookingId;

        this.paymentForm.controls.invoiceNumber.setValue(course.invoiceNumber);
        this.paymentForm.controls.ponumber.setValue(course.poNumber);
        this.paymentForm.controls.cnNumber.setValue(course.cnNumber);

        if (course.cnNumber != null && course.cnNumber != "")
            this.isCreditNote = true;

        if (course.reconcileDate != null)
            this.paymentDate = new Date(course.reconcileDate);
        else this.paymentDate = null;

        this._reconcile = course.isInvoicePaid;
        this._isPaired = course.isPaired;
        this.selectedPaymentMethod = course.transactionType;
        //this.paymentForm.controls.source.patchValue(course.transactionType);
        this.paymentForm.controls.subtotal.setValue(course.courseCost);
        this.applyTax(course.courseCost);

        this.modal.open(this.modalPaymentDetails, { size: 'lg' });

        //if (this._xero.target.checked == true)
        //    this.modal.open(this.modalPaymentDetails, { size: 'lg' });
        //else {
        //    this.modal.open(this.modalPaymentDetails, { size: 'lg' });


        //}
        //this.onPaymentSubmit();
    }

    applyTax(d: any) {


        var tax = (d * 20) / 100;
        this.paymentForm.controls.totalTax.setValue(tax.toFixed(2));
        this.paymentForm.controls.total.setValue((Number(d) + Number(tax)).toFixed(2));

        this.delegateProductForm.controls.totalTax.setValue(tax.toFixed(2));
        this.delegateProductForm.controls.total.setValue((Number(d) + Number(tax)).toFixed(2));
        //this.delegateProductForm.controls.total.setValue((Number(d)).toFixed(2));
    }


    applyProductTax(_applyTax) {

        var qty = Number(this.delegateProductForm.controls.quantity.value);
        var subtotal = Number(this.delegateProductForm.controls.subtotal.value);
        var shippingCost = Number(this.delegateProductForm.controls.shippingCost.value);
        //var totalTax = Number(this.delegateProductForm.controls.totalTax.value);

        var total = qty * subtotal;
        var tax = (total * 20) / 100;



        //var total = (Number(this.delegateProductForm.controls.subtotal.value) +
        //    Number(this.delegateProductForm.controls.shippingCost.value));

        //var tax = (total * 20) / 100;



        if (_applyTax) {
            this.delegateProductForm.controls.total.setValue((Number(total) + Number(shippingCost) + Number(tax)).toFixed(2));
            this.delegateProductForm.controls.totalTax.setValue(tax.toFixed(2));

        }
        else {
            var totalTax = Number(this.delegateProductForm.controls.totalTax.value);
            if (totalTax > 0) {
                this.delegateProductForm.controls.total.setValue((Number(total) + Number(shippingCost) + Number(totalTax)).toFixed(2));
                //this.delegateProductForm.controls.totalTax.setValue(totalTax.toFixed(2));
            }
            else
                this.delegateProductForm.controls.total.setValue(((Number(total) + Number(shippingCost))).toFixed(2));
        }
        //this.delegateProductForm.controls.total.setValue((Number(total) + Number(shippingCost)));

    }

    onEditFoundationCourseSubmit() {
        var req = {
            bookingId: Number(this.fc_bookingIdToUpdate),
            courseId: Number(this.fc_courseIdToUpdate),
            delegateId: this._delegateId,
            currentCourseId: Number(this.fc_currentCourseId)
        };

        this._coursesService.updateFoundationCertificate(req).subscribe((result: any) => {


            if (result.success == true) {

                this.editFoundationCourseForm.reset();

                this.editFoundationCourseForm.controls.dd_GDPR.patchValue("none");
                this.editFoundationCourseForm.controls.dd_FOI.patchValue("none");
                this.editFoundationCourseForm.controls.dd_RM.patchValue("none");

                this.modal.dismissAll();
                this.getDelegateData();

            } else {
                //show errror popup

            }

            //}, (_error: any) => {
            //    //this.loading = false;
        })
    }

    onPaymentSubmit() {
        var req = {};

        req = {
            courseId: this._courseId,
            delegateId: this._delegateId,
            InvoiceNumber: this.paymentForm.controls.invoiceNumber.value,
            InvoicePaidDate: this.paymentDate, //this.paymentForm.controls.p_paymentDate.value,
            InvoicePaidBy: this.paymentForm.controls.source.value,
            SubTotal: "" + this.paymentForm.controls.subtotal.value,
            TotalTax: "" + this.paymentForm.controls.totalTax.value,
            //IsInvoicePaid: this._xero.target.checked,
            IsReconcile: this.paymentForm.controls.reconcile.value,
            IsPaired: this.paymentForm.controls.isPaired.value,
            CurrencyCode: "GBP",
            bookingId: this._bookingId,
            isFoundationCert: this._isFoundationCert,
            FC_bookingIds: "" + this._FC_bookingId,
            purchaseId: this._purchaseId,
            Ponumber: this.paymentForm.controls.ponumber.value,
            cnNumber: this.paymentForm.controls.cnNumber.value
        };


        this._coursesService.updateDelegatePayment(req).subscribe((result: any) => {


            if (result.success == true) {

                this.paymentForm.reset();

                this.paymentForm.controls.source.patchValue("none");
                this.modal.dismissAll();
                this.getDelegateData();

            } else {
                //show error popup
                this._commonService.error(result.message);
            }

        }, (_error: any) => {
            //this.loading = false;
        })
    }

    validateAmount(event: KeyboardEvent, _val): void {

        const charCode = event.which || event.keyCode;
        const char = String.fromCharCode(charCode);

        // Allow decimal point (46) and numbers (48-57)
        if (charCode === 190 || charCode === 46 || charCode === 8 || (charCode >= 48 && charCode <= 57)) {
            // Ensure only one decimal point
            if (charCode === 46 && _val.indexOf('.') !== -1) {

                event.preventDefault();
            }
        } else {
            event.preventDefault();
        }
    }

    editDelegateProduct(product) {
        debugger;
        this.populateDelegateForm(product);
        this.modal.open(this.modalAddDelegateProduct, { size: 'lg' });
    }

    editDelegateFC(_course) {

        this.fc_bookingIdToUpdate = _course.bookingId;
        this.fc_currentCourseId = _course.courseId;

        this.showDD = "";

        if (_course.courseTitle == "Introduction to GDPR Webinar")
            this.showDD = "Introduction to GDPR Webinar";
        if (_course.courseTitle == "Introduction to FOI Webinar")
            this.showDD = "Introduction to FOI Webinar";
        if (_course.courseTitle == "Introduction to Records Management Webinar")
            this.showDD = "Introduction to Records Management Webinar";
        this.modal.open(this.modalEditFoundationCourse, { size: 'lg' });
    }

    changeDelegateCourse(courseId) {
        //this.populateDelegateForm(product);

        this._courseId = courseId;

        this.delegateCourseChangeForm.controls.courseTitle.patchValue(courseId);
        this.modal.open(this.modalChangeDelegateCourse, { size: 'lg' });
    }

    populateDelegateForm(product) {

        if (product != null) {

            this.delegateProductForm.controls.invoiceNumber.setValue(product.invoiceDetail.invoiceNumber);
            this.prodPaymentDate = product.invoiceDetail.invoicePaidOn;
            this.delegateProductForm.controls.source.setValue(product.invoiceDetail.transactionType);
            this.delegateProductForm.controls.subtotal.setValue(product.invoiceDetail.amount.toFixed(2));
            this.delegateProductForm.controls.quantity.setValue(product.invoiceDetail.productQuantity);
            this.delegateProductForm.controls.shippingCost.setValue(product.invoiceDetail.shippingCost);
            this.delegateProductForm.controls.totalTax.setValue(product.invoiceDetail.totalTax);
            this.delegateProductForm.controls.total.setValue(product.invoiceDetail.invoiceTelephone);
            this.delegateProductForm.controls.reconcile.setValue(product.invoiceDetail.isInvoicePaid);
            this.delegateProductForm.controls.isPaired.setValue(product.invoiceDetail.isInvoicePaired);
            this.delegateProductForm.controls.productName.patchValue(product.productId);
            this.delegateProductForm.controls.cnNumber.setValue(product.invoiceDetail.creditNoteNumber);
            this.delegateProductForm.controls.ponumber.patchValue(product.poNumber);

            this._isPaired = product.invoiceDetail.isInvoicePaired;
            this._reconcile = product.invoiceDetail.isInvoicePaid;

            //this.applyTax(product.invoiceDetail.amount);

            if (product.invoiceDetail.totalTax > 0)
                this.applyProductTax(true);
            else
                this.applyProductTax(false);

            this.isCreditNote = false;

            //debugger;
            if (product.invoiceDetail.creditNoteNumber != null && product.invoiceDetail.creditNoteNumber != '')
                this.isCreditNote = true;

            this._bookingId = product.invoiceDetail.bookingId;
            this.selectedPaymentMethod = product.invoiceDetail.transactionType;
            this._invoiceId = product.invoiceDetail.id;
            this._purchaseId = product.invoiceDetail.purchaseId;
        }

    }


    onDelegateCourseChangeSubmit() {
        var req = {};

        req = {
            courseId: this._courseId,
            delegateId: this._delegateId,
            newCourseId: this.delegateCourseChangeForm.controls.courseTitle.value
        };


        this._delegateService.changeDelegateCourse(req).subscribe((result: any) => {


            if (result.success == true) {

                this.delegateCourseChangeForm.reset();

                this.modal.dismissAll();
                this.getDelegateData();

            } else {
                //show error popup

            }

        }, (_error: any) => {
            //this.loading = false;
        })
    }

    onDelegateProductSubmit() {


        if (this.delegateProductForm.controls.quantity.value == null)
            this.delegateProductForm.controls.quantity.setValue(0);

        if (this.delegateProductForm.controls.shippingCost.value == null)
            this.delegateProductForm.controls.shippingCost.setValue(0);



        var req = {};
        //var _prodPaymentDate = this.delegateProductForm.controls.prod_paymentDate.value ? this.delegateProductForm.controls.prod_paymentDate.value : null;

        req = {
            courseId: this._courseId,
            delegateId: this._delegateId,
            InvoiceNumber: this.delegateProductForm.controls.invoiceNumber.value,
            InvoicePaidDate: this.prodPaymentDate, // this.delegateProductForm.controls.p_paymentDate.value,
            InvoicePaidBy: this.delegateProductForm.controls.source.value,
            SubTotal: "" + this.delegateProductForm.controls.subtotal.value,
            TotalTax: "" + this.delegateProductForm.controls.totalTax.value,
            ProductQuantity: "" + this.delegateProductForm.controls.quantity.value,
            ShippingCost: "" + this.delegateProductForm.controls.shippingCost.value,
            IsReconcile: this.delegateProductForm.controls.reconcile.value,
            IsPaired: this.delegateProductForm.controls.isPaired.value,
            CurrencyCode: "GBP",
            bookingId: this._bookingId,
            isFoundationCert: false,
            productId: this.delegateProductForm.controls.productName.value,
            ponumber: this.delegateProductForm.controls.ponumber.value,
            invoiceId: this._invoiceId,
            purchaseId: this._purchaseId,
            cnNumber: this.delegateProductForm.controls.cnNumber.value
        };


        this._delegateService.insertDelegateProduct(req).subscribe((result: any) => {


            if (result.success == true) {

                this.delegateProductForm.reset();

                this.delegateProductForm.controls.source.patchValue("none");
                this.modal.dismissAll();
                this.getDelegateData();

            } else {
                //show errror popup
                this._commonService.error(result.message);
            }

        }, (_error: any) => {
            //this.loading = false;
        })
    }

    onInvoiceEditSubmit() {

        var req = {

            id: Number(this._delegateId),
            invoiceName: this.invoiceEditForm.controls.invoiceName.value,
            invoiceAddress: this.invoiceEditForm.controls.invoiceAddress.value,
            invoiceEmail: this.invoiceEditForm.controls.invoiceEmail.value,
            invoiceDepartment: this.invoiceEditForm.controls.invoiceDepartment.value,
            invoiceOrganization: this.invoiceEditForm.controls.invoiceOrganization.value,
            invoiceTelephone: this.invoiceEditForm.controls.invoiceTelephone.value,
            invoicePostcode: this.invoiceEditForm.controls.invoicePostcode.value,
            invoiceCity: this.invoiceEditForm.controls.invoiceCity.value

        };

        this._delegateService.updateDelegateInvoiceAddress(req).subscribe((result: any) => {


            if (result.success == true) {

                this.modal.dismissAll();
                this.getDelegateData();

            } else {
                //show errror popup

            }

        }, (_error: any) => {
            //this.loading = false;
        })

    }

    updateDelegate() {

        const modalRef = this.modal.open(AddEditDelegateComponent, {
            size: 'lg'
        });
        modalRef.componentInstance.data = this.currentDelegate;

        //if (this._methodToload == this.methodName.archive)
        //    modalRef.componentInstance.refreshGrid.subscribe(event => this.getDelegatesList(true));
        //else if (this._methodToload == this.methodName.nonArchive)
        modalRef.componentInstance.refreshGrid.subscribe(event => this.getDelegateData());

    }

    onInvoicePostalSubmit() {

        var req = {

            id: Number(this._delegateId),
            postalName: this.invoicePostalForm.controls.postalName.value,
            postalAddress: this.invoicePostalForm.controls.postalAddress.value,
            postalTelephone: this.invoicePostalForm.controls.postalTelephone.value,
            postalPostcode: this.invoicePostalForm.controls.postalPostcode.value,
            postalCity: this.invoicePostalForm.controls.postalCity.value

        };

        this._delegateService.updateDelegatePostalAddress(req).subscribe((result: any) => {


            if (result.success == true) {

                this.modal.dismissAll();
                this.getDelegateData();

            } else {
                //show errror popup

            }

        }, (_error: any) => {
            //this.loading = false;
        })

    }

    printNo(num) {
        return Number(num).toLocaleString('en-GB');
    }

    updateInvoiceAddress() {

        this.populateInvoiceAddressForm();

        this.modal.open(this.modalContentEdit, { size: 'lg' });
    }

    close2(e: any) {

        if (this._purchaseId > 0)
            $("#" + this._purchaseId + "_chk").prop("checked", !$("#" + this._purchaseId + "_chk").prop('checked'));
        else if (this._FC_bookingId != "")
            $("#" + this._bookingId + "_chk").prop("checked", !$("#" + this._bookingId + "_chk").prop('checked'));
        else
            $("#" + this._courseId + "_chk").prop("checked", !$("#" + this._courseId + "_chk").prop('checked'));
    }

    close() {
        this.modal.dismissAll();
    }

    updatePostalAddress() {
        this.populatePostalAddressForm();

        this.modal.open(this.modalContentPostal, { size: 'lg' });
    }

    getDelegateData() {


        if (this._delegateId != null) {
            this._delegateService.getDelegateData(Number(this._delegateId)).subscribe((result: any) => {

                if (result.success == true) {

                    if (result.data.length > 0) {
                        this.currentDelegate = result.data[0];
                        //this.populateData();
                    }
                } else {

                    //this._commonService.error(result.data.desc);
                }

            }, (_error: any) => {
                //this.loading = false;
            })
        }
    }

    addDelegateProduct() {
        this._purchaseId = 0;
        this.delegateProductForm.reset();
        this.modal.open(this.modalAddDelegateProduct, { size: 'lg' });
    }


    showProducts() {
        $("#course-section").hide();
        $("#prod-section").show();

        $("#show-prod-container").addClass("bg-purple-gradient text-white");
        $("#show-prod-container").removeClass("bg-lg flip-btn-border");

        $("#show-course-container").addClass("bg-lg flip-btn-border text-main");
        $("#show-course-container").removeClass("bg-purple-gradient text-white");

    }

    showCourses() {
        $("#course-section").show();
        $("#prod-section").hide();

        $("#show-course-container").addClass("bg-purple-gradient text-white");
        $("#show-course-container").removeClass("bg-lg flip-btn-border");

        $("#show-prod-container").addClass("bg-lg flip-btn-border text-main");
        $("#show-prod-container").removeClass("bg-purple-gradient text-white");
    }

    allDelegates() {
        this.gotoAllDelegates.emit();
        //this._route.navigateByUrl("/courses/all-courses");
    }

    goBack() {

        //debugger;
        var previousPath = localStorage.getItem("PREVIOUS_PATH");

        if (previousPath != null && previousPath != "" && previousPath != undefined) {
            if (previousPath == "/finance/invoices-list") {
                //this._route.navigateByUrl('/finance/invoices-list?rndm=' + Math.floor(Math.random() * (100 - 0 + 1)) + 0);
                this.backNavigation.emit();
            }
            else if (previousPath == "/product/bookings?type=true" || previousPath == "/product/bookings?type=false") {
                this.backNavigation.emit();
            }
            else {
                this.gotoAllDelegates.emit();
                this.backNavigation.emit();
            }
        }



    }


    onGDPRSelected(event) {
        this.selectedGDPR = event.value.split(','); // event.source.selected._element.nativeElement.innerText.split(',');

        this.fc_courseIdToUpdate = this.selectedGDPR[0];
        //this.fc_bookingIdToUpdate = this.selectedGDPR[1];


    }

    onFOISelected(event) {
        this.selectedFOI = event.value.split(','); // event.source.selected._element.nativeElement.innerText.split(',');

        this.fc_courseIdToUpdate = this.selectedFOI[0];
        //this.fc_bookingIdToUpdate = this.selectedFOI[1];

    }

    onRMSelected(event) {
        this.selectedRM = event.value.split(','); //event.source.selected._element.nativeElement.innerText.split(',');

        this.fc_courseIdToUpdate = this.selectedRM[0];
        //this.fc_bookingIdToUpdate = this.selectedRM[1];

    }

    setProductFormInputNotRequired(formControlName: string): void {
        const control = this.delegateProductForm.get(formControlName);
        if (control) {
            control.clearValidators();
            control.updateValueAndValidity();
        }
    }

    resetProductFormInputNotRequired(formControlName: string): void {
        const control = this.delegateProductForm.get(formControlName);
        if (control) {
            control.reset();
            control.updateValueAndValidity();
        }
    }

    setCourseFormInputNotRequired(formControlName: string): void {
        const control = this.paymentForm.get(formControlName);
        if (control) {
            control.clearValidators();
            control.updateValueAndValidity();
        }
    }

    resetCourseFormInputNotRequired(formControlName: string): void {
        const control = this.paymentForm.get(formControlName);
        if (control) {
            control.reset();
            control.updateValueAndValidity();
        }
    }
    onPaymentMethodChanged(event) {
        
        if (event.value == "Cancelled") {
            $(`[formControlName="invoiceNumber"]`).removeClass('is-invalid');
            this.setProductFormInputNotRequired('invoiceNumber');
            this.setCourseFormInputNotRequired('invoiceNumber');
        }
        else {
            $(`[formControlName="invoiceNumber"]`).addClass('is-invalid');
            this.resetProductFormInputNotRequired('invoiceNumber');
            this.resetCourseFormInputNotRequired('invoiceNumber');
        }

        if (event.value == "Credit Note")
            this.isCreditNote = true;
        else {
            this.isCreditNote = false;

            this.paymentForm.controls.cnNumber.setValue(null);
            this.delegateProductForm.controls.cnNumber.setValue(null);
        }
    }


}
