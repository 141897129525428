<div class="row">
    <div class="col-lg-9">

        <h2 class="mb-4 text-second font-ag-light" style="font-size: 3rem;">
            <span class="text-main font-ag-bold">Dashboard</span>
        </h2>

    </div>

    <div class="col-lg-3 pt-1">
    </div>

</div>

<h1 class="font-ag-light fw-400 text-second section-heading mb-4">Weekly Bookings</h1>




<div class="row mt-4-">
    <section class="actnow-4-blocks my-4">

        <div class="row pl-1">

            <div class="col-12 col-md-3 px-0">

                <div class="bg-purple-gradient text-center left-stats-box pb-4 mb-1">
                    <div class="d-flex- text-white align-items-end- stats-box-inner">
                        <span class="left-stats-figure">{{an_stats.thisWeekBookings}}</span>
                    </div>

                    <div class="">
                        <h5 class="text-white font-ag-bold" style="font-size: 2rem;">Bookings</h5>
                    </div>
                </div>

                <div class="bg-yellow-gradient text-center left-stats-box pb-4 mb-1">
                    <div class="d-flex- text-white align-items-end- stats-box-inner">
                        <span class="left-stats-figure">{{an_stats.thisWeekInhouseBookings}}</span>
                    </div>

                    <div class="">
                        <h5 class="text-white font-ag-bold" style="font-size: 2rem;">Inhouse</h5>
                    </div>
                </div>

                <div class="bg-blue-gradient text-center left-stats-box pb-4 mb-1">
                    <div class="d-flex- text-white align-items-end- stats-box-inner">
                        <span class="stats-figure">&#163;</span>
                        <!--<span class="left-stats-figure">{{(an_stats.thisWeekRevenue + an_stats.thisWeekInhouseRevenue + an_stats.thisWeekProductsRevenue) | number : '1.2-2'}}</span>-->
                        <span class="left-stats-figure">{{(an_stats.thisWeekFullDayRevenue + an_stats.thisWeekCertificateRevenue + an_stats.thisWeekFcRevenue + an_stats.thisWeekWebinarRevenue + an_stats.thisWeekELearningRevenue + an_stats.thisWeekInhouseRevenue + an_stats.thisWeekProductsRevenue) | number : '1.2-2'}}</span>
                    </div>

                    <div class="">
                        <h5 class="text-white font-ag-bold" style="font-size: 2rem;">Revenue</h5>
                    </div>
                </div>


            </div>

            <div class="col-12 col-md-3 px-0">

                <div class="bg-box-gray text-center p-4 h-100">

                    <div class="d-flex- flex-column justify-content-center align-items-center-">

                        <div class="mt-0 text-center">
                            <h3 class="mb-4 text-second font-ag-light fw-300" style="font-size: 2.5rem;">Upcoming courses</h3>
                        </div>

                        <div class="col-12 week-courses-box">

                            <div class="row" *ngFor="let course of thisWeekList">
                                <div class="col-10 col-md-9  text-left">

                                    <ul class="list-unstyled mb-0 heading-color">

                                        <li class="mb-2 text-second font-ag-bold">

                                            <a class="font-ag other-link" (click)="viewCourse(course)">
                                                <h5 class="font-weight-bold mb-0 mt-2 online-course-heading">
                                                    {{course.courseTitle}}
                                                </h5>
                                            </a>

                                        </li>

                                        <li class="heading-color">Location: <span class="text-second font-ag-bold menu-sub-heading">{{course.courseLocation}}</span></li>
                                        <li class="heading-color">Course date: <span class="text-second font-ag-bold menu-sub-heading">{{course.dateDisplay | date:'dd-MM-yyyy'}}</span></li>
                                        <li class="heading-color">Speaker: <span class="text-second font-ag-bold menu-sub-heading">{{course.speakerName}}</span></li>

                                    </ul>

                                </div>


                                <div class="col-3 d-none d-md-flex align-items-end justify-content-end">

                                    <!--<a [routerLink]="/courses/course-detail?cid=5441">Open Message 44</a>-->
                                    <a class="book-btn bg-yellow-gradient- mr-1" role="button" (click)="courseDetails(course)">Details</a>

                                </div>
                                <div class="col-12 py-2-">

                                    <hr class="u-line mt-2">

                                </div>
                            </div>

                            <div class="row" *ngIf="thisWeekList.length == 0">
                                <div class="col-10 col-md-9  text-left">

                                    <h5 class="text-second font-weight-bold mb-0 mt-2 online-course-heading">
                                        No more bookings
                                    </h5>

                                </div>
                            </div>

                        </div>


                    </div>

                </div>

            </div>

            <div class="col-12 col-md-3 px-0">

                <div class="bg-light text-center p-4 h-100">

                    <div class="d-flex- flex-column justify-content-center align-items-center-">

                        <div class="mt-0 text-center">
                            <h3 class="mb-4 text-second font-ag-light fw-300" style="font-size: 2.5rem;">Approvals</h3>
                        </div>

                        <div class="col-12 week-courses-box">

                            <div class="row" *ngFor="let appr of approvalsList">
                                <div class="col-10 col-md-9  text-left">

                                    <ul class="list-unstyled mb-0 heading-color">

                                        <li class="mb-2 text-second font-ag-bold">
                                            <a class="font-ag other-link" (click)="bookingDetail(appr)">
                                                <h4 class="font-weight-bold mb-0 mt-2 online-course-heading  text-second">
                                                    {{appr.name}}
                                                </h4>
                                            </a>

                                            <a class="font-ag other-link" (click)="bookingDetail(appr)">
                                                <h5 class="font-weight-bold mb-0 mt-2 online-course-heading">
                                                    {{appr.courseName}}
                                                </h5>
                                            </a>

                                        </li>

                                        <li class="heading-color">Booking date: <span class="text-second font-ag-bold menu-sub-heading">{{appr.dateAdded | date:'dd-MM-yyyy'}}</span></li>

                                        <li class="heading-color">Location: <span class="text-second font-ag-bold menu-sub-heading">{{appr.courseLocation}}</span></li>
                                        <li class="heading-color">Course date: <span class="text-second font-ag-bold menu-sub-heading">{{appr.courseDate | date:'dd-MM-yyyy'}}</span></li>
                                        <li class="heading-color">Speaker: <span class="text-second font-ag-bold menu-sub-heading">{{appr.speaker}}</span></li>
                                        <!--<li class="d-md-none"><a class="btn btn-link text-second p-0" role="button" href="/course/gdpr-practitioner-certificate"><u>BOOK</u></a></li>-->

                                    </ul>

                                </div>


                                <div class="col-12 d-none d-md-flex align-items-end justify-content-end mt-2">

                                    <a class="book-btn bg-yellow-gradient- mr-1" role="button" (click)="rejectBooking(appr)">Reject</a>
                                    <a class="book-btn bg-yellow-gradient- mr-1" role="button" (click)="bookingDetail(appr)">Approve</a>

                                </div>
                                <div class="col-12 py-2-">

                                    <hr class="u-line mt-2">

                                </div>
                            </div>

                            <div class="row" *ngIf="approvalsList.length == 0">
                                <div class="col-10 col-md-9  text-left">

                                    <h5 class="text-second font-weight-bold mb-0 mt-2 online-course-heading">
                                        No pending approval
                                    </h5>

                                </div>
                            </div>

                        </div>


                    </div>

                </div>

            </div>

            <div class="col-12 col-md-3 px-0">

                <div class="bg-box-gray text-center p-4 h-100">

                    <div class="d-flex- flex-column justify-content-center align-items-center-">

                        <div class="mt-0 text-center">
                            <h3 class="mb-4 text-second font-ag-light fw-300" style="font-size: 2.5rem;">To Do</h3>
                        </div>

                        <div class="col-12 week-courses-box">

                            <div class="row" *ngFor="let notification of notificationsList">
                                <div class="col-12 col-md-12 text-left">
                                    <a class="font-ag other-link text-notification-" href="{{notification.navigateUrl}}" [innerHTML]="notification.mesage">
                                    </a>
                                </div>
                                <div class="col-12 d-none d-md-flex align-items-end justify-content-end mt-2">

                                    <a class="book-btn bg-yellow-gradient- mr-1" role="button" (click)="actionTaken(notification)">Done</a>

                                </div>

                                <div class="col-12 py-2-">
                                    <hr class="u-line mt-2">
                                </div>

                            </div>

                            <div class="row" *ngIf="notificationsList.length == 0">
                                <div class="col-10 col-md-9  text-left">

                                    <h5 class="text-second font-weight-bold mb-0 mt-2 online-course-heading">
                                        No pending To Do's
                                    </h5>

                                </div>
                            </div>

                            <!--<div class="row">
                                <div class="col-12 col-md-12 text-left">
                                    <a class="font-ag other-link" href="/courses/4340">
                                        <h5 class="font-weight-bold mb-0 mt-2 online-course-heading">
                                            FOI Practitioner Certificate
                                        </h5>
                                    </a>
                                </div>
                                <div class="col-6 col-md-12 text-left">
                                    Date: <span class="text-second font-ag-bold menu-sub-heading">06-10-2022</span>
                                    <br />Location: <span class="text-second font-ag-bold menu-sub-heading">Online</span>
                                </div>

                                <div class="col-12 py-2-">
                                    <hr class="u-line mt-2">
                                </div>

                            </div>

                            <div class="row">
                                <div class="col-12 col-md-12 text-left">
                                    <a class="font-ag other-link" href="/courses/4340">
                                        <h5 class="font-weight-bold mb-0 mt-2 online-course-heading">
                                            FOI Practitioner Certificate
                                        </h5>
                                    </a>
                                </div>
                                <div class="col-6 col-md-12 text-left">
                                    Date: <span class="text-second font-ag-bold menu-sub-heading">06-10-2022</span>
                                    <br />Location: <span class="text-second font-ag-bold menu-sub-heading">Online</span>
                                </div>

                                <div class="col-12 py-2-">
                                    <hr class="u-line mt-2">
                                </div>

                            </div>

                            <div class="row">
                                <div class="col-12 col-md-12 text-left">
                                    <a class="font-ag other-link" href="/courses/4340">
                                        <h5 class="font-weight-bold mb-0 mt-2 online-course-heading">
                                            FOI Practitioner Certificate
                                        </h5>
                                    </a>
                                </div>
                                <div class="col-6 col-md-12 text-left">
                                    Date: <span class="text-second font-ag-bold menu-sub-heading">06-10-2022</span>
                                    <br />Location: <span class="text-second font-ag-bold menu-sub-heading">Online</span>
                                </div>

                                <div class="col-12 py-2-">
                                    <hr class="u-line mt-2">
                                </div>

                            </div>-->



                        </div>


                    </div>

                </div>

            </div>


        </div>


    </section>

</div>




<div *ngIf="uRole=='Super Admin' || uRole =='Admin'">

    <div class="row">
        <section class="actnow-4-blocks my-4 mt-0">

            <div class="row pl-1">

                <div class="col px-0">
                    <div class="bg-light text-center p-1 h-100-">

                        <div class="d-flex- flex-column justify-content-center align-items-center">

                            <div class="mt-4">

                                <h5 class="text-main font-ag-bold mb-1" style="font-size: 2rem;">Full Day</h5>

                            </div>

                        </div>

                    </div>

                    <div class="bg-light text-center p-4 h-100-">
                        <div class="d-flex- flex-column justify-content-center align-items-center">
                            <div class="bg-white text-center p-2 h-100-">

                                <div class="d-flex- flex-column justify-content-center align-items-center-">

                                    <div class="col-12 week-courses-box h-auto">

                                        <div class="row">
                                            <div class="col-12 col-md-12 text-left">
                                                <a class="font-ag other-link">
                                                    <h5 class="font-weight-bold mb-0 mt-2 online-course-heading">
                                                        This Week
                                                    </h5>
                                                </a>
                                            </div>
                                            <div class="col-6 col-md-12 text-left">
                                                Total: <span class="text-second font-ag-bold menu-sub-heading">{{an_stats.thisWeekFullDay}}</span>
                                            </div>

                                            <div class="col-6 col-md-12 text-left">
                                                Revenue: <span class="text-second font-ag-bold menu-sub-heading">&#163;{{an_stats.thisWeekFullDayRevenue | number : '1.2-2'}}</span>
                                            </div>

                                            <div class="col-12 py-2-">
                                                <hr class="u-line mt-2">
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="col px-0">

                    <div class="bg-box-gray text-center p-1 h-100-">

                        <div class="d-flex- flex-column justify-content-center align-items-center">

                            <div class="mt-4">

                                <h5 class="text-main font-ag-bold mb-1" style="font-size: 2rem;">Certificates</h5>

                            </div>

                        </div>

                    </div>

                    <div class="bg-box-gray text-center p-4 h-100-">
                        <div class="d-flex- flex-column justify-content-center align-items-center">
                            <div class="bg-white text-center p-2 h-100-">

                                <div class="d-flex- flex-column justify-content-center align-items-center-">


                                    <div class="col-12 week-courses-box h-auto">

                                        <div class="row">
                                            <div class="col-12 col-md-12 text-left">
                                                <a class="font-ag other-link">
                                                    <h5 class="font-weight-bold mb-0 mt-2 online-course-heading">
                                                        This Week
                                                    </h5>
                                                </a>
                                            </div>
                                            <div class="col-6 col-md-12 text-left">
                                                Total: <span class="text-second font-ag-bold menu-sub-heading">{{an_stats.thisWeekCertificate + an_stats.thisWeekFc}}</span>
                                            </div>

                                            <div class="col-6 col-md-12 text-left">
                                                Revenue: <span class="text-second font-ag-bold menu-sub-heading">&#163;{{(an_stats.thisWeekCertificateRevenue + an_stats.thisWeekFcRevenue)  | number : '1.2-2'}}</span>
                                            </div>

                                            <div class="col-12 py-2-">
                                                <hr class="u-line mt-2">
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="col px-0">

                    <div class="bg-box-gray text-center p-1 h-100-">

                        <div class="d-flex- flex-column justify-content-center align-items-center">

                            <div class="mt-4">

                                <h5 class="text-main font-ag-bold mb-1" style="font-size: 2rem;">Webinars</h5>

                            </div>

                        </div>

                    </div>

                    <div class="bg-box-gray text-center p-4 h-100-">
                        <div class="d-flex- flex-column justify-content-center align-items-center">
                            <div class="bg-white text-center p-2 h-100-">

                                <div class="d-flex- flex-column justify-content-center align-items-center-">


                                    <div class="col-12 week-courses-box h-auto">

                                        <div class="row">

                                            <div class="col-12 col-md-12 text-left">
                                                <a class="font-ag other-link">
                                                    <h5 class="font-weight-bold mb-0 mt-2 online-course-heading">
                                                        This Week
                                                    </h5>
                                                </a>
                                            </div>
                                            <div class="col-6 col-md-12 text-left">
                                                Total: <span class="text-second font-ag-bold menu-sub-heading">{{an_stats.thisWeekWebinar}}</span>
                                            </div>


                                            <div class="col-6 col-md-12 text-left">
                                                Revenue: <span class="text-second font-ag-bold menu-sub-heading">&#163;{{an_stats.thisWeekWebinarRevenue | number : '1.2-2'}}</span>
                                            </div>

                                            <div class="col-12 py-2-">
                                                <hr class="u-line mt-2">
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="col px-0">

                    <div class="bg-light text-center p-1 h-100-">

                        <div class="d-flex- flex-column justify-content-center align-items-center">

                            <div class="mt-4">

                                <h5 class="text-main font-ag-bold mb-1" style="font-size: 2rem;">eLearning</h5>

                            </div>

                        </div>

                    </div>

                    <div class="bg-light text-center p-4 h-100-">
                        <div class="d-flex- flex-column justify-content-center align-items-center">
                            <div class="bg-white text-center p-2 h-100-">

                                <div class="d-flex- flex-column justify-content-center align-items-center-">


                                    <div class="col-12 week-courses-box h-auto">

                                        <div class="row">
                                            <div class="col-12 col-md-12 text-left">
                                                <a class="font-ag other-link">
                                                    <h5 class="font-weight-bold mb-0 mt-2 online-course-heading">
                                                        This Week
                                                    </h5>
                                                </a>
                                            </div>
                                            <div class="col-6 col-md-12 text-left">
                                                Total: <span class="text-second font-ag-bold menu-sub-heading">{{an_stats.thisWeekELearning}}</span>
                                                <span class="text-second font-ag-bold f-licences"> ({{an_stats.thisWeekELearningLicence}})</span>
                                            </div>


                                            <div class="col-6 col-md-12 text-left">
                                                Revenue: <span class="text-second font-ag-bold menu-sub-heading">&#163;{{an_stats.thisWeekELearningRevenue | number : '1.2-2'}}</span>
                                            </div>

                                            <div class="col-12 py-2-">
                                                <hr class="u-line mt-2">
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="col px-0">

                    <div class="bg-box-gray text-center p-1 h-100-">

                        <div class="d-flex- flex-column justify-content-center align-items-center">

                            <div class="mt-4">

                                <h5 class="text-main font-ag-bold mb-1" style="font-size: 2rem;">Products</h5>

                            </div>

                        </div>

                    </div>

                    <div class="bg-box-gray text-center p-4 h-100-">
                        <div class="d-flex- flex-column justify-content-center align-items-center">
                            <div class="bg-white text-center p-2 h-100-">

                                <div class="d-flex- flex-column justify-content-center align-items-center-">


                                    <div class="col-12 week-courses-box h-auto">

                                        <div class="row">
                                            <div class="col-12 col-md-12 text-left">
                                                <a class="font-ag other-link">
                                                    <h5 class="font-weight-bold mb-0 mt-2 online-course-heading">
                                                        This Week
                                                    </h5>
                                                </a>
                                            </div>
                                            <div class="col-6 col-md-12 text-left">
                                                Total: <span class="text-second font-ag-bold menu-sub-heading">{{an_stats.thisWeekProducts}}</span>
                                                <span class="text-second font-ag-bold f-licences"> ({{an_stats.thisWeekProductsLicence}})</span>
                                            </div>


                                            <div class="col-6 col-md-12 text-left">
                                                Revenue: <span class="text-second font-ag-bold menu-sub-heading">&#163;{{an_stats.thisWeekProductsRevenue | number : '1.2-2'}}</span>
                                            </div>

                                            <div class="col-12 py-2-">
                                                <hr class="u-line mt-2">
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="col px-0">

                    <div class="bg-light text-center p-1 h-100-">

                        <div class="d-flex- flex-column justify-content-center align-items-center">


                            <div class="mt-4">

                                <h5 class="text-main font-ag-bold mb-1" style="font-size: 2rem;">Inhouse</h5>

                            </div>

                        </div>

                    </div>

                    <div class="bg-light text-center p-4 h-100-">
                        <div class="d-flex- flex-column justify-content-center align-items-center">
                            <div class="bg-white text-center p-2 h-100-">

                                <div class="d-flex- flex-column justify-content-center align-items-center-">


                                    <div class="col-12 week-courses-box h-auto">

                                        <div class="row">
                                            <div class="col-12 col-md-12 text-left">
                                                <a class="font-ag other-link">
                                                    <h5 class="font-weight-bold mb-0 mt-2 online-course-heading">
                                                        This Week
                                                    </h5>
                                                </a>
                                            </div>
                                            <div class="col-6 col-md-12 text-left">
                                                Total: <span class="text-second font-ag-bold menu-sub-heading">{{an_stats.thisWeekInhouseBookings}}</span>
                                            </div>

                                            <div class="col-6 col-md-12 text-left">
                                                Revenue: <span class="text-second font-ag-bold menu-sub-heading">&#163;{{an_stats.thisWeekInhouseRevenue | number : '1.2-2'}}</span>
                                            </div>

                                            <div class="col-12 py-2-">
                                                <hr class="u-line mt-2">
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>


            </div>

        </section>

    </div>


</div>


<div *ngIf="uRole=='Super Admin' || uRole =='Admin'">
    <h1 class="font-ag-light fw-400 text-second section-heading mb-4">Previous Week</h1>

    <div class="row">
        <section class="actnow-4-blocks my-4 mt-0">

            <div class="row pl-1">

                <!--<div class="col-12 col-md-2 px-0">-->
                <div class="col px-0">
                    <div class="bg-light text-center p-4 h-100-">

                        <div class="d-flex- flex-column justify-content-center align-items-center">

                            <div class="d-flex- align-items-end text-white bg-purple-gradient stats-box stats-box-booking mt-3 mb-3">

                                <span class="stats-figure">{{an_stats.previousWeekFullDay}}</span>

                            </div>

                            <div class="mt-4">

                                <h5 class="text-main font-ag-bold mb-1" style="font-size: 2rem;">Full Day</h5>

                            </div>

                        </div>

                    </div>

                    <div class="bg-light text-center p-4 pt-0- h-100-" style="padding-top:0px !important;;">
                        <div class="d-flex- flex-column justify-content-center align-items-center">
                            <div class="bg-white text-center p-2 h-100-">

                                <div class="d-flex- flex-column justify-content-center align-items-center-">

                                    <div class="col-12 week-courses-box h-auto">

                                        <div class="row">

                                            <div class="col-6 col-md-12 text-left">
                                                Revenue: <span class="text-second font-ag-bold menu-sub-heading">&#163;{{an_stats.previousWeekFullDayRevenue | number : '1.2-2'}}</span>
                                            </div>

                                            <div class="col-12 py-2-">
                                                <hr class="u-line mt-2">
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="col px-0">

                    <div class="bg-box-gray text-center p-4 h-100-">

                        <div class="d-flex- flex-column justify-content-center align-items-center">

                            <div class="d-flex- align-items-end text-white bg-purple-gradient stats-box stats-box-booking mt-3 mb-3">

                                <span class="stats-figure">{{an_stats.previousWeekCertificate + an_stats.previousWeekFc}}</span>

                            </div>

                            <div class="mt-4">

                                <h5 class="text-main font-ag-bold mb-1" style="font-size: 2rem;">Certificates</h5>

                            </div>

                        </div>

                    </div>

                    <div class="bg-light text-center p-4 pt-0- h-100-" style="padding-top:0px !important;;">
                        <div class="d-flex- flex-column justify-content-center align-items-center">
                            <div class="bg-white text-center p-2 h-100-">

                                <div class="d-flex- flex-column justify-content-center align-items-center-">

                                    <div class="col-12 week-courses-box h-auto">

                                        <div class="row">

                                            <div class="col-6 col-md-12 text-left">
                                                Revenue: <span class="text-second font-ag-bold menu-sub-heading">&#163;{{(an_stats.previousWeekCertificateRevenue + an_stats.previousWeekFcRevenue)  | number : '1.2-2'}}</span>
                                            </div>

                                            <div class="col-12 py-2-">
                                                <hr class="u-line mt-2">
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="col px-0">

                    <div class="bg-box-gray text-center p-4 h-100-">

                        <div class="d-flex- flex-column justify-content-center align-items-center">

                            <div class="d-flex- align-items-end text-white bg-purple-gradient stats-box stats-box-booking mt-3 mb-3">

                                <span class="stats-figure">{{an_stats.previousWeekWebinar}}</span>

                            </div>

                            <div class="mt-4">

                                <h5 class="text-main font-ag-bold mb-1" style="font-size: 2rem;">Webinars</h5>

                            </div>

                        </div>

                    </div>

                    <div class="bg-light text-center p-4 pt-0- h-100-" style="padding-top:0px !important;;">
                        <div class="d-flex- flex-column justify-content-center align-items-center">
                            <div class="bg-white text-center p-2 h-100-">

                                <div class="d-flex- flex-column justify-content-center align-items-center-">

                                    <div class="col-12 week-courses-box h-auto">

                                        <div class="row">

                                            <div class="col-6 col-md-12 text-left">
                                                Revenue: <span class="text-second font-ag-bold menu-sub-heading">&#163;{{an_stats.previousWeekWebinarRevenue | number : '1.2-2'}}</span>
                                            </div>

                                            <div class="col-12 py-2-">
                                                <hr class="u-line mt-2">
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="col px-0">

                    <div class="bg-light text-center p-4 h-100-">

                        <div class="d-flex- flex-column justify-content-center align-items-center">

                            <div class="d-flex- align-items-end text-white bg-purple-gradient stats-box stats-box-booking mt-3 mb-3">

                                <span class="stats-figure">{{an_stats.previousWeekELearning}}</span>
                                <span class="stats-figure fs-licences-circle"> ({{an_stats.previousWeekELearningLicence}})</span>
                            </div>

                            <div class="mt-4">

                                <h5 class="text-main font-ag-bold mb-1" style="font-size: 2rem;">eLearning</h5>

                            </div>

                        </div>

                    </div>

                    <div class="bg-light text-center p-4 pt-0- h-100-" style="padding-top:0px !important;;">
                        <div class="d-flex- flex-column justify-content-center align-items-center">
                            <div class="bg-white text-center p-2 h-100-">

                                <div class="d-flex- flex-column justify-content-center align-items-center-">

                                    <div class="col-12 week-courses-box h-auto">

                                        <div class="row">

                                            <div class="col-6 col-md-12 text-left">
                                                Revenue: <span class="text-second font-ag-bold menu-sub-heading">&#163;{{an_stats.previousWeekELearningRevenue | number : '1.2-2'}}</span>
                                            </div>

                                            <div class="col-12 py-2-">
                                                <hr class="u-line mt-2">
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="col px-0">

                    <div class="bg-box-gray text-center p-4 h-100-">

                        <div class="d-flex- flex-column justify-content-center align-items-center">

                            <div class="d-flex- align-items-end text-white bg-purple-gradient stats-box stats-box-booking mt-3 mb-3">

                                <span class="stats-figure">{{an_stats.previousWeekProducts}}</span>
                                <span class="stats-figure fs-licences-circle"> ({{an_stats.previousWeekProductsLicence}})</span>

                            </div>

                            <div class="mt-4">

                                <h5 class="text-main font-ag-bold mb-1" style="font-size: 2rem;">Products</h5>

                            </div>

                        </div>

                    </div>

                    <div class="bg-light text-center p-4 pt-0- h-100-" style="padding-top:0px !important;;">
                        <div class="d-flex- flex-column justify-content-center align-items-center">
                            <div class="bg-white text-center p-2 h-100-">

                                <div class="d-flex- flex-column justify-content-center align-items-center-">

                                    <div class="col-12 week-courses-box h-auto">

                                        <div class="row">

                                            <div class="col-6 col-md-12 text-left">
                                                Revenue: <span class="text-second font-ag-bold menu-sub-heading">&#163;{{an_stats.previousWeekProductsRevenue | number : '1.2-2'}}</span>
                                            </div>

                                            <div class="col-12 py-2-">
                                                <hr class="u-line mt-2">
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="col px-0">

                    <div class="bg-light text-center p-4 h-100-">

                        <div class="d-flex- flex-column justify-content-center align-items-center">

                            <div class="d-flex- align-items-end text-white bg-purple-gradient stats-box stats-box-booking mt-3 mb-3">

                                <span class="stats-figure">{{an_stats.previousWeekInhouseBookings}}</span>

                            </div>

                            <div class="mt-4">

                                <h5 class="text-main font-ag-bold mb-1" style="font-size: 2rem;">Inhouse</h5>

                            </div>

                        </div>

                    </div>

                    <div class="bg-light text-center p-4 pt-0- h-100-" style="padding-top:0px !important;;">
                        <div class="d-flex- flex-column justify-content-center align-items-center">
                            <div class="bg-white text-center p-2 h-100-">

                                <div class="d-flex- flex-column justify-content-center align-items-center-">

                                    <div class="col-12 week-courses-box h-auto">

                                        <div class="row">

                                            <div class="col-6 col-md-12 text-left">
                                                Revenue: <span class="text-second font-ag-bold menu-sub-heading">&#163;{{an_stats.previousWeekInhouseRevenue | number : '1.2-2'}}</span>
                                            </div>

                                            <div class="col-12 py-2-">
                                                <hr class="u-line mt-2">
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>


            </div>

        </section>

    </div>


</div>

<div *ngIf="uRole=='Super Admin' || uRole =='Admin'">
    <h1 class="font-ag-light fw-400 text-second section-heading mb-4">Monthly Bookings</h1>

    <div class="row">
        <section class="actnow-4-blocks my-4 mt-0">

            <div class="row pl-1">

                <!--<div class="col-12 col-md-2 px-0">-->
                <div class="col px-0">


                    <div class="bg-light text-center p-1 h-100-">

                        <div class="d-flex- flex-column justify-content-center align-items-center">

                            <!--<div class="d-flex- align-items-end text-white bg-purple-gradient stats-box stats-box-booking mt-3 mb-3">

                                <span class="stats-figure">{{an_stats.thisWeekFullDay}}</span>

                            </div>-->

                            <div class="mt-4">

                                <h5 class="text-main font-ag-bold mb-1" style="font-size: 2rem;">Full Day</h5>

                            </div>

                        </div>

                    </div>

                    <div class="bg-light text-center p-4 h-100-">
                        <div class="d-flex- flex-column justify-content-center align-items-center">
                            <div class="bg-white text-center p-2 h-100-">

                                <div class="d-flex- flex-column justify-content-center align-items-center-">

                                    <div class="col-12 week-courses-box h-auto">

                                        <div class="row">

                                            <div class="col-12 col-md-12 text-left">
                                                <a class="font-ag other-link">
                                                    <h5 class="font-weight-bold mb-0 mt-2 online-course-heading">
                                                        This month
                                                    </h5>
                                                </a>
                                            </div>
                                            <div class="col-6 col-md-12 text-left">
                                                Total: <span class="text-second font-ag-bold menu-sub-heading">{{an_stats.thisMonthFullDay}}</span>
                                            </div>

                                            <div class="col-6 col-md-12 text-left">
                                                Revenue: <span class="text-second font-ag-bold menu-sub-heading">&#163;{{an_stats.currentMonthFullDayRevenue | number : '1.2-2'}}</span>
                                            </div>

                                            <div class="col-12 py-2-">
                                                <hr class="u-line mt-2">
                                            </div>


                                            <div class="col-12 col-md-12 text-left">
                                                <a class="font-ag other-link">
                                                    <h5 class="font-weight-bold mb-0 mt-2 online-course-heading">
                                                        Previous month
                                                    </h5>
                                                </a>
                                            </div>
                                            <div class="col-6 col-md-12 text-left">
                                                Total: <span class="text-second font-ag-bold menu-sub-heading">{{an_stats.previousMonthFullDay}}</span>
                                            </div>

                                            <div class="col-6 col-md-12 text-left">
                                                Revenue: <span class="text-second font-ag-bold menu-sub-heading">&#163;{{an_stats.previousMonthFullDayRevenue | number : '1.2-2'}}</span>
                                            </div>


                                            <div class="col-12 py-2-">
                                                <hr class="u-line mt-2">
                                            </div>

                                            <!--<div class="mt-0 text-left">
                                                <h3 class="mb-1 mt-4 text-second font-ag-light fw-300" style="font-size: 2rem;">Revenue</h3>
                                            </div>
                                            <div class="col-12 col-md-12 text-left">
                                                <a class="font-ag other-link">
                                                    <h5 class="font-weight-bold mb-0 mt-2 online-course-heading">
                                                        {{an_stats.currentQuarter}}
                                                    </h5>
                                                </a>
                                            </div>
                                            <div class="col-6 col-md-12 text-left">
                                                <span class="text-second font-ag-bold menu-sub-heading">&#163;{{an_stats.currentQuarterFullDayRevenue | number : '1.2-2'}}</span>
                                            </div>

                                            <div class="col-12 py-2-">
                                                <hr class="u-line mt-2">
                                            </div>

                                            <div class="col-12 col-md-12 text-left">
                                                <a class="font-ag other-link">
                                                    <h5 class="font-weight-bold mb-0 mt-2 online-course-heading">
                                                        {{an_stats.previousQuarter}}
                                                    </h5>
                                                </a>
                                            </div>
                                            <div class="col-6 col-md-12 text-left">
                                                <span class="text-second font-ag-bold menu-sub-heading">&#163;{{an_stats.previousYearCurrentQuarterFullDayRevenue | number : '1.2-2'}}</span>
                                            </div>

                                            <div class="col-12 py-2-">
                                                <hr class="u-line mt-2">
                                            </div>-->

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="col px-0">

                    <div class="bg-box-gray text-center p-1 h-100-">

                        <div class="d-flex- flex-column justify-content-center align-items-center">

                            <!--<div class="d-flex- align-items-end text-white bg-purple-gradient stats-box stats-box-booking mt-3 mb-3">

                                <span class="stats-figure">{{an_stats.thisWeekCertificate + an_stats.thisWeekFc}}</span>

                            </div>-->

                            <div class="mt-4">

                                <h5 class="text-main font-ag-bold mb-1" style="font-size: 2rem;">Certificates</h5>

                            </div>

                        </div>

                    </div>

                    <div class="bg-box-gray text-center p-4 h-100-">
                        <div class="d-flex- flex-column justify-content-center align-items-center">
                            <div class="bg-white text-center p-2 h-100-">

                                <div class="d-flex- flex-column justify-content-center align-items-center-">


                                    <div class="col-12 week-courses-box h-auto">

                                        <div class="row">

                                            <div class="col-12 col-md-12 text-left">
                                                <a class="font-ag other-link">
                                                    <h5 class="font-weight-bold mb-0 mt-2 online-course-heading">
                                                        This month
                                                    </h5>
                                                </a>
                                            </div>
                                            <div class="col-6 col-md-12 text-left">
                                                Total: <span class="text-second font-ag-bold menu-sub-heading">{{an_stats.thisMonthCertificate + an_stats.thisMonthFc}}</span>
                                            </div>

                                            <div class="col-6 col-md-12 text-left">
                                                Revenue: <span class="text-second font-ag-bold menu-sub-heading">&#163;{{(an_stats.currentMonthCertificateRevenue + an_stats.currentMonthFcRevenue) | number : '1.2-2'}}</span>
                                            </div>


                                            <div class="col-12 py-2-">
                                                <hr class="u-line mt-2">
                                            </div>


                                            <div class="col-12 col-md-12 text-left">
                                                <a class="font-ag other-link">
                                                    <h5 class="font-weight-bold mb-0 mt-2 online-course-heading">
                                                        Previous month
                                                    </h5>
                                                </a>
                                            </div>
                                            <div class="col-6 col-md-12 text-left">
                                                Total: <span class="text-second font-ag-bold menu-sub-heading">{{an_stats.previousMonthCertificate + an_stats.previousMonthFc}}</span>
                                            </div>

                                            <div class="col-6 col-md-12 text-left">
                                                Revenue: <span class="text-second font-ag-bold menu-sub-heading">&#163;{{(an_stats.previousMonthCertificateRevenue + an_stats.previousMonthFcRevenue) | number : '1.2-2'}}</span>
                                            </div>


                                            <div class="col-12 py-2-">
                                                <hr class="u-line mt-2">
                                            </div>

                                            <!--<div class="mt-0 text-left">
                                                <h3 class="mb-1 mt-4 text-second font-ag-light fw-300" style="font-size: 2rem;">Revenue</h3>
                                            </div>
                                            <div class="col-12 col-md-12 text-left">
                                                <a class="font-ag other-link">
                                                    <h5 class="font-weight-bold mb-0 mt-2 online-course-heading">
                                                        {{an_stats.currentQuarter}}
                                                    </h5>
                                                </a>
                                            </div>
                                            <div class="col-6 col-md-12 text-left">
                                                <span class="text-second font-ag-bold menu-sub-heading">&#163;{{(an_stats.currentQuarterCertificateRevenue + an_stats.currentQuarterFcRevenue) | number : '1.2-2'}}</span>
                                            </div>

                                            <div class="col-12 py-2-">
                                                <hr class="u-line mt-2">
                                            </div>

                                            <div class="col-12 col-md-12 text-left">
                                                <a class="font-ag other-link">
                                                    <h5 class="font-weight-bold mb-0 mt-2 online-course-heading">
                                                        {{an_stats.previousQuarter}}
                                                    </h5>
                                                </a>
                                            </div>
                                            <div class="col-6 col-md-12 text-left">
                                                <span class="text-second font-ag-bold menu-sub-heading">&#163;{{(an_stats.previousYearCurrentQuarterCertificateRevenue + an_stats.previousYearCurrentQuarterFcRevenue) | number : '1.2-2'}}</span>
                                            </div>

                                            <div class="col-12 py-2-">
                                                <hr class="u-line mt-2">
                                            </div>-->

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="col px-0">

                    <div class="bg-box-gray text-center p-1 h-100-">

                        <div class="d-flex- flex-column justify-content-center align-items-center">

                            <!--<div class="d-flex- align-items-end text-white bg-purple-gradient stats-box stats-box-booking mt-3 mb-3">

                                <span class="stats-figure">{{an_stats.thisWeekWebinar}}</span>

                            </div>-->

                            <div class="mt-4">

                                <h5 class="text-main font-ag-bold mb-1" style="font-size: 2rem;">Webinars</h5>

                            </div>

                        </div>

                    </div>

                    <div class="bg-box-gray text-center p-4 h-100-">
                        <div class="d-flex- flex-column justify-content-center align-items-center">
                            <div class="bg-white text-center p-2 h-100-">

                                <div class="d-flex- flex-column justify-content-center align-items-center-">


                                    <div class="col-12 week-courses-box h-auto">

                                        <div class="row">


                                            <div class="col-12 col-md-12 text-left">
                                                <a class="font-ag other-link">
                                                    <h5 class="font-weight-bold mb-0 mt-2 online-course-heading">
                                                        This month
                                                    </h5>
                                                </a>
                                            </div>
                                            <div class="col-6 col-md-12 text-left">
                                                Total: <span class="text-second font-ag-bold menu-sub-heading">{{an_stats.thisMonthWebinar}}</span>
                                            </div>

                                            <div class="col-6 col-md-12 text-left">
                                                Revenue: <span class="text-second font-ag-bold menu-sub-heading">&#163;{{an_stats.currentMonthWebinarRevenue | number : '1.2-2'}}</span>
                                            </div>


                                            <div class="col-12 py-2-">
                                                <hr class="u-line mt-2">
                                            </div>


                                            <div class="col-12 col-md-12 text-left">
                                                <a class="font-ag other-link">
                                                    <h5 class="font-weight-bold mb-0 mt-2 online-course-heading">
                                                        Previous month
                                                    </h5>
                                                </a>
                                            </div>
                                            <div class="col-6 col-md-12 text-left">
                                                Total: <span class="text-second font-ag-bold menu-sub-heading">{{an_stats.previousMonthWebinar}}</span>
                                            </div>

                                            <div class="col-6 col-md-12 text-left">
                                                Revenue: <span class="text-second font-ag-bold menu-sub-heading">&#163;{{an_stats.previousMonthWebinarRevenue | number : '1.2-2'}}</span>
                                            </div>


                                            <div class="col-12 py-2-">
                                                <hr class="u-line mt-2">
                                            </div>

                                            <!--<div class="mt-0 text-left">
                                                <h3 class="mb-1 mt-4 text-second font-ag-light fw-300" style="font-size: 2rem;">Revenue</h3>
                                            </div>
                                            <div class="col-12 col-md-12 text-left">
                                                <a class="font-ag other-link">
                                                    <h5 class="font-weight-bold mb-0 mt-2 online-course-heading">
                                                        {{an_stats.currentQuarter}}
                                                    </h5>
                                                </a>
                                            </div>
                                            <div class="col-6 col-md-12 text-left">
                                                <span class="text-second font-ag-bold menu-sub-heading">&#163;{{an_stats.currentQuarterWebinarRevenue | number : '1.2-2'}}</span>
                                            </div>

                                            <div class="col-12 py-2-">
                                                <hr class="u-line mt-2">
                                            </div>

                                            <div class="col-12 col-md-12 text-left">
                                                <a class="font-ag other-link">
                                                    <h5 class="font-weight-bold mb-0 mt-2 online-course-heading">
                                                        {{an_stats.previousQuarter}}
                                                    </h5>
                                                </a>
                                            </div>
                                            <div class="col-6 col-md-12 text-left">
                                                <span class="text-second font-ag-bold menu-sub-heading">&#163;{{an_stats.previousYearCurrentQuarterWebinarRevenue | number : '1.2-2'}}</span>
                                            </div>

                                            <div class="col-12 py-2-">
                                                <hr class="u-line mt-2">
                                            </div>-->

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="col px-0">

                    <div class="bg-light text-center p-1 h-100-">

                        <div class="d-flex- flex-column justify-content-center align-items-center">

                            <!--<div class="d-flex- align-items-end text-white bg-purple-gradient stats-box stats-box-booking mt-3 mb-3">

                                <span class="stats-figure">{{an_stats.thisWeekELearning}}</span>

                            </div>-->

                            <div class="mt-4">

                                <h5 class="text-main font-ag-bold mb-1" style="font-size: 2rem;">eLearning</h5>

                            </div>

                        </div>

                    </div>

                    <div class="bg-light text-center p-4 h-100-">
                        <div class="d-flex- flex-column justify-content-center align-items-center">
                            <div class="bg-white text-center p-2 h-100-">

                                <div class="d-flex- flex-column justify-content-center align-items-center-">


                                    <div class="col-12 week-courses-box h-auto">

                                        <div class="row">

                                            <div class="col-12 col-md-12 text-left">
                                                <a class="font-ag other-link">
                                                    <h5 class="font-weight-bold mb-0 mt-2 online-course-heading">
                                                        This month
                                                    </h5>
                                                </a>
                                            </div>
                                            <div class="col-6 col-md-12 text-left">
                                                Total: <span class="text-second font-ag-bold menu-sub-heading">{{an_stats.thisMonthELearning}}</span>
                                                <span class="text-second font-ag-bold f-licences"> ({{an_stats.thisMonthELearningLicence}})</span>
                                            </div>

                                            <div class="col-6 col-md-12 text-left">
                                                Revenue: <span class="text-second font-ag-bold menu-sub-heading">&#163;{{an_stats.currentMonthELearningRevenue | number : '1.2-2'}}</span>
                                            </div>


                                            <div class="col-12 py-2-">
                                                <hr class="u-line mt-2">
                                            </div>




                                            <div class="col-12 col-md-12 text-left">
                                                <a class="font-ag other-link">
                                                    <h5 class="font-weight-bold mb-0 mt-2 online-course-heading">
                                                        Previous month
                                                    </h5>
                                                </a>
                                            </div>
                                            <div class="col-6 col-md-12 text-left">
                                                Total: <span class="text-second font-ag-bold menu-sub-heading">{{an_stats.previousMonthELearning}}</span>
                                                <span class="text-second font-ag-bold f-licences"> ({{an_stats.previousMonthELearningLicence}})</span>
                                            </div>

                                            <div class="col-6 col-md-12 text-left">
                                                Revenue: <span class="text-second font-ag-bold menu-sub-heading">&#163;{{an_stats.previousMonthELearningRevenue | number : '1.2-2'}}</span>
                                            </div>


                                            <div class="col-12 py-2-">
                                                <hr class="u-line mt-2">
                                            </div>

                                            <!--<div class="mt-0 text-left">
                                                <h3 class="mb-1 mt-4 text-second font-ag-light fw-300" style="font-size: 2rem;">Revenue</h3>
                                            </div>
                                            <div class="col-12 col-md-12 text-left">
                                                <a class="font-ag other-link">
                                                    <h5 class="font-weight-bold mb-0 mt-2 online-course-heading">
                                                        {{an_stats.currentQuarter}}
                                                    </h5>
                                                </a>
                                            </div>
                                            <div class="col-6 col-md-12 text-left">
                                                <span class="text-second font-ag-bold menu-sub-heading">&#163;{{an_stats.currentQuarterELearningRevenue | number : '1.2-2'}}</span>
                                            </div>

                                            <div class="col-12 py-2-">
                                                <hr class="u-line mt-2">
                                            </div>

                                            <div class="col-12 col-md-12 text-left">
                                                <a class="font-ag other-link">
                                                    <h5 class="font-weight-bold mb-0 mt-2 online-course-heading">
                                                        {{an_stats.previousQuarter}}
                                                    </h5>
                                                </a>
                                            </div>
                                            <div class="col-6 col-md-12 text-left">
                                                <span class="text-second font-ag-bold menu-sub-heading">&#163;{{an_stats.previousYearCurrentQuarterELearningRevenue | number : '1.2-2'}}</span>
                                            </div>

                                            <div class="col-12 py-2-">
                                                <hr class="u-line mt-2">
                                            </div>-->

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="col px-0">

                    <div class="bg-box-gray text-center p-1 h-100-">

                        <div class="d-flex- flex-column justify-content-center align-items-center">

                            <!--<div class="d-flex- align-items-end text-white bg-purple-gradient stats-box stats-box-booking mt-3 mb-3">

                                <span class="stats-figure">{{an_stats.thisWeekProducts}}</span>

                            </div>-->

                            <div class="mt-4">

                                <h5 class="text-main font-ag-bold mb-1" style="font-size: 2rem;">Products</h5>

                            </div>

                        </div>

                    </div>

                    <div class="bg-box-gray text-center p-4 h-100-">
                        <div class="d-flex- flex-column justify-content-center align-items-center">
                            <div class="bg-white text-center p-2 h-100-">

                                <div class="d-flex- flex-column justify-content-center align-items-center-">


                                    <div class="col-12 week-courses-box h-auto">

                                        <div class="row">


                                            <div class="col-12 col-md-12 text-left">
                                                <a class="font-ag other-link">
                                                    <h5 class="font-weight-bold mb-0 mt-2 online-course-heading">
                                                        This month
                                                    </h5>
                                                </a>
                                            </div>
                                            <div class="col-6 col-md-12 text-left">
                                                Total: <span class="text-second font-ag-bold menu-sub-heading">{{an_stats.thisMonthProducts}}</span>
                                                <span class="text-second font-ag-bold f-licences"> ({{an_stats.thisMonthProductsLicence}})</span>
                                            </div>

                                            <div class="col-6 col-md-12 text-left">
                                                Revenue: <span class="text-second font-ag-bold menu-sub-heading">&#163;{{an_stats.currentMonthProductsRevenue | number : '1.2-2'}}</span>
                                            </div>


                                            <div class="col-12 py-2-">
                                                <hr class="u-line mt-2">
                                            </div>



                                            <div class="col-12 col-md-12 text-left">
                                                <a class="font-ag other-link">
                                                    <h5 class="font-weight-bold mb-0 mt-2 online-course-heading">
                                                        Previous month
                                                    </h5>
                                                </a>
                                            </div>
                                            <div class="col-6 col-md-12 text-left">
                                                Total: <span class="text-second font-ag-bold menu-sub-heading">{{an_stats.previousMonthProducts}}</span>
                                                <span class="text-second font-ag-bold f-licences"> ({{an_stats.previousMonthProductsLicence}})</span>
                                            </div>

                                            <div class="col-6 col-md-12 text-left">
                                                Revenue: <span class="text-second font-ag-bold menu-sub-heading">&#163;{{an_stats.previousMonthProductsRevenue | number : '1.2-2'}}</span>
                                            </div>


                                            <div class="col-12 py-2-">
                                                <hr class="u-line mt-2">
                                            </div>

                                            <!--<div class="mt-0 text-left">
                                                <h3 class="mb-1 mt-4 text-second font-ag-light fw-300" style="font-size: 2rem;">Revenue</h3>
                                            </div>
                                            <div class="col-12 col-md-12 text-left">
                                                <a class="font-ag other-link">
                                                    <h5 class="font-weight-bold mb-0 mt-2 online-course-heading">
                                                        {{an_stats.currentQuarter}}
                                                    </h5>
                                                </a>
                                            </div>
                                            <div class="col-6 col-md-12 text-left">
                                                <span class="text-second font-ag-bold menu-sub-heading">&#163;{{an_stats.currentQuarterProductsRevenue | number : '1.2-2'}}</span>
                                            </div>

                                            <div class="col-12 py-2-">
                                                <hr class="u-line mt-2">
                                            </div>

                                            <div class="col-12 col-md-12 text-left">
                                                <a class="font-ag other-link">
                                                    <h5 class="font-weight-bold mb-0 mt-2 online-course-heading">
                                                        {{an_stats.previousQuarter}}
                                                    </h5>
                                                </a>
                                            </div>
                                            <div class="col-6 col-md-12 text-left">
                                                <span class="text-second font-ag-bold menu-sub-heading">&#163;{{an_stats.previousYearCurrentQuarterProductsRevenue | number : '1.2-2'}}</span>
                                            </div>

                                            <div class="col-12 py-2-">
                                                <hr class="u-line mt-2">
                                            </div>-->

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="col px-0">

                    <div class="bg-light text-center p-1 h-100-">

                        <div class="d-flex- flex-column justify-content-center align-items-center">

                            <!--<div class="d-flex- align-items-end text-white bg-purple-gradient stats-box stats-box-booking mt-3 mb-3">

                                <span class="stats-figure">{{an_stats.thisWeekInhouseBookings}}</span>

                            </div>-->

                            <div class="mt-4">

                                <h5 class="text-main font-ag-bold mb-1" style="font-size: 2rem;">Inhouse</h5>

                            </div>

                        </div>

                    </div>

                    <div class="bg-light text-center p-4 h-100-">
                        <div class="d-flex- flex-column justify-content-center align-items-center">
                            <div class="bg-white text-center p-2 h-100-">

                                <div class="d-flex- flex-column justify-content-center align-items-center-">


                                    <div class="col-12 week-courses-box h-auto">

                                        <div class="row">


                                            <div class="col-12 col-md-12 text-left">
                                                <a class="font-ag other-link">
                                                    <h5 class="font-weight-bold mb-0 mt-2 online-course-heading">
                                                        This month
                                                    </h5>
                                                </a>
                                            </div>
                                            <div class="col-6 col-md-12 text-left">
                                                Total: <span class="text-second font-ag-bold menu-sub-heading">{{an_stats.thisMonthInhouse}}</span>
                                            </div>

                                            <div class="col-6 col-md-12 text-left">
                                                Revenue: <span class="text-second font-ag-bold menu-sub-heading">&#163;{{an_stats.currentMonthInHouseRevenue | number : '1.2-2'}}</span>
                                            </div>


                                            <div class="col-12 py-2-">
                                                <hr class="u-line mt-2">
                                            </div>


                                            <div class="col-12 col-md-12 text-left">
                                                <a class="font-ag other-link">
                                                    <h5 class="font-weight-bold mb-0 mt-2 online-course-heading">
                                                        Previous month
                                                    </h5>
                                                </a>
                                            </div>
                                            <div class="col-6 col-md-12 text-left">
                                                Total: <span class="text-second font-ag-bold menu-sub-heading">{{an_stats.previousMonthInhouse}}</span>
                                            </div>

                                            <div class="col-6 col-md-12 text-left">
                                                Revenue: <span class="text-second font-ag-bold menu-sub-heading">&#163;{{an_stats.previousMonthInhouseRevenue | number : '1.2-2'}}</span>
                                            </div>


                                            <div class="col-12 py-2-">
                                                <hr class="u-line mt-2">
                                            </div>

                                            <!--<div class="mt-0 text-left">
                                                <h3 class="mb-1 mt-4 text-second font-ag-light fw-300" style="font-size: 2rem;">Revenue</h3>
                                            </div>
                                            <div class="col-12 col-md-12 text-left">
                                                <a class="font-ag other-link">
                                                    <h5 class="font-weight-bold mb-0 mt-2 online-course-heading">
                                                        {{an_stats.currentQuarter}}
                                                    </h5>
                                                </a>
                                            </div>
                                            <div class="col-6 col-md-12 text-left">
                                                <span class="text-second font-ag-bold menu-sub-heading">&#163;{{an_stats.currentQuarterInhouseRevenue | number : '1.2-2'}}</span>
                                            </div>

                                            <div class="col-12 py-2-">
                                                <hr class="u-line mt-2">
                                            </div>

                                            <div class="col-12 col-md-12 text-left">
                                                <a class="font-ag other-link">
                                                    <h5 class="font-weight-bold mb-0 mt-2 online-course-heading">
                                                        {{an_stats.previousQuarter}}
                                                    </h5>
                                                </a>
                                            </div>
                                            <div class="col-6 col-md-12 text-left">
                                                <span class="text-second font-ag-bold menu-sub-heading">&#163;{{an_stats.previousYearCurrentQuarterInhouseRevenue | number : '1.2-2'}}</span>
                                            </div>

                                            <div class="col-12 py-2-">
                                                <hr class="u-line mt-2">
                                            </div>-->

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>


            </div>

        </section>

    </div>

    <div class="row">
        <section class="actnow-4-blocks my-4 mt-0">


            <div class="row pl-1 mr-booking-stats">
                <div class="col-12 col-md-3 px-0">
                    <div class="bg-red-gradient text-center left-stats-box pb-4 mb-1 booking-stats">
                        <span class="pull-left text-white font-ag-bold-" style="font-size: 1.5rem;">Current Month</span>
                        <h5 class="pull-right text-white font-ag-bold stats-figure-fs">&#163;{{an_stats.currentMonthInvoicesIssuedRevenue  | number : '1.2-2'}}</h5>
                    </div>
                </div>
                <div class="col-12 col-md-3 px-0">
                    <div class="bg-red-gradient text-center left-stats-box pb-4 mb-1 booking-stats">
                        <span class="pull-left text-white font-ag-bold-" style="font-size: 1.5rem;">Current Quarter</span>
                        <h5 class="pull-right text-white font-ag-bold stats-figure-fs">&#163;{{(an_stats.currentQuarterInvoicesIssuedRevenue + an_stats.currentQuarterInhouseRevenue)  | number : '1.2-2'}}</h5>
                    </div>
                </div>
                <div class="col-12 col-md-3 px-0">
                    <div class="bg-blue-gradient text-center left-stats-box pb-4 mb-1 booking-stats">
                        <span class="pull-left text-white font-ag-bold-" style="font-size: 1.5rem;">{{an_stats.previousMonth}}</span>
                        <h5 class="pull-right text-white font-ag-bold stats-figure-fs">&#163;{{an_stats.previousMonthRevenue  | number : '1.2-2'}}</h5>
                    </div>
                </div>
                <!--<div class="col-12 col-md-3 px-0">
                    <div class="bg-blue-gradient text-center left-stats-box pb-4 mb-1 booking-stats">
                        <span class="pull-left text-white font-ag-bold-" style="font-size: 1.5rem;">{{an_stats.previousYearCurrentMonth}}</span>
                        <h5 class="pull-right text-white font-ag-bold stats-figure-fs">&#163;{{an_stats.previousYearCurrentMonthInvoicesIssuedRevenue  | number : '1.2-2'}}</h5>
                    </div>
                </div>-->
                <div class="col-12 col-md-3 px-0">
                    <div class="bg-purple-gradient text-center left-stats-box pb-4 mb-1 booking-stats">
                        <span class="pull-left text-white font-ag-bold-" style="font-size: 1.5rem;">{{an_stats.previousQuarter}}</span>
                        <h5 class="pull-right text-white font-ag-bold stats-figure-fs">&#163;{{(an_stats.previousYearCurrentQuarterInvoicesIssuedRevenue + an_stats.previousYearCurrentQuarterInhouseRevenue)  | number : '1.2-2'}}</h5>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>

<div *ngIf="uRole=='Super Admin'">
    <h1 class="font-ag-light fw-400 text-second section-heading mb-4">Yearly Bookings</h1>
    <div class="row">
        <section class="actnow-4-blocks my-4 mt-0">
            <div class="row pl-1">
                <div class="col-12 col-md-3 px-0">
                    <div class="bg-purple-gradient text-center left-stats-box pb-4 mb-1">
                        <div class="d-flex- text-white align-items-end- stats-box-inner">
                            <span class="left-stats-figure">{{an_stats.thisYearBookings}}</span>
                        </div>
                        <div class="">
                            <h5 class="text-white font-ag-bold" style="font-size: 2rem;">Bookings</h5>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-md-3 px-0">
                    <div class="bg-blue-gradient text-center left-stats-box pb-4 mb-1">
                        <div class="d-flex- text-white align-items-end- stats-box-inner">
                            <span class="left-stats-figure">{{an_stats.currentYearELearning}}</span>
                            <span class="left-stats-figure stats-figure-fs"> ({{an_stats.thisYearELearningLicence}})</span>
                        </div>
                        <div class="">
                            <h5 class="text-white font-ag-bold" style="font-size: 2rem;">eLearning</h5>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-3 px-0">
                    <div class="bg-red-gradient text-center left-stats-box pb-4 mb-1">
                        <div class="d-flex- text-white align-items-end- stats-box-inner">
                            <span class="left-stats-figure">{{an_stats.thisYearProductBookings}}</span>
                            <span class="left-stats-figure stats-figure-fs"> ({{an_stats.thisYearProductsLicence}})</span>
                        </div>
                        <div class="">
                            <h5 class="text-white font-ag-bold" style="font-size: 2rem;">Products</h5>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-3 px-0">
                    <div class="bg-yellow-gradient text-center left-stats-box pb-4 mb-1">
                        <div class="d-flex- text-white align-items-end- stats-box-inner">
                            <span class="left-stats-figure">{{an_stats.thisYearInhouseBookings}}</span>
                        </div>
                        <div class="">
                            <h5 class="text-white font-ag-bold" style="font-size: 2rem;">Inhouse</h5>
                        </div>
                    </div>
                </div>

                <!--<div class="col-12 col-md-3 px-0">
                    <div class="bg-blue-gradient text-center left-stats-box pb-4 mb-1">
                        <div class="d-flex- text-white align-items-end- stats-box-inner">
                            <span class="stats-figure">&#163;</span>
                            <span class="left-stats-figure">{{an_stats.thisYearRevenue  | number : '1.2-2'}}</span>
                        </div>
                        <div class="">
                            <h5 class="text-white font-ag-bold" style="font-size: 2rem;">Revenue</h5>
                        </div>
                    </div>
                </div>-->
            </div>
        </section>
    </div>




    <div class="row">
        <section class="actnow-4-blocks my-4 mt-0">
            <div class="row pl-1">
                <div class="col px-0">

                    <div class="bg-light text-center p-1 h-100-">
                        <div class="d-flex- flex-column justify-content-center align-items-center">
                            <!--<div class="d-flex- align-items-end text-white bg-purple-gradient stats-box stats-box-booking mt-3 mb-3">
                                <span class="stats-figure">{{an_stats.currentYearFullDayBookings}}</span>
                            </div>-->
                            <div class="mt-4">
                                <h5 class="text-main font-ag-bold mb-1" style="font-size: 2rem;">Full Day</h5>
                            </div>
                        </div>
                    </div>
                    <div class="bg-light text-center p-4 h-100-">
                        <div class="d-flex- flex-column justify-content-center align-items-center">
                            <div class="bg-white text-center p-2 h-100-">
                                <div class="d-flex- flex-column justify-content-center align-items-center-">
                                    <div class="col-12 week-courses-box h-auto">
                                        <div class="row">
                                            <div class="col-12 col-md-12 text-left">
                                                <a class="font-ag other-link">
                                                    <h5 class="font-weight-bold mb-0 mt-2 online-course-heading">
                                                        This year
                                                    </h5>
                                                </a>
                                            </div>
                                            <div class="col-6 col-md-12 text-left">
                                                Total: <span class="text-second font-ag-bold menu-sub-heading">{{an_stats.currentYearFullDayBookings}}</span>
                                            </div>
                                            <div class="col-12 py-2-">
                                                <hr class="u-line mt-2">
                                            </div>
                                            <div class="col-12 col-md-12 text-left">
                                                <a class="font-ag other-link">
                                                    <h5 class="font-weight-bold mb-0 mt-2 online-course-heading">
                                                        Previous year
                                                    </h5>
                                                </a>
                                            </div>
                                            <div class="col-6 col-md-12 text-left">
                                                Total: <span class="text-second font-ag-bold menu-sub-heading">{{an_stats.previousYearFullDayBookings}}</span>
                                            </div>
                                            <div class="col-12 py-2-">
                                                <hr class="u-line mt-2">
                                            </div>
                                            <div class="mt-0 text-left">
                                                <h3 class="mb-1 mt-4 text-second font-ag-light fw-300" style="font-size: 2rem;">Revenue</h3>
                                            </div>
                                            <div class="col-12 col-md-12 text-left">
                                                <a class="font-ag other-link">
                                                    <h5 class="font-weight-bold mb-0 mt-2 online-course-heading">
                                                        This year
                                                    </h5>
                                                </a>
                                            </div>
                                            <div class="col-6 col-md-12 text-left">
                                                <span class="text-second font-ag-bold menu-sub-heading">&#163;{{an_stats.currentYearFullDayRevenue  | number : '1.2-2'}}</span>
                                            </div>
                                            <div class="col-12 py-2-">
                                                <hr class="u-line mt-2">
                                            </div>
                                            <div class="col-12 col-md-12 text-left">
                                                <a class="font-ag other-link">
                                                    <h5 class="font-weight-bold mb-0 mt-2 online-course-heading">
                                                        Previous year
                                                    </h5>
                                                </a>
                                            </div>
                                            <div class="col-6 col-md-12 text-left">
                                                <span class="text-second font-ag-bold menu-sub-heading">&#163;{{an_stats.previousYearFullDayRevenue  | number : '1.2-2'}}</span>
                                            </div>
                                            <div class="col-12 py-2-">
                                                <hr class="u-line mt-2">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col px-0">
                    <div class="bg-box-gray text-center p-1 h-100-">
                        <div class="d-flex- flex-column justify-content-center align-items-center">
                            <!--<div class="d-flex- align-items-end text-white bg-purple-gradient stats-box stats-box-booking mt-3 mb-3">
                                <span class="stats-figure">{{an_stats.currentYearCertificateBookings}}</span>
                            </div>-->
                            <div class="mt-4">
                                <h5 class="text-main font-ag-bold mb-1" style="font-size: 2rem;">Certificates</h5>
                            </div>
                        </div>
                    </div>
                    <div class="bg-box-gray text-center p-4 h-100-">
                        <div class="d-flex- flex-column justify-content-center align-items-center">
                            <div class="bg-white text-center p-2 h-100-">
                                <div class="d-flex- flex-column justify-content-center align-items-center-">
                                    <div class="col-12 week-courses-box h-auto">
                                        <div class="row">
                                            <div class="col-12 col-md-12 text-left">
                                                <a class="font-ag other-link">
                                                    <h5 class="font-weight-bold mb-0 mt-2 online-course-heading">
                                                        This year
                                                    </h5>
                                                </a>
                                            </div>
                                            <div class="col-6 col-md-12 text-left">
                                                Total: <span class="text-second font-ag-bold menu-sub-heading">{{an_stats.currentYearCertificateBookings}}</span>
                                            </div>
                                            <div class="col-12 py-2-">
                                                <hr class="u-line mt-2">
                                            </div>
                                            <div class="col-12 col-md-12 text-left">
                                                <a class="font-ag other-link">
                                                    <h5 class="font-weight-bold mb-0 mt-2 online-course-heading">
                                                        Previous year
                                                    </h5>
                                                </a>
                                            </div>
                                            <div class="col-6 col-md-12 text-left">
                                                Total: <span class="text-second font-ag-bold menu-sub-heading">{{an_stats.previousYearCertificateBookings}}</span>
                                            </div>
                                            <div class="col-12 py-2-">
                                                <hr class="u-line mt-2">
                                            </div>
                                            <div class="mt-0 text-left">
                                                <h3 class="mb-1 mt-4 text-second font-ag-light fw-300" style="font-size: 2rem;">Revenue</h3>
                                            </div>
                                            <div class="col-12 col-md-12 text-left">
                                                <a class="font-ag other-link">
                                                    <h5 class="font-weight-bold mb-0 mt-2 online-course-heading">
                                                        This year
                                                    </h5>
                                                </a>
                                            </div>
                                            <div class="col-6 col-md-12 text-left">
                                                <span class="text-second font-ag-bold menu-sub-heading">&#163;{{an_stats.currentYearCertificateRevenue  | number : '1.2-2'}}</span>
                                            </div>
                                            <div class="col-12 py-2-">
                                                <hr class="u-line mt-2">
                                            </div>
                                            <div class="col-12 col-md-12 text-left">
                                                <a class="font-ag other-link">
                                                    <h5 class="font-weight-bold mb-0 mt-2 online-course-heading">
                                                        Previous year
                                                    </h5>
                                                </a>
                                            </div>
                                            <div class="col-6 col-md-12 text-left">
                                                <span class="text-second font-ag-bold menu-sub-heading">&#163;{{an_stats.previousYearCertificateRevenue  | number : '1.2-2'}}</span>
                                            </div>
                                            <div class="col-12 py-2-">
                                                <hr class="u-line mt-2">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col px-0">
                    <div class="bg-box-gray text-center p-1 h-100-">
                        <div class="d-flex- flex-column justify-content-center align-items-center">
                            <!--<div class="d-flex- align-items-end text-white bg-purple-gradient stats-box stats-box-booking mt-3 mb-3">
                                <span class="stats-figure">{{an_stats.currentYearCertificateBookings}}</span>
                            </div>-->
                            <div class="mt-4">
                                <h5 class="text-main font-ag-bold mb-1" style="font-size: 2rem;">Webinar</h5>
                            </div>
                        </div>
                    </div>
                    <div class="bg-box-gray text-center p-4 h-100-">
                        <div class="d-flex- flex-column justify-content-center align-items-center">
                            <div class="bg-white text-center p-2 h-100-">
                                <div class="d-flex- flex-column justify-content-center align-items-center-">
                                    <div class="col-12 week-courses-box h-auto">
                                        <div class="row">
                                            <div class="col-12 col-md-12 text-left">
                                                <a class="font-ag other-link">
                                                    <h5 class="font-weight-bold mb-0 mt-2 online-course-heading">
                                                        This year
                                                    </h5>
                                                </a>
                                            </div>
                                            <div class="col-6 col-md-12 text-left">
                                                Total: <span class="text-second font-ag-bold menu-sub-heading">{{an_stats.currentYearWebinar}}</span>
                                            </div>
                                            <div class="col-12 py-2-">
                                                <hr class="u-line mt-2">
                                            </div>
                                            <div class="col-12 col-md-12 text-left">
                                                <a class="font-ag other-link">
                                                    <h5 class="font-weight-bold mb-0 mt-2 online-course-heading">
                                                        Previous year
                                                    </h5>
                                                </a>
                                            </div>
                                            <div class="col-6 col-md-12 text-left">
                                                Total: <span class="text-second font-ag-bold menu-sub-heading">{{an_stats.previousYearWebinar}}</span>
                                            </div>
                                            <div class="col-12 py-2-">
                                                <hr class="u-line mt-2">
                                            </div>
                                            <div class="mt-0 text-left">
                                                <h3 class="mb-1 mt-4 text-second font-ag-light fw-300" style="font-size: 2rem;">Revenue</h3>
                                            </div>
                                            <div class="col-12 col-md-12 text-left">
                                                <a class="font-ag other-link">
                                                    <h5 class="font-weight-bold mb-0 mt-2 online-course-heading">
                                                        This year
                                                    </h5>
                                                </a>
                                            </div>
                                            <div class="col-6 col-md-12 text-left">
                                                <span class="text-second font-ag-bold menu-sub-heading">&#163;{{an_stats.currentYearWebinarRevenue  | number : '1.2-2'}}</span>
                                            </div>
                                            <div class="col-12 py-2-">
                                                <hr class="u-line mt-2">
                                            </div>
                                            <div class="col-12 col-md-12 text-left">
                                                <a class="font-ag other-link">
                                                    <h5 class="font-weight-bold mb-0 mt-2 online-course-heading">
                                                        Previous year
                                                    </h5>
                                                </a>
                                            </div>
                                            <div class="col-6 col-md-12 text-left">
                                                <span class="text-second font-ag-bold menu-sub-heading">&#163;{{an_stats.previousYearWebinarRevenue  | number : '1.2-2'}}</span>
                                            </div>
                                            <div class="col-12 py-2-">
                                                <hr class="u-line mt-2">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col px-0">
                    <div class="bg-light text-center p-1 h-100-">
                        <div class="d-flex- flex-column justify-content-center align-items-center">
                            <!--<div class="d-flex- align-items-end text-white bg-purple-gradient stats-box stats-box-booking mt-3 mb-3">
                                <span class="stats-figure">{{an_stats.currentYear_ELearning}}</span>
                            </div>-->
                            <div class="mt-4">
                                <h5 class="text-main font-ag-bold mb-1" style="font-size: 2rem;">eLearning</h5>
                            </div>
                        </div>
                    </div>
                    <div class="bg-light text-center p-4 h-100-">
                        <div class="d-flex- flex-column justify-content-center align-items-center">
                            <div class="bg-white text-center p-2 h-100-">
                                <div class="d-flex- flex-column justify-content-center align-items-center-">
                                    <div class="col-12 week-courses-box h-auto">
                                        <div class="row">
                                            <div class="col-12 col-md-12 text-left">
                                                <a class="font-ag other-link">
                                                    <h5 class="font-weight-bold mb-0 mt-2 online-course-heading">
                                                        This year
                                                    </h5>
                                                </a>
                                            </div>
                                            <div class="col-6 col-md-12 text-left">
                                                Total: <span class="text-second font-ag-bold menu-sub-heading">{{an_stats.currentYear_ELearning}}</span>
                                                <span class="text-second font-ag-bold menu-sub-heading f-licences"> ({{an_stats.thisYearELearningLicence}})</span>
                                            </div>
                                            <div class="col-12 py-2-">
                                                <hr class="u-line mt-2">
                                            </div>
                                            <div class="col-12 col-md-12 text-left">
                                                <a class="font-ag other-link">
                                                    <h5 class="font-weight-bold mb-0 mt-2 online-course-heading">
                                                        Previous year
                                                    </h5>
                                                </a>
                                            </div>
                                            <div class="col-6 col-md-12 text-left">
                                                Total: <span class="text-second font-ag-bold menu-sub-heading">{{an_stats.previousYear_ELearning}}</span>
                                                <span class="text-second font-ag-bold menu-sub-heading f-licences"> ({{an_stats.previousYearELearningLicence}})</span>
                                            </div>
                                            <div class="col-12 py-2-">
                                                <hr class="u-line mt-2">
                                            </div>
                                            <div class="mt-0 text-left">
                                                <h3 class="mb-1 mt-4 text-second font-ag-light fw-300" style="font-size: 2rem;">Revenue</h3>
                                            </div>
                                            <div class="col-12 col-md-12 text-left">
                                                <a class="font-ag other-link">
                                                    <h5 class="font-weight-bold mb-0 mt-2 online-course-heading">
                                                        This year
                                                    </h5>
                                                </a>
                                            </div>
                                            <div class="col-6 col-md-12 text-left">
                                                <span class="text-second font-ag-bold menu-sub-heading">&#163;{{an_stats.currentYearELearningRevenue}}</span>
                                            </div>
                                            <div class="col-12 py-2-">
                                                <hr class="u-line mt-2">
                                            </div>
                                            <div class="col-12 col-md-12 text-left">
                                                <a class="font-ag other-link">
                                                    <h5 class="font-weight-bold mb-0 mt-2 online-course-heading">
                                                        Previous year
                                                    </h5>
                                                </a>
                                            </div>
                                            <div class="col-6 col-md-12 text-left">
                                                <span class="text-second font-ag-bold menu-sub-heading">&#163;{{an_stats.previousYearELearningRevenue}}</span>
                                            </div>
                                            <div class="col-12 py-2-">
                                                <hr class="u-line mt-2">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col px-0">
                    <div class="bg-box-gray text-center p-1 h-100-">
                        <div class="d-flex- flex-column justify-content-center align-items-center">
                            <!--<div class="d-flex- align-items-end text-white bg-purple-gradient stats-box stats-box-booking mt-3 mb-3">
                                <span class="stats-figure">{{an_stats.thisYearProductBookings}}</span>
                            </div>-->
                            <div class="mt-4">
                                <h5 class="text-main font-ag-bold mb-1" style="font-size: 2rem;">Products</h5>
                            </div>
                        </div>
                    </div>
                    <div class="bg-box-gray text-center p-4 h-100-">
                        <div class="d-flex- flex-column justify-content-center align-items-center">
                            <div class="bg-white text-center p-2 h-100-">
                                <div class="d-flex- flex-column justify-content-center align-items-center-">
                                    <div class="col-12 week-courses-box h-auto">
                                        <div class="row">
                                            <div class="col-12 col-md-12 text-left">
                                                <a class="font-ag other-link">
                                                    <h5 class="font-weight-bold mb-0 mt-2 online-course-heading">
                                                        This year
                                                    </h5>
                                                </a>
                                            </div>
                                            <div class="col-6 col-md-12 text-left">
                                                Total: <span class="text-second font-ag-bold menu-sub-heading">{{an_stats.thisYearProductBookings}}</span>
                                                <span class="text-second font-ag-bold menu-sub-heading f-licences"> ({{an_stats.thisYearProductsLicence}})</span>
                                            </div>
                                            <div class="col-12 py-2-">
                                                <hr class="u-line mt-2">
                                            </div>
                                            <div class="col-12 col-md-12 text-left">
                                                <a class="font-ag other-link">
                                                    <h5 class="font-weight-bold mb-0 mt-2 online-course-heading">
                                                        Previous year
                                                    </h5>
                                                </a>
                                            </div>
                                            <div class="col-6 col-md-12 text-left">
                                                Total: <span class="text-second font-ag-bold menu-sub-heading">{{an_stats.previousYarProductBookings}}</span>
                                                <span class="text-second font-ag-bold menu-sub-heading f-licences"> ({{an_stats.previousYearProductsLicence}})</span>
                                            </div>
                                            <div class="col-12 py-2-">
                                                <hr class="u-line mt-2">
                                            </div>
                                            <div class="mt-0 text-left">
                                                <h3 class="mb-1 mt-4 text-second font-ag-light fw-300" style="font-size: 2rem;">Revenue</h3>
                                            </div>
                                            <div class="col-12 col-md-12 text-left">
                                                <a class="font-ag other-link">
                                                    <h5 class="font-weight-bold mb-0 mt-2 online-course-heading">
                                                        This year
                                                    </h5>
                                                </a>
                                            </div>
                                            <div class="col-6 col-md-12 text-left">
                                                <span class="text-second font-ag-bold menu-sub-heading">&#163;{{an_stats.currentYearProductsRevenue  | number : '1.2-2'}}</span>
                                            </div>
                                            <div class="col-12 py-2-">
                                                <hr class="u-line mt-2">
                                            </div>
                                            <div class="col-12 col-md-12 text-left">
                                                <a class="font-ag other-link">
                                                    <h5 class="font-weight-bold mb-0 mt-2 online-course-heading">
                                                        Previous year
                                                    </h5>
                                                </a>
                                            </div>
                                            <div class="col-6 col-md-12 text-left">
                                                <span class="text-second font-ag-bold menu-sub-heading">&#163;{{an_stats.previousYearProductsRevenue  | number : '1.2-2'}}</span>
                                            </div>
                                            <div class="col-12 py-2-">
                                                <hr class="u-line mt-2">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col px-0">
                    <div class="bg-light text-center p-1 h-100-">
                        <div class="d-flex- flex-column justify-content-center align-items-center">
                            <!--<div class="d-flex- align-items-end text-white bg-purple-gradient stats-box stats-box-booking mt-3 mb-3">
                                <span class="stats-figure">{{an_stats.thisYearInhouseBookings}}</span>
                            </div>-->
                            <div class="mt-4">
                                <h5 class="text-main font-ag-bold mb-1" style="font-size: 2rem;">Inhouse</h5>
                            </div>
                        </div>
                    </div>
                    <div class="bg-light text-center p-4 h-100-">
                        <div class="d-flex- flex-column justify-content-center align-items-center">
                            <div class="bg-white text-center p-2 h-100-">
                                <div class="d-flex- flex-column justify-content-center align-items-center-">
                                    <div class="col-12 week-courses-box h-auto">
                                        <div class="row">
                                            <div class="col-12 col-md-12 text-left">
                                                <a class="font-ag other-link">
                                                    <h5 class="font-weight-bold mb-0 mt-2 online-course-heading">
                                                        This year
                                                    </h5>
                                                </a>
                                            </div>
                                            <div class="col-6 col-md-12 text-left">
                                                Total: <span class="text-second font-ag-bold menu-sub-heading">{{an_stats.thisYearInhouseBookings}}</span>
                                            </div>
                                            <div class="col-12 py-2-">
                                                <hr class="u-line mt-2">
                                            </div>
                                            <div class="col-12 col-md-12 text-left">
                                                <a class="font-ag other-link">
                                                    <h5 class="font-weight-bold mb-0 mt-2 online-course-heading">
                                                        Previous year
                                                    </h5>
                                                </a>
                                            </div>
                                            <div class="col-6 col-md-12 text-left">
                                                Total: <span class="text-second font-ag-bold menu-sub-heading">{{an_stats.previousYearInhouseBookings}}</span>
                                            </div>
                                            <div class="col-12 py-2-">
                                                <hr class="u-line mt-2">
                                            </div>
                                            <div class="mt-0 text-left">
                                                <h3 class="mb-1 mt-4 text-second font-ag-light fw-300" style="font-size: 2rem;">Revenue</h3>
                                            </div>
                                            <div class="col-12 col-md-12 text-left">
                                                <a class="font-ag other-link">
                                                    <h5 class="font-weight-bold mb-0 mt-2 online-course-heading">
                                                        This year
                                                    </h5>
                                                </a>
                                            </div>
                                            <div class="col-6 col-md-12 text-left">
                                                <span class="text-second font-ag-bold menu-sub-heading">&#163;{{an_stats.currentYearInhouseRevenue  | number : '1.2-2'}}</span>
                                            </div>
                                            <div class="col-12 py-2-">
                                                <hr class="u-line mt-2">
                                            </div>
                                            <div class="col-12 col-md-12 text-left">
                                                <a class="font-ag other-link">
                                                    <h5 class="font-weight-bold mb-0 mt-2 online-course-heading">
                                                        Previous year
                                                    </h5>
                                                </a>
                                            </div>
                                            <div class="col-6 col-md-12 text-left">
                                                <span class="text-second font-ag-bold menu-sub-heading">&#163;{{an_stats.previousYearInhouseRevenue  | number : '1.2-2'}}</span>
                                            </div>
                                            <div class="col-12 py-2-">
                                                <hr class="u-line mt-2">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <div class="row">
        <section class="actnow-4-blocks my-4 mt-0">
            <div class="row pl-1 mr-booking-stats">
                <div class="col-12 col-md-3 px-0">
                    <div class="bg-pink-gradient text-center left-stats-box pb-4 mb-1 booking-stats">
                        <span class="pull-left text-white font-ag-bold-" style="font-size: 1.5rem;">Current Year</span>
                        <h5 class="pull-right text-white font-ag-bold stats-figure-fs">&#163;{{an_stats.thisYearRevenue  | number : '1.2-2'}}</h5>
                    </div>
                </div>
                <div class="col-12 col-md-3 px-0">
                    <div class="bg-purple-gradient text-center left-stats-box pb-4 mb-1 booking-stats">
                        <span class="pull-left text-white font-ag-bold-" style="font-size: 1.5rem;">Previous Year</span>
                        <h5 class="pull-right text-white font-ag-bold stats-figure-fs">&#163;{{an_stats.previousYearInvoicesRevenue  | number : '1.2-2'}}</h5>
                    </div>
                </div>
                <div class="col-12 col-md-3 px-0">
                    <div class="bg-red-gradient text-center left-stats-box pb-4 mb-1 booking-stats">
                        <span class="pull-left text-white font-ag-bold-" style="font-size: 1.5rem;">Debt</span>
                        <h5 class="pull-right text-white font-ag-bold stats-figure-fs">
                            &#163;{{an_stats.totalDebt | number}}
                            <span style="font-size: 22px;">
                                ({{an_stats.totalDebtInvoicesCount}})
                            </span>
                        </h5>
                    </div>
                </div>
                <div class="col-12 col-md-3 px-0">
                    <div class="bg-red-gradient text-center left-stats-box pb-4 mb-1 booking-stats">
                        <span class="pull-left text-white font-ag-bold-" style="font-size: 1.5rem;">Debt >63d</span>
                        <h5 class="pull-right text-white font-ag-bold stats-figure-fs">
                            &#163;{{an_stats.debtOver63Days | number}}
                            <span style="font-size: 22px;">
                                ({{an_stats.debtOver63DaysInvoicesCount}})
                            </span>
                        </h5>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>
<div *ngIf="uRole=='Super Admin'">
    <h1 class="font-ag-light fw-400 text-second section-heading mb-4">Last 3 Year Analysis</h1>
    <div class="row">
        <section class="actnow-4-blocks my-4 mt-0">
            <div class="container-fluid py-3">
                <div class="row mt-5">
                    <div class="col-12 col-md-4">
                        <div id="chart-gauge"></div>
                    </div>
                    <div class="col-12 col-md-4">
                        <div id="chart-pie"></div>
                    </div>
                    <div class="col-12 col-md-4">
                        <div id="chart-column"></div>
                    </div>
                    <div class="col-12 col-md-4">
                        <div id="chart-line"></div>
                    </div>
                    <div class="col-12 col-md-4">
                        <div id="chart-1"></div>
                    </div>
                    <div class="col-12 col-md-4">
                        <div id="chart-2"></div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>
<ng-template #modalContentDetails let-close="close">
    <div class="modal-header primary">
        <h5 class="modal-title primary">Booking Details</h5>
        <!--<button type="button" class="close" (click)="close()">
            <span aria-hidden="true">&times;</span>
        </button>-->
    </div>
    <div class="modal-body- p-4">



        <mat-accordion class="example-headers-align">
            <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" (closed)="setStep_closed()" hideToggle>

                <mat-expansion-panel-header>
                    <mat-panel-title>
                        DELEGATE DETAILS
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <h6 class="text-gray font-ag">
                    <div class="row">
                        <div class="col-md-2">
                            Delegate:
                        </div>
                        <div class="col-md-10">
                            <span class="text-second font-ag-bold">
                                {{getValue(_booking.name)}}
                            </span>
                        </div>
                    </div>
                </h6>

                <h6 class="text-gray font-ag">
                    <div class="row">
                        <div class="col-md-2">
                            Organisation:
                        </div>

                        <div class="col-md-10">
                            <span class="text-second font-ag-bold">
                                {{getValue(_booking.organization)}}
                            </span>
                        </div>
                    </div>
                </h6>

                <h6 class="text-gray font-ag">
                    <div class="row">
                        <div class="col-md-2">
                            Department:
                        </div>

                        <div class="col-md-10">
                            <span class="text-second font-ag-bold">
                                {{getValue(_booking.department)}}
                            </span>
                        </div>
                    </div>
                </h6>

                <h6 class="text-gray font-ag">
                    <div class="row">
                        <div class="col-md-2">
                            Address:
                        </div>

                        <div class="col-md-10">
                            <span class="text-second font-ag-bold">
                                {{getValue(_booking.address)}}
                            </span>
                        </div>
                    </div>
                </h6>

                <h6 class="text-gray font-ag">
                    <div class="row">
                        <div class="col-md-2">
                            City:
                        </div>

                        <div class="col-md-10">
                            <span class="text-second font-ag-bold">
                                {{getValue(_booking.cityName)}}
                            </span>
                        </div>
                    </div>
                </h6>
                <h6 class="text-gray font-ag">
                    <div class="row">
                        <div class="col-md-2">
                            Postcode:
                        </div>

                        <div class="col-md-10">
                            <span class="text-second font-ag-bold">
                                {{getValue(_booking.postcode)}}
                            </span>
                        </div>
                    </div>
                </h6>

                <h6 class="text-gray font-ag">
                    <div class="row">
                        <div class="col-md-2">
                            Telephone:
                        </div>

                        <div class="col-md-10">
                            <span class="text-second font-ag-bold">
                                {{getValue(_booking.telephone)}}
                            </span>
                        </div>
                    </div>
                </h6>

                <h6 class="text-gray font-ag">
                    <div class="row">
                        <div class="col-md-2">
                            Email:
                        </div>

                        <div class="col-md-10">
                            <span class="text-second font-ag-bold">
                                {{getValue(_booking.email)}}
                            </span>
                        </div>
                    </div>
                </h6>


                <mat-action-row>
                    <a mat-button color="primary" class="book-btn" (click)="nextStep()">Next</a>
                </mat-action-row>
            </mat-expansion-panel>

            <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)" (closed)="setStep_closed()" hideToggle>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        COURSE DETAILS
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <h6 class="text-gray font-ag">
                    <div class="row">
                        <div class="col-md-2">
                            Title:
                        </div>

                        <div class="col-md-10">
                            <span class="text-second font-ag-bold">
                                {{getValue(_booking.courseName)}}
                            </span>
                        </div>
                    </div>
                </h6>

                <h6 class="text-gray font-ag">
                    <div class="row">
                        <div class="col-md-2">
                            Location:
                        </div>

                        <div class="col-md-10">
                            <span class="text-second font-ag-bold">
                                {{getValue(_booking.courseLocation)}}
                            </span>
                        </div>
                    </div>
                </h6>
                <h6 class="text-gray font-ag">
                    <div class="row">
                        <div class="col-md-2">
                            Date:
                        </div>

                        <div class="col-md-10">
                            <span class="text-second font-ag-bold">
                                {{getValue(_booking.courseDate | date:'dd-MM-YYYY')}}
                            </span>
                        </div>
                    </div>
                </h6>

                <h6 class="text-gray font-ag">
                    <div class="row">
                        <div class="col-md-2">
                            Cost:
                        </div>

                        <div class="col-md-10">
                            <span class="text-second font-ag-bold">
                                {{getValue(_booking.courseCost)}}
                            </span>
                        </div>
                    </div>
                </h6>

                <h6 class="text-gray font-ag">
                    <div class="row">
                        <div class="col-md-2">
                            Course Type:
                        </div>

                        <div class="col-md-10">
                            <span class="text-second font-ag-bold">
                                {{getValue(getCourseType(_booking.courseType))}}
                            </span>
                        </div>
                    </div>
                </h6>
                <mat-action-row>
                    <a mat-button color="warn" class="book-btn mr-1" (click)="prevStep()">Previous</a>
                    <a mat-button color="primary" class="book-btn" (click)="nextStep()">Next</a>
                </mat-action-row>
            </mat-expansion-panel>

            <mat-expansion-panel [expanded]="step === 2" (opened)="setStep(2)" (closed)="setStep_closed()" hideToggle>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        INVOICE DETAILS
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <h6 class="text-gray font-ag">
                    <div class="row">
                        <div class="col-md-2">
                            Name:
                        </div>
                        <div class="col-md-10">
                            <span class="text-second font-ag-bold">
                                {{getValue(_booking.invoiceName)}}
                            </span>
                        </div>
                    </div>
                </h6>

                <h6 class="text-gray font-ag">
                    <div class="row">
                        <div class="col-md-2">
                            Organisation:
                        </div>
                        <div class="col-md-10">
                            <span class="text-second font-ag-bold">
                                {{getValue(_booking.invoiceOrganization)}}
                            </span>
                        </div>
                    </div>
                </h6>

                <h6 class="text-gray font-ag">
                    <div class="row">
                        <div class="col-md-2">
                            Department:
                        </div>
                        <div class="col-md-10">
                            <span class="text-second font-ag-bold">
                                {{getValue(_booking.invoiceDepartment)}}
                            </span>
                        </div>
                    </div>
                </h6>

                <h6 class="text-gray font-ag">
                    <div class="row">
                        <div class="col-md-2">
                            Email:
                        </div>
                        <div class="col-md-10">
                            <span class="text-second font-ag-bold">
                                {{getValue(_booking.invoiceEmail)}}
                            </span>
                        </div>
                    </div>
                </h6>

                <h6 class="text-gray font-ag">
                    <div class="row">
                        <div class="col-md-2">
                            Telephone:
                        </div>
                        <div class="col-md-10">
                            <span class="text-second font-ag-bold">
                                {{getValue(_booking.invoiceTelephone)}}
                            </span>
                        </div>
                    </div>
                </h6>

                <h6 class="text-gray font-ag">
                    <div class="row">
                        <div class="col-md-2">
                            Address:
                        </div>
                        <div class="col-md-10">
                            <span class="text-second font-ag-bold">
                                {{getValue(_booking.invoiceAddress)}}
                            </span>
                        </div>
                    </div>
                </h6>

                <h6 class="text-gray font-ag">
                    <div class="row">
                        <div class="col-md-2">
                            City:
                        </div>
                        <div class="col-md-10">
                            <span class="text-second font-ag-bold">
                                {{getValue(_booking.invoiceCity)}}
                            </span>
                        </div>
                    </div>
                </h6>

                <h6 class="text-gray font-ag">
                    <div class="row">
                        <div class="col-md-2">
                            Postcode:
                        </div>
                        <div class="col-md-10">
                            <span class="text-second font-ag-bold">
                                {{getValue(_booking.invoicePostcode)}}
                            </span>
                        </div>
                    </div>
                </h6>

                <h6 class="text-gray font-ag">
                    <div class="row">
                        <div class="col-md-2">
                            PromoCode:
                        </div>
                        <div class="col-md-10">
                            <span class="text-second font-ag-bold">
                                {{getValue(_booking.invoicePromoCode)}}
                            </span>
                        </div>
                    </div>
                </h6>

                <h6 class="text-gray font-ag">
                    <div class="row">
                        <div class="col-md-2">
                            PO Number:
                        </div>
                        <div class="col-md-10">
                            <span class="text-second font-ag-bold">
                                {{getValue(_booking.invoicePurchaseOrder)}}
                            </span>
                        </div>
                    </div>
                </h6>



                <mat-action-row>
                    <a mat-button color="warn" class="book-btn mr-1" (click)="prevStep()">Previous</a>
                    <a mat-button color="primary" class="book-btn" (click)="nextStep()">Next</a>
                </mat-action-row>
            </mat-expansion-panel>

            <mat-expansion-panel [expanded]="step === 3" (opened)="setStep(3)" (closed)="setStep_closed()" hideToggle>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        BOOKER DETAILS
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <h6 class="text-gray font-ag">
                    <div class="row">
                        <div class="col-md-2">
                            Name:
                        </div>

                        <div class="col-md-10">
                            <span class="text-second font-ag-bold">
                                {{getValue(_booking.bookerName)}}
                            </span>
                        </div>
                    </div>
                </h6>

                <h6 class="text-gray font-ag">
                    <div class="row">
                        <div class="col-md-2">
                            Email:
                        </div>

                        <div class="col-md-10">
                            <span class="text-second font-ag-bold">
                                {{getValue(_booking.bookerEmail)}}
                            </span>
                        </div>
                    </div>
                </h6>

                <h6 class="text-gray font-ag">
                    <div class="row">
                        <div class="col-md-2">
                            Telephone:
                        </div>

                        <div class="col-md-10">
                            <span class="text-second font-ag-bold">
                                {{getValue(_booking.bookerTelephone)}}
                            </span>
                        </div>
                    </div>
                </h6>
                <mat-action-row>
                    <a mat-button color="warn" class="book-btn mr-1" (click)="prevStep()">Previous</a>
                    <!--<a mat-button color="primary" class="book-btn" (click)="nextStep()">Next</a>-->
                </mat-action-row>
            </mat-expansion-panel>
        </mat-accordion>

    </div>
    <div class="modal-footer">
        <button type="button" class="book-btn" (click)="approve(_booking)">
            Approve
        </button>
        <button type="button" class="book-btn" (click)="close()">
            Close
        </button>
    </div>
</ng-template>


<ng-template #modalCourseDetails let-close="close">
    <div class="modal-header primary">
        <h5 class="modal-title primary">Course Details</h5>
    </div>

    <div class="modal-body- p-4">



        <h6 class="text-gray font-ag">
            <div class="row">
                <div class="col-md-3">
                    Course title:
                </div>

                <div class="col-md-9">
                    <span class="text-second font-ag-bold">
                        {{_course.courseTitle}}
                    </span>
                </div>
            </div>
        </h6>

        <!--<h6 class="text-gray font-ag">
            <div class="row">
                <div class="col-md-2">
                    Course title:
                </div>

                <div class="col-md-10">
                    <span class="text-second font-ag-bold">
                        {{_course.courseName}} ({{_booking.courseLocation}})
                    </span>
                </div>
            </div>
        </h6>-->

        <h6 class="text-gray font-ag">
            <div class="row">
                <div class="col-md-3">
                    Date:
                </div>

                <div class="col-md-9">
                    <span class="text-second font-ag-bold">
                        {{_course.dateDisplay | date:'dd-MM-YYYY'}}
                    </span>
                </div>
            </div>
        </h6>

        <h6 class="text-gray font-ag">
            <div class="row">
                <div class="col-md-3">
                    Location:
                </div>

                <div class="col-md-9">
                    <span class="text-second font-ag-bold">
                        {{_course.courseLocation}}
                    </span>
                </div>
            </div>
        </h6>

        <h6 class="text-gray font-ag">
            <div class="row">
                <div class="col-md-3">
                    Speaker Name:
                </div>

                <div class="col-md-9">
                    <span class="text-second font-ag-bold">
                        {{_course.speakerName}}
                    </span>
                </div>
            </div>
        </h6>

        <h6 class="text-gray font-ag">
            <div class="row">
                <div class="col-md-3">
                    Total Bookings:
                </div>

                <div class="col-md-9">
                    <span class="text-second font-ag-bold">
                        {{_course.totalBookings}}
                    </span>
                </div>
            </div>
        </h6>



    </div>
    <div class="modal-footer">
        <button type="button" class="book-btn" (click)="close()">
            OK
        </button>
    </div>
</ng-template>