import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AgGridAngular } from 'ag-grid-angular';
import { CellClickedEvent, ColDef, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { XeroInvoiceStatus } from '../../../common/validation/messages';
import { CommonServiceService } from '../../../common/_services/common-service.service';
import { FinanceService } from '../../../common/_services/finance-services';
import { DeleteComponent } from '../../../shared/_components/delete/delete.component';
import { ButtonRendererComponent } from '../../courses/course-actions/button-renderer/button-renderer.component';
import { CheckboxRendererComponent } from '../../courses/course-actions/checkbox-renderer/checkbox-renderer.component';

@Component({
    selector: 'app-xero-invoices',
    templateUrl: './xero-invoices.component.html',
    styleUrls: ['./xero-invoices.component.css']
})
export class XeroInvoicesComponent implements OnInit {

    public _refDialog: any;

    invoicesList: any;
    domLayout;
    private gridApi;
    gridOptions: GridOptions;
    _params: any;
    _code: any;
    _state: any;
    currentDate = new Date();
    start_date: any = new Date(new Date().setDate(this.currentDate.getDate() - 30));

    _methodToload: any = "";
    listTitle: any = "Xero Invoices";
    paginationPageSize: any = 10;

    toggleFilterValue: any = 0;
    filterToggleAll: boolean = true;
    filterToggleDraft: boolean = false;

    isSelectAll: boolean = false;
    selectAllText: any = "Select All";

    invoiceNumbers: any[] = [];

    frameworkComponents: any;

    _paramOut = {
        showReceivedInvoices: false,
        showSuppliers: false,
        id: 0
    }
    @Output() gotoInvoicesReceived = new EventEmitter<object>();
    @Output() gotoCreditNotes = new EventEmitter<void>();
    @Output() gotoInvoicesIssued = new EventEmitter<void>();


    constructor(private _dialog: MatDialog,
        private route: ActivatedRoute,
        private _financeService: FinanceService,
        private _commonService: CommonServiceService) {

        this.gridOptions = {
            suppressCellSelection: true,
            rowClass: 'grid-row',
            context: {
                componentParent: this
            }
        };

        this.domLayout = "autoHeight";

        this.frameworkComponents = {
            checkboxRenderer: CheckboxRendererComponent,
            buttonRenderer: ButtonRendererComponent,
        }
    }

    ngOnInit(): void {

        this.route.queryParamMap
            .subscribe((params) => {
                this._params = { ...params.keys, ...params };
                this._code = this._params.params.code;
                this._state = this._params.params.state;
            });

        if (this._code != null && this._code != undefined
            && this._code != "null" != Number.isNaN(this._code)) {

        }

        this.getXeroInvoices();

        localStorage.setItem('projectPageSize', '50');
        //this.getSuppliersList();
    }

    public columnDefs: ColDef[] = [
        //{ field: 'dateAdded', headerName: 'Added Date', width: 200, valueFormatter: this.convertStringToDate },
        {
            field: '',
            headerName: "",
            cellRenderer: 'checkboxRenderer',
            cellRendererParams: {
                onClick: this.selectInvoiceToUpdate.bind(this)
            },
            headerClass: "pull-center",
            width: 75

        },
        { field: 'contact.name', headerName: 'To', cellRenderer: this.emptyReplacement, resizable: true },
        { field: 'date', headerName: 'Date', width: 225, valueFormatter: this.convertStringToDate, resizable: true },
        { field: 'dueDate', headerName: 'Due Date', valueFormatter: this.convertStringToDate, resizable: true },
        { field: 'invoiceNumber', headerName: 'Invoice Number', width: 200, resizable: true },
        { field: 'reference', headerName: 'PO Number', cellRenderer: this.emptyReplacement, resizable: true },
        { field: 'subTotal', headerName: 'Sub Total', cellRenderer: params => this.currencyFormatter(params.value), resizable: true },
        { field: 'totalTax', headerName: 'VAT', cellRenderer: params => this.currencyFormatter(params.value), resizable: true },
        { field: 'amountDue', headerName: 'Due', cellRenderer: params => this.currencyFormatter(params.value), resizable: true },
        { field: 'status', headerName: 'Status', valueFormatter: this.getStatus, resizable: true },
        { field: 'fullyPaidOnDate', headerName: 'Payment Date', valueFormatter: this.convertStringToDate, resizable: true },
        { field: 'sentToContact', headerName: 'Sent', cellRenderer: this.boolReplacement, resizable: true },
        {
            field: 'Action', cellRenderer: 'buttonRenderer',
            cellRendererParams: {
                onClick: this.syncIndividualInvoice.bind(this),
                label: 'sync'
            }
        },
        //{
        //    field: 'Action', cellRendererFramework: SupplierClickableParentComponent,
        //    sortable: false, filter: false, width: 85, cellStyle: { color: '#1BAAD5', 'margin-top': '7px', 'border': '0px' }
        //}
    ];

    convertStringToDate(params) {

        var strDate = params.value;
        if (strDate != null && strDate != "" && strDate != undefined) {
            var date = new Date(strDate);
            var dd = String(date.getDate()).padStart(2, '0');
            var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = date.getFullYear();

            var _date = dd + '/' + mm + '/' + yyyy;
            return _date;
        }
        else return "";
    }

    getStatus(params) {
        return XeroInvoiceStatus[params.value];
    }

    // CURRENCY FORMATTING
    currencyFormatter(currency) {
        if (currency != null) {
            var sansDec = currency.toFixed(0);
            var formatted = sansDec.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return '<span>&#163;</span>' + `${formatted}`;
        }
        else return '-';
    }

    emptyReplacement(params) {

        if (params.value != null && params.value != "") {
            return params.value;
        }
        else return "-";
    }

    boolReplacement(params) {

        if (params.value == true) {
            return "Sent";
        }
        else return "-";
    }


    public rowData = [
        { make: '', model: '', price: 0 }
    ];

    // DefaultColDef sets props common to all Columns
    public defaultColDef: ColDef = {
        sortable: true,
        filter: true,
    };

    selectInvoiceToUpdate(param) {
        //debugger;
        if (param.action == true) {
            var req = {};

            req = {
                InvoiceNumber: param.rowData.invoiceNumber,
                Status: "" + param.rowData.status,
                InvoicePaidDate: param.rowData.fullyPaidOnDate
            };

            this.invoiceNumbers.push(req);
        }
        else {
            var key = param.rowData.invoiceNumber;

            var __key = this.invoiceNumbers.find(({ InvoiceNumber }) => InvoiceNumber === key);

            const index = this.invoiceNumbers.indexOf(__key, 0);
            if (index > -1) {
                this.invoiceNumbers.splice(index, 1);
            }
        }
    }

    syncInvoices() {

    }

    syncIndividualInvoice(param) {
        
        if (this.invoiceNumbers.length > 0)
            this.invoiceNumbers.forEach((item, index) => {
                $("#" + item.InvoiceNumber).prop("checked", false);
            });

        this.invoiceNumbers = [];

        var req = {};

        req = {
            InvoiceNumber: param.data.invoiceNumber,
            Status: "" + param.data.status,
            InvoicePaidDate: this.adjustDateForTimeOffset(new Date(param.data.fullyPaidOnDate))
        };
        //debugger;
        this.invoiceNumbers.push(req);

        this.syncData();
    }

   

    syncData() {
        
        this._refDialog = this._dialog.open(DeleteComponent, {
            autoFocus: false,
            disableClose: true,
            data: { message: "Are you sure to reconcile this invoice?", title: "Reconcile" }
        });
        
        this._refDialog.componentInstance.redirect.subscribe((data) => {

            this._financeService.updateInvoiceFromXero(this.invoiceNumbers).subscribe((result: any) => {


                if (result.success == true) {

                    this._commonService.success(result.message);

                    this.getXeroInvoices();

                } else {
                    //show error popup
                    this._commonService.error(result.message);
                }

            }, (_error: any) => {
                //this.loading = false;
            })


        });

        this._refDialog.componentInstance.closePopup.subscribe((data) => {
            this._refDialog.close();
        });


        
        

    }

    // For accessing the Grid's API
    @ViewChild(AgGridAngular) agGrid!: AgGridAngular;

    setAutoHeight() {
        this.gridApi.setDomLayout("autoHeight");
        //document.querySelector("#dGrid").style.height = "";
        var elm = document.querySelector("#supplierGrid") as HTMLElement;


        var elm_outer = document.querySelector(".grid-wrapper") as HTMLElement;

        // elm_outer.offsetHeight 
        elm.style.height = screen.height - 300 + "px";
        //Array.from(document.getElementsByClassName('grid-wrapper') as HTMLCollectionOf<HTMLElement>)
    }

    setFixedHeight() {

        this.gridApi.setDomLayout("normal");
        //document.querySelector("#dGrid").style.height = "400px";
        var elm = document.querySelector("#supplierGrid") as HTMLElement;
        elm.style.height = '535px';
    }


    // Example load data from sever
    onGridReady(params: GridReadyEvent) {

        this.gridApi = params.api;

        this.rowData = [
            { make: '', model: '', price: 0 }
        ];

        this.setFixedHeight();
        this.gridOptions.api!.sizeColumnsToFit();
    }

    // Example of consuming Grid Event
    onCellClicked(e: CellClickedEvent): void {
        if (e.colDef.field != "Action") {
            //this.gotoSupplierDetails.emit(e.data);
        }
    }

    onRowClicked() { }

    // Example using Grid's API
    clearSelection(): void {
        this.agGrid.api.deselectAll();
    }



    onPageSizeChanged() {
        var elm = document.getElementById('page-size') as HTMLInputElement;
        localStorage.setItem('projectPageSize', elm.value);
        this.gridApi.paginationSetPageSize(Number(elm.value));
    }

    addEvent(event: MatDatepickerInputEvent<Date>) {
        
        if (event.value != null) {
            //this.start_date = new Date(event.value);
            //this.start_date.setDate(event.value.getDate());
            //this.start_date.setMonth(event.value.getMonth());
            //this.start_date.setFullYear(event.value.getFullYear());
            //this.start_date.setHours(0, 0, 0, 0);

            this.start_date = this.adjustDateForTimeOffset(event.value);

            // call filter
            this.onSubmit();
        }

    }

    adjustDateForTimeOffset(dateToAdjust) {
        var offsetMs = dateToAdjust.getTimezoneOffset() * 60000;
        return new Date(dateToAdjust.getTime() - offsetMs);
    }

    getXeroInvoices() {

        var req = {
            code: this._code,
            state: this._state,
            filter: Number(this.toggleFilterValue),
            filterDate: this.start_date
        }

        this._financeService.GetXeroInvoices(req).subscribe((result: any) => {
            //debugger;
            if (result.success == true) {
                if (result.message != "" && result.message != null) {
                    // open the xero login window
                    //this._route.navigate(result.message);
                    window.open(result.message, "_self");
                    //window.location.replace(result.message);
                }
                else if (result.data != null) {
                    this.invoicesList = result.data;
                }


            } else {

                //this._commonService.error(result.data.desc);
            }

        }, (_error: any) => {
            //this.loading = false;
        })
    }

    toggleSelectAll(): void {
        this.isSelectAll = !this.isSelectAll;
        this.selectAllText = this.isSelectAll ? 'Deselect all' : 'Select all';

        this.selectAll(this.isSelectAll);
        
    }
    

    

    getFilterToggleValue(val: any) {
        
        this.filterToggleAll = false;
        //this.toggleFilterValue = val;

        if (val == 'all')
            this.toggleFilterValue = XeroInvoiceStatus.ALL;
        else if (val == 'Draft')
            this.toggleFilterValue = XeroInvoiceStatus.DRAFT;
        else if (val == 'Awaiting Approval')
            this.toggleFilterValue = XeroInvoiceStatus.SUBMITTED;
        else if (val == 'Awaiting Payment')
            this.toggleFilterValue = XeroInvoiceStatus.AUTHORISED;
        else if (val == 'Paid')
            this.toggleFilterValue = XeroInvoiceStatus.PAID;


        // call filter
        this.onSubmit();
    }

    onSubmit() {
        if (this.start_date != null)
            this.getXeroInvoices();
    }

    

    gotoSuppliersList() {
        this._paramOut.showReceivedInvoices = false;
        this._paramOut.id = 0;
        this._paramOut.showSuppliers = true;
        this.gotoInvoicesReceived.emit(this._paramOut);
    }

    gotoInvoicesIssuedList() {
        this.gotoInvoicesIssued.emit();
    }

    gotoInvoicesReceivedList() {
        this._paramOut.showReceivedInvoices = true;
        this._paramOut.id = 0;
        this._paramOut.showSuppliers = false;
        this.gotoInvoicesReceived.emit(this._paramOut);
    }

    gotoCreditNotesList() {
        this.gotoCreditNotes.emit();
    }

    selectAll(action) {


        if (action) {
            if (this.invoicesList.length > 0)
                this.invoicesList.forEach((item, index) => {
                    $("#" + item.invoiceNumber).prop("checked", true);
                    this.selectInvoiceToUpdate(
                        { action: true, rowData: item }
                    );
                });
        }
        else {
            this.invoiceNumbers = [];

            if (this.invoicesList.length > 0)
                this.invoicesList.forEach((item, index) => {
                    $("#" + item.invoiceNumber).prop("checked", false);
                });
        }

    }
}
