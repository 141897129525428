<!--<div id="section-to-print" #printSection class="full-width" style="display:none; top: 0; position: absolute;">-->
<div id="section-to-print" #printSection class="full-width" style="display:none; top: 0; position: absolute; width:100%!important">
    <div class="row">
        <div class="col-5 pull-left">
            <img class="img-responsive an-logo pull-left" src="assets\images\loginlogo.png" />
        </div>
        <div class="col-7 text-right">

            <h2 class="mb-3 text-second font-ag-light" style="font-size: 3rem;">
                <span class="text-main font-ag-bold">Delegate List</span>
            </h2>


        </div>
        <br />
        <span class="mb-3 pull-right pl-0 mt-4" style="font-weight: bold; font-size: larger;">
            {{courseTitle}}
            <br />
            {{start_date |date: 'dd-MM-yyyy'}}
        </span>

        <table class="full-width">

            <tr *ngFor="let delegate of delegatesList" style="border-bottom:1pt solid black;">
                <td class="v-align-top pt-3">
                    <div>
                        <strong>
                            {{delegate.name}}
                        </strong>
                        <br />
                        <strong>Organisation: </strong>{{delegate.organization}}
                        <br />
                        <strong>Email: </strong>{{delegate.email}}
                        <br />
                        <div *ngIf="isOfficeUse == true">
                            <strong *ngIf="delegate.telephone != null && delegate.telephone != '' ">Contact number: </strong><span *ngIf="delegate.telephone != null && delegate.telephone != '' ">{{delegate.telephone}}&nbsp;&nbsp;&nbsp;&nbsp;</span>
                            <strong>Invoice number: </strong>{{delegate.isSent.invoiceNumber}}&nbsp;&nbsp;&nbsp;&nbsp;
                            <strong>Status: </strong> <span *ngIf="delegate.isSent.isInvoicePaid == false">Not Paid</span> <span *ngIf="delegate.isSent.isInvoicePaid == true">Paid</span>
                        </div>
                    </div>
                </td>
            </tr>
        </table>


    </div>
</div>


<!--<div id="section-to-print-office" class="full-width" style="display:none; top: 0; position: absolute;">

    <div class="row">
        <div class="col-5 pull-left">
            <img class="img-responsive an-logo pull-left" src="assets\images\loginlogo.png" />
        </div>
        <div class="col-7 text-right">

            <h2 class="mb-3 text-second font-ag-light" style="font-size: 3rem;">
                <span class="text-main font-ag-bold">Delegates List</span>
            </h2>
        </div>

        <span class="mb-3 pull-right pl-0" style="font-weight: bold; font-size: larger;">{{courseTitle}}</span>

        <table class="full-width">

            <tr *ngFor="let delegate of delegatesList" style="border-bottom:1pt solid black;">
                <td class="v-align-top pt-3">
                    <div>
                        <strong>
                            {{delegate.name}}
                        </strong>
                        <br />
                        <strong>Email: </strong>{{delegate.email}}
                        <br />
                        <strong>Organisation: </strong>{{delegate.organization}}
                        <br />
                        <strong>Invoice number: </strong>{{delegate.isSent.invoiceNumber}}
                        <strong>Status: </strong>{{delegate.isSent.isInvoicePaid}}
                    </div>
                </td>
            </tr>
        </table>


    </div>
</div>-->





<div class="row">
    <div class="col-lg-9">

        <h2 class="mb-4 text-second font-ag-light" style="font-size: 3rem;">
            <span class="text-main font-ag-bold">Upcoming Course</span> DETAILS
            <!--REVERT-->
        </h2>

    </div>

    <div class="col-lg-3 pt-1">

        <button class="book-btn pull-right filter-apply" (click)="allCourses()">
            Back
        </button>
    </div>

</div>

<div class="row no-bg pb-0 p-3 ml-0 mb-0 m-2 pt-0 mt-0">
    <div class="col-md-4">
        <div class="row m-0">
            <div class="col-md-12 delegate-box pb-2">
                <!--<form [formGroup]="coursesForm" name="coursesForm">-->
                <!--<mat-select formControlName="d_courses" #select class="top-dd" (change)="getCurrentCourseViewList($event)">
                <mat-option *ngFor="let course of coursesList" [value]="course.title">{{course.title}}</mat-option>-->
                <!--<option class="bg-white main-select-opt" *ngFor="let course of coursesList" [value]="course.title">{{course.title}}</option>-->
                <!--</mat-select>-->
                <!--<mat-select formControlName="d_courses" #select id="main_dd"
                            (selectionChange)="getCurrentCourseViewList($event, true)"
                            class="form-control no-radius top-dd">
                    <mat-option *ngFor="let course of coursesList" [value]="course.title">{{course.title}}</mat-option>
                </mat-select>-->

                <label class="text-second fw-normal">

                    <span class="delegate-box">
                        {{courseTitle}}
                    </span>
                </label>
                <!--</form>-->
            </div>
        </div>
    </div>
    <div class="col-md-8">
        <div class="row no-bg">
            <div class="col-md-4">
            </div>
            <div class="col-md-4">
                <label class="text-second fw-normal pull-right">
                    Total Delegates:
                    <span class="delegate-box">
                        {{delegatesList.length}}
                    </span>
                </label>

            </div>
            <div class="col-md-4">
                <label class="text-second fw-normal pull-right">
                    Total Income:
                    <span class="income-box">
                        &#163;{{printNo(courseIncome)}}
                    </span>
                </label>
            </div>
        </div>
    </div>

</div>

<div class="row outer-div bg-lg ml-0 mb-0 mt-0 m-2 pt-0 p-3">
    <div class="col-md-4">
        <div class="row">
            <div class="col-md-12">


                <form [formGroup]="coursesForm" name="coursesForm">


                    <!--<mat-select formControlName="current_courses" #select
                            (selectionChange)="getCourseData($event)"
                            class="form-control no-radius mb-3 no-border">
                    <mat-option *ngFor="let crs of currentCoursesList" [value]="crs.courseId">-->
                    <!--{{crs.courseTitle}}-->
                    <!--{{crs.dates.courseStartDate | date:'dd-MM-yyyy'}}</mat-option>
                    </mat-select>-->

                    <div class="row bg-white h-crs-detail m-0 mb-3px">
                        <div class="col-md-4">
                            <label class="text-second fw-normal">Date:</label>
                        </div>
                        <div class="col-md-8">
                            <label class="text-second">{{start_date | date:'dd-MM-yyyy'}}</label>
                        </div>
                    </div>
                    <div class="row bg-white h-crs-detail m-0 mb-3px">
                        <div class="col-md-4">
                            <label class="text-second fw-normal">Location:</label>
                        </div>
                        <div class="col-md-8">
                            <mat-select formControlName="location" #select
                                        (selectionChange)="getHotelsListByLocation($event, true)"
                                        class="form-control no-radius no-border pl-0 pr-0">
                                <mat-option *ngFor="let loc of locationsList" [value]="loc.cityId">{{loc.cityName}}</mat-option>
                            </mat-select>
                        </div>
                    </div>
                    <div class="row bg-white h-crs-detail m-0 mb-3px">
                        <div class="col-md-4">
                            <label class="text-second fw-normal">Hotel:</label>
                        </div>
                        <div class="col-md-8">
                            <mat-select formControlName="hotel" #select
                                        (selectionChange)="getHotelRoomsList($event.value, true)"
                                        class="form-control no-radius no-border pl-0 pr-0">
                                <mat-option *ngFor="let hotel of hotelsList" [value]="hotel.id">{{hotel.name}}</mat-option>
                            </mat-select>
                        </div>
                    </div>
                    <div class="row bg-white h-crs-detail m-0 mb-3">
                        <div class="col-md-4">
                            <label class="text-second fw-normal">Fee:</label>
                        </div>
                        <div class="col-md-8">
                            <label class="text-second">&#163;{{cost}}</label>&nbsp;&nbsp;&nbsp;
                            <label class="text-second fw-normal">(Ex VAT)</label>
                        </div>
                    </div>



                    <div class="row bg-white h-crs-detail m-0 mb-3px">
                        <div class="col-md-4">
                            <label class="text-second fw-normal">Speaker:</label>
                        </div>
                        <div class="col-md-8">
                            <mat-select formControlName="speaker" #select
                                        (selectionChange)="updateSpeaker($event.value, 0, true, false)"
                                        class="form-control no-radius no-border pl-0 pr-0">
                                <mat-option *ngFor="let spkr of speakersList" [value]="spkr.speakerId">{{spkr.speakerName}}</mat-option>
                            </mat-select>
                        </div>
                    </div>

                    <div class="row bg-white h-crs-detail m-0 mb-3px">
                        <div class="col-md-4">
                            <label class="text-second fw-normal">Duration:</label>
                        </div>
                        <div class="col-md-8">
                            <label *ngIf="courseTypeId == 2" class="text-second">{{duration}} days</label>
                            <label *ngIf="courseTypeId == 1" class="text-second">{{duration}} day</label>
                        </div>
                    </div>

                    <div class="row bg-white h-crs-detail m-0 mb-3px">
                        <div class="col-md-4">
                            <label class="text-second fw-normal">Registration:</label>
                        </div>
                        <div class="col-md-8">
                            <label class="text-second">{{registration | date:'shortTime'}}</label>
                        </div>
                    </div>

                    <div class="row bg-white h-crs-detail m-0 mb-3px">
                        <div class="col-md-4">
                            <label class="text-second fw-normal">Start:</label>
                        </div>
                        <div class="col-md-8">
                            <label class="text-second">{{start_time}}</label>
                        </div>
                    </div>

                    <div class="row bg-white h-crs-detail m-0 mb-3px">
                        <div class="col-md-4">
                            <label class="text-second fw-normal">Finish:</label>
                        </div>
                        <div class="col-md-8">
                            <label class="text-second">{{end_time}}</label>
                        </div>
                    </div>

                    <div class="btn-holder mb-3">
                        <button class="book-btn pull-right filter-apply" (click)="updateCourse()">
                            Update
                        </button>
                    </div>

                </form>



            </div>
        </div>

        <div *ngIf="courseTypeId == 2" class="row">
            <div class="col-md-12">
                <div class="row bg-white h-crs-detail m-0 mb-3px">
                    <div class="col-md-8">
                        <label class="text-second fw-normal">Assessment date reminder</label>
                    </div>
                    <div class="col-md-4">
                        <button class="book-btn pull-right filter-apply" (click)="sendReminderEmailDelegates()">
                            Send reminder
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-8">

        <div class="row">
            <div class="col-md-12">

                <label class="text-second p-1">Course Dates</label>
                <form [formGroup]="del_date_Form" name="del_date_Form" (ngSubmit)="onSubmit(f)" #f="ngForm">

                    <table class="table">
                        <thead>
                            <tr class="">
                                <th>Day</th>
                                <th>Date</th>
                                <th>Speaker</th>
                                <th>Hotel/Room</th>
                                <th>Capacity</th>
                                <th>Material</th>
                                <th>Teams</th>
                                <th>Cancelled</th>
                            </tr>
                        </thead>
                        <tr class="no-border">
                            <td class="p-1 max-d-w">
                                <span class="cd-span m-1 course-details-day-no-portrait">
                                    Day 1
                                </span>

                                <span class="cd-span m-1 course-details-day-portrait-only">
                                    D1
                                </span>
                            </td>
                            <td class="p-1">
                                <span class="cd-span m-1 td-date">
                                    {{day1 | date:'dd-MM-yyyy'}}
                                </span>
                            </td>
                            <td class="p-1">
                                <span class="cd-span m-1 pt-0 pr-0 pl-0">
                                    <mat-select formControlName="speaker1" #select
                                                (selectionChange)="updateSpeaker($event.value, 1, true, true)"
                                                class="form-control no-radius no-border mt-inner-dd">
                                        <mat-option *ngFor="let spkr of speakersList" [value]="spkr.speakerId">{{spkr.speakerName}}</mat-option>
                                    </mat-select>
                                </span>
                            </td>
                            <td class="p-1">
                                <span class="cd-span m-1 pt-0 pr-0 pl-0">
                                    <mat-select formControlName="room1" #select
                                                (selectionChange)="updateSpecificRoom($event.value, 1)"
                                                class="form-control no-radius no-border mt-inner-dd">
                                        <mat-option *ngFor="let room of hotelRoomsList" [value]="room.id">{{room.room}}</mat-option>
                                    </mat-select>
                                </span>
                            </td>
                            <td class="p-1">
                                <span class="cd-span m-1 pull-center">
                                    {{day1_capacity}}
                                </span>
                            </td>
                            <td class="p-1">
                                <span class="cd-span m-1 pt-1 pull-center">
                                    <mat-checkbox formControlName="box1" class="example-margin"
                                                  (change)="updateMaterialSent($event.checked, 1, 'material')"
                                                  [(ngModel)]="d1_checked"></mat-checkbox>
                                </span>

                            </td>
                            <td class="p-1">
                                <span class="cd-span m-1 pt-1 pull-center">
                                    <mat-checkbox formControlName="teams1" class="example-margin"
                                                  (change)="updateMaterialSent($event.checked, 1, 'teams')"
                                                  [(ngModel)]="d1_teams"></mat-checkbox>
                                </span>
                            </td>
                            <td class="p-1">
                                <span class="cd-span m-1 pt-1 pull-center">
                                    <mat-checkbox formControlName="cancel1" class="example-margin"
                                                  (change)="updateCancellation($event.checked, 1)"
                                                  [(ngModel)]="d1_cancelled"></mat-checkbox>
                                </span>

                            </td>

                        </tr>

                        <tr *ngIf="courseTypeId == 2">

                            <td class="p-1 max-d-w">
                                <span class="cd-span m-1 td-date- ">
                                    Day 2
                                </span>
                            </td>

                            <td class="p-1">
                                <span class="cd-span m-1 td-date">
                                    {{day2 | date:'dd-MM-yyyy'}}
                                </span>
                            </td>
                            <td class="p-1">
                                <span class="cd-span m-1 pt-0 pr-0 pl-0">
                                    <mat-select formControlName="speaker2" #select
                                                (selectionChange)="updateSpeaker($event.value, 2, true, true)"
                                                class="form-control no-radius no-border mt-inner-dd">
                                        <mat-option *ngFor="let spkr of speakersList" [value]="spkr.speakerId">{{spkr.speakerName}}</mat-option>
                                    </mat-select>
                                </span>
                            </td>
                            <td class="p-1">
                                <span class="cd-span m-1 pt-0 pr-0 pl-0">
                                    <mat-select formControlName="room2" #select
                                                (selectionChange)="updateSpecificRoom($event.value, 2)"
                                                class="form-control no-radius no-border mt-inner-dd">
                                        <mat-option *ngFor="let room of hotelRoomsList" [value]="room.id">{{room.room}}</mat-option>
                                    </mat-select>
                                </span>
                            </td>
                            <td class="p-1">
                                <span class="cd-span m-1 pull-center">
                                    {{day2_capacity}}
                                </span>
                            </td>

                            <td class="p-1">
                                <span class="cd-span m-1 pt-1 pull-center">
                                    <mat-checkbox formControlName="box2" class="example-margin" [(ngModel)]="d2_checked"
                                                  (change)="updateMaterialSent($event.checked, 2, 'material')"></mat-checkbox>
                                </span>
                            </td>

                            <td class="p-1">
                                <span class="cd-span m-1 pt-1 pull-center">
                                    <mat-checkbox formControlName="teams2" class="example-margin"
                                                  (change)="updateMaterialSent($event.checked, 2, 'teams')"
                                                  [(ngModel)]="d2_teams"></mat-checkbox>
                                </span>
                            </td>

                            <td class="p-1">
                                <span class="cd-span m-1 pt-1 pull-center">
                                    <mat-checkbox formControlName="cancel2" class="example-margin"
                                                  (change)="updateCancellation($event.checked, 2)"
                                                  [(ngModel)]="d2_cancelled"></mat-checkbox>
                                </span>

                            </td>

                        </tr>

                        <tr *ngIf="courseTypeId == 2">
                            <td class="p-1 max-d-w">
                                <span class="cd-span m-1 td-date- ">
                                    Day 3
                                </span>
                            </td>
                            <td class="p-1">
                                <span class="cd-span m-1 td-date">
                                    {{day3 | date:'dd-MM-yyyy'}}
                                </span>
                            </td>

                            <td class="p-1">
                                <span class="cd-span m-1 pt-0 pr-0 pl-0">
                                    <mat-select formControlName="speaker3" #select
                                                (selectionChange)="updateSpeaker($event.value, 3, true, true)"
                                                class="form-control no-radius no-border mt-inner-dd">
                                        <mat-option *ngFor="let spkr of speakersList" [value]="spkr.speakerId">{{spkr.speakerName}}</mat-option>
                                    </mat-select>
                                </span>
                            </td>

                            <td class="p-1">
                                <span class="cd-span m-1 pt-0 pr-0 pl-0">
                                    <mat-select formControlName="room3" #select
                                                (selectionChange)="updateSpecificRoom($event.value, 3)"
                                                class="form-control no-radius no-border mt-inner-dd">
                                        <mat-option *ngFor="let room of hotelRoomsList" [value]="room.id">{{room.room}}</mat-option>
                                    </mat-select>
                                </span>
                            </td>
                            <td class="p-1">
                                <span class="cd-span m-1 pull-center">
                                    {{day3_capacity}}
                                </span>
                            </td>
                            <td class="p-1">
                                <span class="cd-span m-1 pt-1 pull-center">
                                    <mat-checkbox formControlName="box3" class="example-margin" [(ngModel)]="d3_checked"
                                                  (change)="updateMaterialSent($event.checked, 3, 'material')"></mat-checkbox>
                                </span>
                            </td>
                            <td class="p-1">
                                <span class="cd-span m-1 pt-1 pull-center">
                                    <mat-checkbox formControlName="teams3" class="example-margin"
                                                  (change)="updateMaterialSent($event.checked, 3, 'teams')"
                                                  [(ngModel)]="d3_teams"></mat-checkbox>
                                </span>
                            </td>
                            <td class="p-1">
                                <span class="cd-span m-1 pt-1 pull-center">
                                    <mat-checkbox formControlName="cancel3" class="example-margin"
                                                  (change)="updateCancellation($event.checked, 3)"
                                                  [(ngModel)]="d3_cancelled"></mat-checkbox>
                                </span>
                            </td>

                        </tr>

                        <tr *ngIf="courseTypeId == 2">
                            <td class="p-1 max-d-w">
                                <span class="cd-span m-1 td-date- ">
                                    Day 4
                                </span>
                            </td>
                            <td class="p-1">
                                <span class="cd-span m-1 td-date">
                                    {{day4 | date:'dd-MM-yyyy'}}
                                </span>
                            </td>
                            <td class="p-1">
                                <span class="cd-span m-1 pt-0 pr-0 pl-0">
                                    <mat-select formControlName="speaker4" #select
                                                (selectionChange)="updateSpeaker($event.value, 4, true, true)"
                                                class="form-control no-radius no-border mt-inner-dd">
                                        <mat-option *ngFor="let spkr of speakersList" [value]="spkr.speakerId">{{spkr.speakerName}}</mat-option>
                                    </mat-select>
                                </span>
                            </td>

                            <td class="p-1">
                                <span class="cd-span m-1 pt-0 pr-0 pl-0">
                                    <mat-select formControlName="room4" #select
                                                (selectionChange)="updateSpecificRoom($event.value, 4)"
                                                class="form-control no-radius no-border mt-inner-dd">
                                        <mat-option *ngFor="let room of hotelRoomsList" [value]="room.id">{{room.room}}</mat-option>
                                    </mat-select>
                                </span>
                            </td>
                            <td class="p-1">
                                <span class="cd-span m-1 pull-center">
                                    {{day4_capacity}}
                                </span>
                            </td>
                            <td class="p-1">
                                <span class="cd-span m-1 pt-1 pull-center">
                                    <mat-checkbox formControlName="box4" class="example-margin" [(ngModel)]="d4_checked"
                                                  (change)="updateMaterialSent($event.checked, 4, 'material')"></mat-checkbox>
                                </span>
                            </td>
                            <td class="p-1">
                                <span class="cd-span m-1 pt-1 pull-center">
                                    <mat-checkbox formControlName="teams4" class="example-margin"
                                                  (change)="updateMaterialSent($event.checked, 4, 'teams')"
                                                  [(ngModel)]="d4_teams"></mat-checkbox>
                                </span>
                            </td>
                            <td class="p-1">
                                <span class="cd-span m-1 pt-1 pull-center">
                                    <mat-checkbox formControlName="cancel4" class="example-margin"
                                                  (change)="updateCancellation($event.checked, 4)"
                                                  [(ngModel)]="d4_cancelled"></mat-checkbox>
                                </span>

                            </td>



                            <!--<td>{{day4 | date:'dd-MM-yyyy'}}</td>
                            <td>
                                <mat-select formControlName="speaker4" #select
                                            class="form-control no-radius">
                                    <mat-option *ngFor="let spkr of speakersList" [value]="spkr.speakerId">{{spkr.speakerName}}</mat-option>
                                </mat-select>
                            </td>
                            <td>
                                <mat-select formControlName="room4" #select
                                            (selectionChange)="updateSpecificRoom($event.value, 4)"
                                            class="form-control no-radius">
                                    <mat-option *ngFor="let room of hotelRoomsList" [value]="room.id">{{room.room}}</mat-option>
                                </mat-select>
                            </td>
                            <td>{{day4_capacity}}</td>
                            <td>
                                <mat-checkbox formControlName="box4" class="example-margin" [(ngModel)]="d4_checked"></mat-checkbox>
                            </td>-->
                        </tr>
                    </table>
                    <!--<button class="btn btn-primary">Add site</button>-->
                </form>
                <!--<ag-grid-angular style="width: 610px; height:300px;"
                                 class="ag-theme-alpine"
                                 [columnDefs]="datesColumnDefs"
                                 [defaultColDef]="defaultColDef"
                                 [rowData]="datesList"
                                 [animateRows]="true">

                </ag-grid-angular>-->

                <label class="text-second p-1">Delegate Details</label>

                <button class="book-btn pull-right filter-apply" (click)="addDelegateToCourse()">
                    Add delegate
                </button>

                <button class="book-btn pull-right filter-apply mr-2" (click)="getDelegateEmails()">
                    Email to Delegates
                </button>

                <button class="book-btn pull-right filter-apply mr-2" (click)="printPDF(false)">
                    Print (For Speaker)
                </button>

                <button class="book-btn pull-right filter-apply mr-2" (click)="printPDF(true)">
                    Print (Office use)
                </button>

                <ag-grid-angular id="myGrid" style="height:300px;"
                                 class="ag-theme-alpine"
                                 [columnDefs]="columnDefs"
                                 [defaultColDef]="defaultColDef"
                                 [rowData]="delegatesList"
                                 [animateRows]="true"
                                 [gridOptions]="gridOptions"
                                 (cellClicked)="onCellClicked($event)"
                                 (gridReady)="onGridReady($event)"
                                 [frameworkComponents]="frameworkComponents">

                </ag-grid-angular>



            </div>
        </div>
    </div>
</div>


<!--<mat-tab-group (selectedTabChange)="gotoMainMenu($event)" [selectedIndex]="0" mat-align-tabs="start">
    <mat-tab label="Courses">

    </mat-tab>

    <mat-tab label="Delegates">
    </mat-tab>

    <mat-tab label="Schedule">
    </mat-tab>
</mat-tab-group>-->



<ng-template #modalPaymentDetails let-close="close">
    <div class="modal-header primary">
        <h5 class="modal-title primary">Payment Details</h5>
    </div>
    <div class="modal-body- p-4">


        <form class="form-horizontal" [formGroup]="paymentForm" (ngSubmit)="onPaymentSubmit()" name="cuForm" autocomplete="off">


            <div class="row">
                <div class="col-6">

                    <label class="text-second">Invoice Number:</label>
                    <input type="text" class="form-control no-radius" placeholder="Invoice Number"
                           [ngClass]="{  'is-invalid': i.invoiceNumber.errors}"
                           required formControlName="invoiceNumber" maxlength="250" />
                    <div *ngIf="i.invoiceNumber.errors" class="invalid-feedback">
                        <div *ngIf="i.invoiceNumber.errors.pattern">{{errors.courseTitle}}</div>
                    </div>
                </div>
                <div class="col-6">

                    <label class="text-second">Subtotal:</label>
                    <input type="text" class="form-control no-radius" placeholder="Subtotal"
                           [ngClass]="{  'is-invalid': i.subtotal.errors}"
                           required formControlName="subtotal" maxlength="250" />
                    <div *ngIf="i.subtotal.errors" class="invalid-feedback">
                        <div *ngIf="i.subtotal.errors.pattern">{{errors.courseTitle}}</div>
                    </div>
                </div>
                <div class="col-6">
                    <label class="text-second">Total Tax:</label>
                    <input type="text" class="form-control no-radius" placeholder="Total Tax"
                           [ngClass]="{  'is-invalid': i.totalTax.errors}"
                           required formControlName="totalTax" maxlength="250" />
                    <div *ngIf="i.totalTax.errors" class="invalid-feedback">
                        <div *ngIf="i.totalTax.errors.pattern">{{errors.courseTitle}}</div>
                    </div>
                </div>
                <div class="col-6">
                    <label class="text-second">Paid By:</label>

                    <mat-select formControlName="source" #select [(ngModel)]="selectedPaymentMethod" id="paymentMethod"
                                class="form-control no-radius text-second mt-3-">

                        <mat-option selected value="none" class=" text-second">Select source</mat-option>

                        <mat-option value="BACS">
                            BACS
                        </mat-option>

                        <mat-option value="Cash">
                            Cash
                        </mat-option>

                        <mat-option value="Cheque">
                            Cheque
                        </mat-option>

                        <mat-option value="Credit Note">
                            Credit Note
                        </mat-option>

                        <mat-option value="Direct Debit">
                            Direct Debit
                        </mat-option>

                        <mat-option value="AMEX">
                            AMEX
                        </mat-option>

                        <mat-option value="Paypal">
                            Paypal
                        </mat-option>

                        <mat-option value="Phone">
                            Phone
                        </mat-option>

                        <mat-option value="Shopify">
                            Shopify
                        </mat-option>

                        <mat-option value="Written Off">
                            Written Off
                        </mat-option>

                        <mat-option value="Other">
                            Other
                        </mat-option>

                        <mat-option value="Cancelled">
                            Cancelled
                        </mat-option>


                    </mat-select>

                </div>
                <div class="col-6">
                    <div class="col-md-12  mt-3">
                        <mat-form-field appearance="fill" ngDefaultControl class="w-100">
                            <mat-label>Payment date</mat-label>
                            <input matInput [matDatepicker]="picker_paymentDate" formControlName="p_paymentDate" placeholder=""
                                   [ngModel]="paymentDate"
                                   [ngClass]="{'is-invalid':  submitted && i.p_paymentDate.errors}">
                            <mat-datepicker-toggle matSuffix [for]="picker_paymentDate"></mat-datepicker-toggle>
                            <mat-datepicker #picker_paymentDate></mat-datepicker>
                        </mat-form-field>
                    </div>

                </div>
            </div>




            <div class="modal-footer">
                <button type="button" class="book-btn" (click)="close()">
                    Cancel
                </button>

                <div class="">
                    <button [disabled]="!paymentForm.valid"
                            class="book-btn">
                        Save
                    </button>

                </div>
            </div>

        </form>



    </div>

</ng-template>


<ng-template #modalAddDelegateToCourse let-close="close">
    <div class="modal-header primary">
        <h5 class="modal-title primary">Add Delegate to Course</h5>
    </div>
    <div class="modal-body- p-4">


        <form *ngIf="showAddDelegateToCourseForm==true" class="form-horizontal" [formGroup]="addDelegateToCourseForm" (ngSubmit)="onAddDelegateToCourseFormSubmit()" name="addDelegateToCourseForm" autocomplete="off">


            <div class="row">
                <div class="col-12">

                    <label class="text-second">Search delegate by Email address:</label>
                    <input type="text" class="form-control no-radius" placeholder="abc@defg.com"
                           [ngClass]="{  'is-invalid': dc.email.errors && dc.email.errors.pattern}"
                           required formControlName="email" maxlength="250" />
                    <div *ngIf="dc.email.errors" class="invalid-feedback">
                        <div *ngIf="dc.email.errors.pattern">{{errors.email}}</div>
                    </div>
                </div>

            </div>


            <div class="modal-footer">
                <button type="button" class="book-btn" (click)="close()">
                    Cancel
                </button>

                <div class="">
                    <button [disabled]="!addDelegateToCourseForm.valid"
                            class="book-btn">
                        Search
                    </button>

                </div>
            </div>

        </form>

        <div *ngIf="showAddDelegateToCourseForm==false">

            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-12">

                        <div class="row bg-white h-crs-detail m-0 mb-3px">
                            <div class="col-md-4">
                                <label class="text-second fw-normal">Name:</label>
                            </div>
                            <div class="col-md-8">
                                <label class="text-second">{{currentDelegate.name}}</label>
                            </div>
                        </div>

                        <div class="row bg-white h-crs-detail m-0 mb-3px">
                            <div class="col-md-4">
                                <label class="text-second fw-normal">Email:</label>
                            </div>
                            <div class="col-md-8">
                                <label class="text-second">{{emptyFormatter(currentDelegate.email)}}</label>
                            </div>
                        </div>

                        <div class="row bg-white h-crs-detail m-0 mb-3px">
                            <div class="col-md-4">
                                <label class="text-second fw-normal">Organisation:</label>
                            </div>
                            <div class="col-md-8">
                                <label class="text-second">{{currentDelegate.organization}}</label>
                            </div>
                        </div>

                        <div class="row bg-white h-crs-detail m-0 mb-3px">
                            <div class="col-md-4">
                                <label class="text-second fw-normal">Sector:</label>
                            </div>
                            <div class="col-md-8">
                                <label class="text-second">{{emptyFormatter(currentDelegate.sector)}}</label>
                            </div>
                        </div>

                        <div class="row bg-white h-crs-detail m-0 mb-3px">
                            <div class="col-md-4">
                                <label class="text-second fw-normal">Department:</label>
                            </div>
                            <div class="col-md-8">
                                <label class="text-second">{{emptyFormatter(currentDelegate.department)}}</label>
                            </div>
                        </div>

                        <div class="row bg-white h-crs-detail m-0 mb-3px">
                            <div class="col-md-4">
                                <label class="text-second fw-normal">Address 1:</label>
                            </div>
                            <div class="col-md-8">
                                <label class="text-second">{{emptyFormatter(currentDelegate.address1)}}</label>
                            </div>
                        </div>

                        <div class="row bg-white h-crs-detail m-0 mb-3px">
                            <div class="col-md-4">
                                <label class="text-second fw-normal">Address 2:</label>
                            </div>
                            <div class="col-md-8">
                                <label class="text-second">{{emptyFormatter(currentDelegate.address2)}}</label>
                            </div>
                        </div>

                        <div class="row bg-white h-crs-detail m-0 mb-3px">
                            <div class="col-md-4">
                                <label class="text-second fw-normal">Town/City:</label>
                            </div>
                            <div class="col-md-8">
                                <label class="text-second">{{currentDelegate.cityName}}</label>
                            </div>
                        </div>

                        <div class="row bg-white h-crs-detail m-0 mb-3px">
                            <div class="col-md-4">
                                <label class="text-second fw-normal">Postcode:</label>
                            </div>
                            <div class="col-md-8">
                                <label class="text-second">{{emptyFormatter(currentDelegate.postcode)}}</label>
                            </div>
                        </div>

                        <div class="row bg-white h-crs-detail m-0 mb-3px">
                            <div class="col-md-4">
                                <label class="text-second fw-normal">Telephone:</label>
                            </div>
                            <div class="col-md-8">
                                <label class="text-second">{{emptyFormatter(currentDelegate.telephone)}}</label>
                            </div>
                        </div>



                    </div>
                </div>
            </div>

            <div class="modal-footer">
                <button type="button" class="book-btn" (click)="close()">
                    Cancel
                </button>
                <button type="button" class="book-btn" (click)="searchAgain()">
                    Search again
                </button>

                <button type="button" class="book-btn" (click)="addToCourse()">
                    Add to course
                </button>

            </div>
        </div>

    </div>

</ng-template>


<ng-template #modalDelegateEmails let-close="close">
    <div class="modal-header primary">
        <h5 class="modal-title primary">Delegate Emails</h5>
    </div>
    <div class="modal-body- p-4">


        {{delegateEmails}}


        <div class="modal-footer">
            <button type="button" class="book-btn" (click)="close()">
                Close
            </button>
        </div>
    </div>

</ng-template>